import { Component, Vue, Watch } from "vue-property-decorator";
import { DeviceType } from "@/store/module/app";

const WIDTH = 992; // refer to Bootstrap's responsive design

@Component({
  name: "ResizeMixin",
})
export default class extends Vue {
  get device(): DeviceType {
    return (this as any).$store.getters["app/device"];
  }

  get sidebar() {
    return (this as any).$store.getters["app/sidebar"];
  }

  @Watch("$route")
  private onRouteChange() {
    if (this.device === DeviceType.Mobile && this.sidebar.opened) {
      (this as any).$store.commit("app/CLOSE_SIDEBAR", false);
    }
  }

  beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  }

  mounted() {
    const isMobile = this.isMobile();
    if (isMobile) {
      (this as any).$store
        .commit(
          "app/TOGGLE_DEVICE",
          DeviceType.Mobile
        )(this as any)
        .$store.commit("app/CLOSE_SIDEBAR", true);
    }
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  private isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < WIDTH;
  }

  private resizeHandler() {
    if (!document.hidden) {
      const isMobile = this.isMobile();

      (this as any).$store.commit(
        "app/TOGGLE_DEVICE",
        isMobile ? DeviceType.Mobile : DeviceType.Desktop
      );
      if (isMobile) {
        (this as any).$store.commit("app/CLOSE_SIDEBAR", true);
      }
    }
  }
}
