import request from "@/utils/request";

import {
  serviceOptions,
  AccountService,
  AreaService,
  AttachmentService,
  CityService,
  CmsCategoryService,
  CmsAnchorService,
  SwiperService,
  CountryService,
  ProvinceService,
  RoleService,
  SessionService,
  TokenAuthService,
  UserService,
  PermissionService,
  DataDictionaryService,
  EnumService,
  AuditFlowProxyService,
  AuditFlowDefinitionService,
  AuditFlowService,
  ScopeDataSourceService,
  TownService,
  FeedbackService,
  FeedbackReplyService,
  CustomerService,
  ProductBrandService,
  ProductCategoryService,
  SysSettingTaxeService,
  ProductSpecParamService,
  SysSettingOrderService,
  ProductBasicParamService,
  WarehouseService,
  WarehouseLocationService,
  WarehouseSelfLiftinPointService,
  ProductSkuService,
  ProductService,
  SupplierService,
  SupplierProductSaleMainService,
  SupplierProductSaleDetailService,
  WarehouseInStorageMainService,
  ProductRevisePriceRecordService,
  FinanceSalesStatisticService,
  OrderMainService,
  FinanceMemberPaymentOrderService,
  CustomerReceivingAddressMappingService,
  CustomerBillAddressMappingService,
  LanguageService,
  ProductMappingService,
} from "@/api/appService";

serviceOptions.axios = request;

export default {
  guid: "00000000-0000-0000-0000-000000000000",

  tokenAuth: TokenAuthService,
  account: AccountService,
  cmsCategory: CmsCategoryService,
  cmsAnchor: CmsAnchorService,
  swiper: SwiperService,
  attachmentService: AttachmentService,
  attachment: AttachmentService,
  enumService: EnumService,

  auditFlowProxy: AuditFlowProxyService,
  auditFlow: AuditFlowService,
  auditFlowDefinition: AuditFlowDefinitionService,

  country: CountryService,
  province: ProvinceService,
  city: CityService,
  area: AreaService,
  town: TownService,

  dataDictionary: DataDictionaryService,

  role: RoleService,
  session: SessionService,
  user: UserService,
  permission: PermissionService,
  dataPermissionScopeProvider: ScopeDataSourceService,

  feedback: FeedbackService,
  feedbackReply: FeedbackReplyService,

  customer: CustomerService,
  productBrand: ProductBrandService,
  productCategory: ProductCategoryService,
  sysSettingTaxe: SysSettingTaxeService,
  productSpecParam: ProductSpecParamService,
  sysSettingOrder: SysSettingOrderService,
  productBasicParam: ProductBasicParamService,
  wareHouse: WarehouseService,
  wareHouseLocation: WarehouseLocationService,
  warehouseSelfLiftinPoint: WarehouseSelfLiftinPointService,
  productSku: ProductSkuService,
  product: ProductService,
  supplier: SupplierService,
  supplierProductSalesMain: SupplierProductSaleMainService,
  supplierProductSalesDetail: SupplierProductSaleDetailService,
  productRevisePriceRecord: ProductRevisePriceRecordService,
  warehouseInStorageMain: WarehouseInStorageMainService,
  financeSaleStatistic: FinanceSalesStatisticService,
  orderMain: OrderMainService,
  financeMemberPaymentOrder: FinanceMemberPaymentOrderService,
  customerReceivingAddressMapping: CustomerReceivingAddressMappingService,
  customerBillAddressMapping: CustomerBillAddressMappingService,
  language: LanguageService,
  productMapping: ProductMappingService,
};
