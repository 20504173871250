export const isValidUsername = (str: string) =>
  ["admin", "editor"].indexOf(str.trim()) >= 0;

export const isExternal = (path: string) =>
  /^(https?:|mailto:|tel:)/.test(path);

function isValidPhone(str: string) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  return reg.test(str);
}

function validPhone(rule: any, value: string, callback: any) {
  if (!value) callback(new Error("请输入手机号码！"));
  else if (!isValidPhone(value))
    callback(new Error("请输入正确的11位手机号码！"));
  else callback();
}

function validMoney(rule: any, value: string, callback: any) {
  if (!value) callback(new Error("金额不能为空！"));
  else if (!isValidNumber(value))
    callback(new Error("金额只能为数字和小数点！"));
  else callback();
}

function validAge(rule: any, value: string, callback: any) {
  if (!value) callback(new Error("年龄不能为空！"));
  else if (!isValidNumber(value)) callback(new Error("年龄只能为数字！"));
  else callback();
}

function isValidNumber(str: string) {
  const reg = /^(-?\d+)(\.\d+)?$/;
  return reg.test(str);
}

export default {
  validPhone,
  validMoney,
  validAge,
};
