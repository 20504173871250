import { TenantDto, TenantLoginInfoDto } from "@/api/appService";
import { getSidebarStatus, getSize, setSidebarStatus } from "@/utils/cookies";
import { getLocale } from "@/lang";
import { Action, ActionContext } from "vuex";
import App from "@/App.vue";
import { UserState } from "@/store/module/user";

export enum DeviceType {
  Mobile = 0,
  Desktop = 1,
}

interface Sidebar {
  opened: boolean;
  withoutAnimation: boolean;
}

export interface AppState {
  sidebar: Sidebar;
  device: DeviceType;
  language: string;
  size: string;
  name: string;
  version: string;
  tenant: TenantDto;
  tenantId?: number;
}

let storage = JSON.parse(
  window.localStorage.getItem("app") ||
    window.sessionStorage.getItem("app") ||
    "{}"
) as AppState;

function serialize(params: any) {
  storage = { ...storage, ...params };
  window.sessionStorage.setItem("app", JSON.stringify(storage));
}

export default {
  namespaced: true,
  state: () =>
    ({
      sidebar: {
        opened: getSidebarStatus() !== "closed",
        withoutAnimation: false,
      },
      device: storage.device || DeviceType.Desktop,
      language: storage.language || getLocale(),
      size: storage.size || getSize() || "medium",
      name: storage.name || "",
      version: storage.version || "",
      tenant: storage.tenant || {},
      tenantId: storage.tenantId || undefined,
    } as AppState),
  mutations: {
    TOGGLE_SIDEBAR(state: AppState, withoutAnimation: boolean) {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = withoutAnimation;

      setSidebarStatus(state.sidebar.opened ? "opened" : "closed");

      serialize(state);
    },
    CLOSE_SIDEBAR(state: AppState, withoutAnimation: boolean) {
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
      setSidebarStatus("closed");
      serialize(state);
    },
    TOGGLE_DEVICE(state: AppState, device: DeviceType) {
      state.device = device;
      serialize(state);
    },
    SET_TENANT_ID(state: AppState, tenantId: number) {
      state.tenantId = tenantId;
      serialize(state);
    },
    SET_TENANT(state: AppState, tenant: TenantDto) {
      state.tenant = tenant;
      state.tenantId = tenant.id;
      serialize(state);
    },
  },
  actions: {
    toggleSidebar(
      context: ActionContext<AppState, any>,
      withoutAnimation: boolean
    ) {
      context.commit("TOGGLE_SIDEBAR", withoutAnimation);
    },
    closeSidebar(
      context: ActionContext<AppState, any>,
      withoutAnimation: boolean
    ) {
      context.commit("CLOSE_SIDEBAR", withoutAnimation);
    },
    toggleDevice(context: ActionContext<AppState, any>, device: DeviceType) {
      context.commit("TOGGLE_DEVICE", device);
    },
  },

  getters: {
    tenantId(state: AppState): number | undefined {
      return state.tenantId;
    },
    sidebarOpened(state: AppState): boolean {
      return state.sidebar.opened;
    },
    sidebar(state: AppState): Sidebar {
      return state.sidebar;
    },
    device(state: AppState): DeviceType {
      return state.device;
    },
    tenant(state: AppState): TenantDto {
      return state.tenant;
    },
  },
};
