import Vuex, { Store } from "vuex";
import app, { AppState } from "@/store/module/app";
import dataDictionary, {
  DataDictionaryState,
} from "@/store/module/dataDictionary";
import enumValues, { EnumValuesState } from "@/store/module/enumValues";
import permission, { PermissionState } from "@/store/module/permission";
import settings, { SettingsState } from "@/store/module/settings";
import user, { UserState } from "@/store/module/user";

import Vue from "vue";

Vue.use(Vuex);

export interface AppRootState {
  app: AppState;
  dataDictionary: DataDictionaryState;
  enumValues: EnumValuesState;
  permission: PermissionState;
  settings: SettingsState;
  user: UserState;
}

export default new Store<AppRootState>({
  state: {} as AppRootState,
  mutations: {},
  modules: {
    app: app,
    dataDictionary: dataDictionary,
    enumValues: enumValues,
    permission: permission,
    settings: settings,
    user: user,
  },
});
