import { ActionContext } from "vuex";

export interface SettingsState {
  theme: string;
  fixedHeader: boolean;
  showSettings: boolean;
  showTagsView: boolean;
  showSidebarLogo: boolean;
  sidebarTextTheme: boolean;
}

export interface ChangeSettingInput {
  key: string;
  value: string | boolean;
}

let storage = JSON.parse(
  window.localStorage.getItem("settings") ||
    window.sessionStorage.getItem("settings") ||
    "{}"
) as SettingsState;

function serialize(params: any) {
  storage = { ...storage, ...params };
  window.sessionStorage.setItem("settings", JSON.stringify(storage));
}

export default {
  namespaced: true,
  state: () =>
    ({
      theme: storage.theme || "",
      fixedHeader: false,
      showSettings: false,
      showTagsView: false,
      showSidebarLogo: true,
      sidebarTextTheme: true,
    } as SettingsState),
  mutations: {
    CHANGE_SETTING(state: SettingsState, payload: ChangeSettingInput) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.hasOwnProperty(payload.key)) {
        (state as any)[payload.key] = payload.value;
      }
    },
  },
  actions: {
    changeSetting(
      context: ActionContext<SettingsState, any>,
      payload: ChangeSettingInput
    ) {
      context.commit("CHANGE_SETTING", payload);
    },
  },
};
