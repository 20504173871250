
























































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api/index";
import { AuthenticateModel } from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "Login",
})
export default class Login extends Vue {
  @Ref() readonly dataForm!: ElForm;

  form: AuthenticateModel = {
    userNameOrEmailAddress: "",
    password: "",
    rememberClient: true,
  };

  workWeiXinId = "";

  errorInfo = "";

  logging = false;

  loginByAccount() {
    (this.$refs.dataForm as any).validate((isValid: boolean) => {
      if (!isValid) {
        return;
      }

      this.logging = true;

      api.tokenAuth
        .authenticate({ body: this.form })
        .then((res) => {
          (this as any).$store.dispatch("user/login", res).then(() => {
            this.$router.push("/");
          });
        })
        .catch((err) => {
          this.errorInfo = err;
          this.logging = false;
        });
    });
  }

  loginFormRules = {
    userNameOrEmailAddress: [
      {
        required: true,
        message: "请输入账号",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: "请输入密码",
      },
    ],
  };
}
