








import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "AppMain",
})
export default class extends Vue {}
