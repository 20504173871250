import AppContent from "@/layout/AppContent.vue";

const wareHouseRouter = [
  {
    path: "warehouse",
    component: AppContent,
    name: "warehouse",
    redirect: "/warehouse",
    meta: {
      title: "库存管理",
    },
    children: [
      {
        path: "warehouseSearch",
        name: "warehouseSearch",
        component: () => import("@/views/warehouse/warehouseSearch/list.vue"),
        meta: {
          title: "库存查询",
        },
      },
      {
        path: "warehouseLocation",
        name: "warehouseLocation",
        component: () => import("@/views/warehouse/warehouseLocation/list.vue"),
        meta: {
          title: "仓库库位管理",
        },
      },
      {
        path: "locationManagement",
        name: "locationManagement",
        component: () =>
          import("@/views/warehouse/warehouseLocation/location.vue"),
        meta: {
          title: "库位管理",
        },
      },
      {
        path: "warehouseSelfLiftinPoint",
        name: "warehouseSelfLiftinPoint",
        component: () =>
          import("@/views/warehouse/warehouseLocation/selfLiftinPoints.vue"),
        meta: {
          title: "自提点管理",
        },
      },
      {
        path: "warehouseInStorageMain",
        name: "warehouseInStorageMain",
        component: () =>
          import("@/views/warehouse/warehouseInStorageMain/list.vue"),
        meta: {
          title: "入库管理",
        },
      },
      {
        path: "warehouseInStorageMainDetail/:id",
        name: "warehouseInStorageMainDetail",
        component: () =>
          import("@/views/warehouse/warehouseInStorageMain/detail.vue"),
        meta: {
          title: "入库管理",
        },
      },
      {
        path: "warehouseInStorageMainCreate",
        name: "warehouseInStorageMainCreate",
        component: () =>
          import("@/views/warehouse/warehouseInStorageMain/edit.vue"),
        meta: {
          title: "新增入库管理",
        },
      },
      {
        path: "warehouseInStorageMainEdit/:id",
        name: "warehouseInStorageMainEdit",
        component: () =>
          import("@/views/warehouse/warehouseInStorageMain/edit.vue"),
        meta: {
          title: "编辑入库管理",
        },
      },
      {
        path: "warehouseExManagement",
        name: "warehouseExManagement",
        component: () =>
          import("@/views/warehouse/warehouseExManagement/list.vue"),
        meta: {
          title: "出库管理",
        },
      },
      {
        path: "invoice/:id",
        name: "invoice",
        component: () =>
          import("@/views/warehouse/warehouseExManagement/invoice.vue"),
        meta: {
          title: "发货单",
        },
      }
    ],
  },
];

export default wareHouseRouter;
