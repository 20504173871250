/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export class IList<T> extends Array<T> {}
export class List<T> extends Array<T> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount: number;
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount!: number;
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static isTenantAvailable(
    params: {
      /** requestBody */
      body?: IsTenantAvailableInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IsTenantAvailableOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/IsTenantAvailable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 注册
   */
  static register(
    params: {
      /** requestBody */
      body?: RegisterInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegisterOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改手机号
   */
  static changeMobilePhone(
    params: {
      /** requestBody */
      body?: ChangeMobilePhoneDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/ChangeMobilePhone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码
   */
  static resetPassword4Portal(
    params: {
      /** requestBody */
      body?: ResetPassword4PortalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Account/ResetPassword4Portal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AreaService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AreaCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AreaCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      cityId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cityId: params['cityId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      areaName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AreaDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AreaName: params['areaName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Area/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AttachmentService {
  /**
   *
   */
  static getAttachments(
    params: {
      /**  */
      hostId?: string;
      /**  */
      hostType?: AttachmentHostType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAttachments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { hostId: params['hostId'], hostType: params['hostType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAttachmentsByIds(
    params: {
      /**  */
      ids?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttachmentDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/GetAttachmentsByIds';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ids: params['ids'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传到临时目录
   */
  static uploadToTemp(options: IRequestOptions = {}): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadToTemp';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传到Oss
   */
  static uploadToOss(options: IRequestOptions = {}): Promise<UploadResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/UploadToOss';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载附件
   */
  static download(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Attachment/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditFlowService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      key?: string;
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Key: params['key'],
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: CanAuditRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSort(
    params: {
      /** requestBody */
      body?: AuditFlowUpdateSortPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/UpdateSort';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static active(
    params: {
      /** requestBody */
      body?: GuidEntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Active';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlow/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditFlowDefinitionService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDefinitionListDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowDefinition/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { displayName: params['displayName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDefinitionDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowDefinition/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditFlowProxyService {
  /**
   * CreateAsync
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditProxyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * GetForEdit
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowCreateOrEditProxyDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      key?: string;
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Key: params['key'],
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * UpdateAsync
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditFlowCreateOrEditProxyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 此记录当前用户是否可以进行审核
   */
  static canAudit(
    params: {
      /** requestBody */
      body?: CanAuditRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/CanAudit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetail(
    params: {
      /**  */
      scope?: AuditFlowScope;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/GetDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { scope: params['scope'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditFlowDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditFlowProxy/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuditNodePreDefineService {
  /**
   *
   */
  static getForEdit(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineCreateOrEditDtoGetForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/GetForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      desc?: string;
      /**  */
      sorting?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Desc: params['desc'],
        Sorting: params['sorting'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: AuditNodePreDefineCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: AuditNodePreDefineCreateOrEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuditNodePreDefineDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/AuditNodePreDefine/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CartItemService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: CartItemCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CartItemDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static batchSave(
    params: {
      /** requestBody */
      body?: CartItemCreateOrUpdateDto[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/BatchSave';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CartItemDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /**  */
      cartItemIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CartItemDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        CartItemIds: params['cartItemIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: CartItemCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CartItemDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量删除数据
   */
  static batchDelete(
    params: {
      /** requestBody */
      body?: Int64BatchId;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/BatchDelete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前购物车的数量
   */
  static getCurrentCount(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CartItem/GetCurrentCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CityService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CityCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      provinceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provinceId: params['provinceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      cityName?: string;
      /**  */
      shortCode?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CityName: params['cityName'],
        ShortCode: params['shortCode'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/City/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsAnchorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsAnchorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsAnchorCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      name?: string;
      /**  */
      displayName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsAnchorDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsAnchor/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Name: params['name'],
        DisplayName: params['displayName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsCategoryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CmsCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 只和UseParentIdParameter为true时使用。 */
      parentId?: number;
      /**  */
      parentCode?: string;
      /**  */
      useParentIdParameter?: boolean;
      /**  */
      isHidden?: boolean;
      /**  */
      enableDataPermissionLimit?: boolean;
      /**  */
      needShowContentCategory?: boolean;
      /** 是否是导航 */
      isNavigation?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        ParentCode: params['parentCode'],
        UseParentIdParameter: params['useParentIdParameter'],
        IsHidden: params['isHidden'],
        EnableDataPermissionLimit: params['enableDataPermissionLimit'],
        NeedShowContentCategory: params['needShowContentCategory'],
        IsNavigation: params['isNavigation'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CmsCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static showIt(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/ShowIt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hiddenIt(
    params: {
      /** requestBody */
      body?: Int32EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/HiddenIt';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCmsCategoryList(
    params: {
      /**  */
      categoryCode?: string;
      /**  */
      isLeftJoin?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetCmsCategoryList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { categoryCode: params['categoryCode'], isLeftJoin: params['isLeftJoin'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByCode(
    params: {
      /**  */
      categoryCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetByCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { categoryCode: params['categoryCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改栏目排序
   */
  static updateSort(
    params: {
      /** requestBody */
      body?: CmsCategoryUpdateSortDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/UpdateSort';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取全部的导航
   */
  static getAllNavCategory(options: IRequestOptions = {}): Promise<CmsCategoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetAllNavCategory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取指定的导航
   */
  static getOneNavCategory(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetOneNavCategory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取指定的子导航
   */
  static getChildNavCategory(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CmsCategoryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CmsCategory/GetChildNavCategory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConfigurationService {
  /**
   *
   */
  static changeUiTheme(
    params: {
      /** requestBody */
      body?: ChangeUiThemeInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Configuration/ChangeUiTheme';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CountryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CountryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Country/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CountryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Country/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(options: IRequestOptions = {}): Promise<CountryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Country/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Country/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      countryName?: string;
      /**  */
      shortName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Country/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CountryName: params['countryName'],
        ShortName: params['shortName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Country/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerService {
  /**
   * 创建企业客户
   */
  static create(
    params: {
      /** requestBody */
      body?: CustomerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 创建普通客户
   */
  static createOrdinary(
    params: {
      /** requestBody */
      body?: OrdinaryCustomerCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/CreateOrdinary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 会员类型 */
      customerType: CustomerType;
      /** 会员编号 */
      customerCode?: string;
      /** 公司名或公司称呼 */
      enterpriseNameOrCall?: string;
      /** 客户姓名 */
      realName?: string;
      /** 客户手机号 */
      mobilePhone?: string;
      /** 客户邮箱 */
      email?: string;
      /** 状态（启用/禁用） */
      isActive?: boolean;
      /** 会员客户-加入会员时间 */
      membershipTme?: string;
      /** 会员客户-会员有效期（会员结束时间） */
      memberValidity?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        CustomerType: params['customerType'],
        CustomerCode: params['customerCode'],
        EnterpriseNameOrCall: params['enterpriseNameOrCall'],
        RealName: params['realName'],
        MobilePhone: params['mobilePhone'],
        Email: params['email'],
        IsActive: params['isActive'],
        MembershipTme: params['membershipTme'],
        MemberValidity: params['memberValidity'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 更新企业客户（手机号不可以修改）
   */
  static update(
    params: {
      /** requestBody */
      body?: CustomerCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置帐额
   */
  static setEnterpriseAccountQuota(
    params: {
      /** requestBody */
      body?: CustomerSetEnterpriseAccountQuotaDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/SetEnterpriseAccountQuota';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改当前客户信息
   */
  static updateCurrentInfo(
    params: {
      /** requestBody */
      body?: CustomerCurrentInfoUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/UpdateCurrentInfo';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前客户信息
   */
  static getCurrentInfo(options: IRequestOptions = {}): Promise<CustomerBaseDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Customer/GetCurrentInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerBillAddressMappingService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: CustomerBillAddressMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerBillAddressMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerBillAddressMapping/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerBillAddressMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerBillAddressMapping/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 客户Id */
      customerId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerBillAddressMappingDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerBillAddressMapping/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        CustomerId: params['customerId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: CustomerBillAddressMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerBillAddressMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerBillAddressMapping/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerBillAddressMapping/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerReceivingAddressMappingService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: CustomerReceivingAddressMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerReceivingAddressMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerReceivingAddressMapping/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerReceivingAddressMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerReceivingAddressMapping/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 客户Id */
      customerId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerReceivingAddressMappingDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerReceivingAddressMapping/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        CustomerId: params['customerId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: CustomerReceivingAddressMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerReceivingAddressMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerReceivingAddressMapping/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerReceivingAddressMapping/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取每个客户可以维护的收货地址的最大数量
   */
  static getMaxCountByEachCustomer(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerReceivingAddressMapping/GetMaxCountByEachCustomer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置默认
   */
  static setDefault(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/CustomerReceivingAddressMapping/SetDefault';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DataDictionaryService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: DataDictionaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DataDictionaryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static syncToTenant(
    params: {
      /** requestBody */
      body?: DataDictionarySyncInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/SyncToTenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByKey(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetByKey';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 分页查询
   */
  static getAll(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据parentID获取子字典数据
   */
  static getListByParentId(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetListByParentId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Key获取子字典数据
   */
  static getDataDictionaryListByKey(
    params: {
      /**  */
      parentId?: number;
      /**  */
      isParent?: boolean;
      /** 键名 */
      key?: string;
      /** 显示的名称 */
      displayName?: string;
      /** 显示顺序 */
      displayOrder?: number;
      /**  */
      parentKey?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetDataDictionaryListByKey';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        ParentId: params['parentId'],
        IsParent: params['isParent'],
        Key: params['key'],
        DisplayName: params['displayName'],
        DisplayOrder: params['displayOrder'],
        ParentKey: params['parentKey'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据Key获取不分页的字典项
   */
  static getDataDictionaryByKey(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetDataDictionaryByKey';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有的一级字典项
   */
  static getAllRoot(options: IRequestOptions = {}): Promise<DataDictionaryDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/GetAllRoot';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataDictionaryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataDictionary/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DataPermissionLimitService {
  /**
   * 获取数据
   */
  static getAll(
    params: {
      /**  */
      hostId?: string;
      /**  */
      hostType?: DataPermissionLimitHostType;
      /**  */
      targetType?: DataPermissionLimitTargetType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataPermissionLimitDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { HostId: params['hostId'], HostType: params['hostType'], TargetType: params['targetType'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: DataPermissionLimitUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reset(
    params: {
      /** requestBody */
      body?: DataPermissionLimitHostType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Reset';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/DataPermissionLimit/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EnumService {
  /**
   * 获取枚举选项列表
   */
  static getValues(
    params: {
      /** 枚举名称，如：NotifyTemplateType */
      typeName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetValues';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { typeName: params['typeName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllEnums(
    params: {
      /**  */
      filter?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAllEnums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filter: params['filter'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAppEnums(options: IRequestOptions = {}): Promise<SelectListItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAppEnums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAppEnumNames(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Enum/GetAppEnumNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FeedbackService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submit(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Submit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 我的在线留言
   */
  static getMy(
    params: {
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 联系方式 */
      contactType?: string;
      /** 反馈类型Id */
      typeCodeId?: number;
      /** 是否显示在官网 */
      isShowPortal?: boolean;
      /** 是否已回复 */
      isReplied?: boolean;
      /** 反馈来源 */
      sourceType?: FeedbackSourceType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/GetMy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Content: params['content'],
        ContactType: params['contactType'],
        TypeCodeId: params['typeCodeId'],
        IsShowPortal: params['isShowPortal'],
        IsReplied: params['isReplied'],
        SourceType: params['sourceType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static replySave(
    params: {
      /** requestBody */
      body?: FeedbackCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/ReplySave';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 是否显示在官网
   */
  static showPortal(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/ShowPortal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 标题 */
      title?: string;
      /** 内容 */
      content?: string;
      /** 联系方式 */
      contactType?: string;
      /** 反馈类型Id */
      typeCodeId?: number;
      /** 是否显示在官网 */
      isShowPortal?: boolean;
      /** 是否已回复 */
      isReplied?: boolean;
      /** 反馈来源 */
      sourceType?: FeedbackSourceType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Feedback/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Title: params['title'],
        Content: params['content'],
        ContactType: params['contactType'],
        TypeCodeId: params['typeCodeId'],
        IsShowPortal: params['isShowPortal'],
        IsReplied: params['isReplied'],
        SourceType: params['sourceType'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FeedbackReplyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: FeedbackReplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: FeedbackReplyCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 显示记录
   */
  static show(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Show';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 隐藏记录
   */
  static hidden(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Hidden';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /** 反馈信息Id */
      feedbackId?: number;
      /** 内容 */
      content?: string;
      /** 是否隐藏 */
      isHide?: boolean;
      /** 用户Id */
      userId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeedbackReplyDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FeedbackReply/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FeedbackId: params['feedbackId'],
        Content: params['content'],
        IsHide: params['isHide'],
        UserId: params['userId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FinanceMemberPaymentOrderService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: FinanceMemberPaymentOrderCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceMemberPaymentOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceMemberPaymentOrder/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceMemberPaymentOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceMemberPaymentOrder/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 会员编号 */
      customerCode?: string;
      /** 是否已支付 */
      isPaid?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceMemberPaymentOrderDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceMemberPaymentOrder/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        CustomerCode: params['customerCode'],
        IsPaid: params['isPaid'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: FinanceMemberPaymentOrderCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceMemberPaymentOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceMemberPaymentOrder/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceMemberPaymentOrder/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FinanceSalesStatisticService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: FinanceSalesStatisticCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceSalesStatisticDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceSalesStatistic/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceSalesStatisticDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceSalesStatistic/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 订单编号 */
      orderCode?: string;
      /** 订单状态 */
      status?: OrderStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceSalesStatisticDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceSalesStatistic/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        OrderCode: params['orderCode'],
        Status: params['status'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: FinanceSalesStatisticCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceSalesStatisticDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceSalesStatistic/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceSalesStatistic/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 导出
   */
  static export(
    params: {
      /** requestBody */
      body?: FinanceSalesStatisticPagedResultRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/FinanceSalesStatistic/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LanguageService {
  /**
   * 获取Language
   */
  static getAllLanguage(options: IRequestOptions = {}): Promise<ApplicationLanguageDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/GetAllLanguage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 创建LanguageText
   */
  static createLanguageText(
    params: {
      /** requestBody */
      body?: LanguageTextCreateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/CreateLanguageText';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取LanguageText
   */
  static getAllLanguageText(
    params: {
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LanguageTextDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/GetAllLanguageText';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取LanguageText
   */
  static getAllLanguageTextTree(
    params: {
      /** Key */
      key?: string;
      /** Value */
      value?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LanguageTextTreeDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/GetAllLanguageTextTree';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Key: params['key'],
        Value: params['value'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改LanguageText的ey
   */
  static updateLanguageTextKey(
    params: {
      /** requestBody */
      body?: LanguageTextUpdateKeyDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/UpdateLanguageTextKey';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改LanguageText的值
   */
  static updateLanguageTextValue(
    params: {
      /** requestBody */
      body?: LanguageTextUpdateValueDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/UpdateLanguageTextValue';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除LanguageText
   */
  static deleteLanguageText(
    params: {
      /** requestBody */
      body?: LanguageTextDeleteDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/DeleteLanguageText';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 上传
   */
  static upload(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Language/Upload';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrderMainService {
  /**
   * 准备代下订单
   */
  static preparePlaceOrderOnBehalf(
    params: {
      /** requestBody */
      body?: PreparePlaceOrderOnBehalfPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CustomerDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/PreparePlaceOrderOnBehalf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 开始代下订单
   */
  static doPlaceOrderOnBehalf(
    params: {
      /** requestBody */
      body?: OrderMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderCheckInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/DoPlaceOrderOnBehalf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 客户创建订单
   */
  static customerCreateOrder(
    params: {
      /** requestBody */
      body?: CustomerOrderMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderCheckInfoDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/CustomerCreateOrder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: OrderMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 购物车选择商品的价格
   */
  static calculateSelectItem(
    params: {
      /** requestBody */
      body?: CartItemSelectedProductPriceQueryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CartItemSelectedProductPriceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/CalculateSelectItem';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 订单编号 */
      orderCode?: string;
      /** 用户账号 */
      userName?: string;
      /** 订单收货方式 */
      receivingMethod?: OrderReceivingMethod;
      /** 订单状态 */
      status?: OrderStatus;
      /** 下单开始时间 */
      beginTime?: string;
      /** 下单结束时间 */
      endTime?: string;
      /** 商品编号 */
      productNumber?: string;
      /** 是否只包含出库的数据
true：忽略Status的查询条件 */
      isOnlyIncludeOutStorage?: boolean;
      /** 前端订单列表Tab */
      orderStatusTab?: OrderStatusTab;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderMainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        OrderCode: params['orderCode'],
        UserName: params['userName'],
        ReceivingMethod: params['receivingMethod'],
        Status: params['status'],
        BeginTime: params['beginTime'],
        EndTime: params['endTime'],
        ProductNumber: params['productNumber'],
        IsOnlyIncludeOutStorage: params['isOnlyIncludeOutStorage'],
        OrderStatusTab: params['orderStatusTab'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: OrderMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 取消
   */
  static cancelled(
    params: {
      /** requestBody */
      body?: OrderMainCancelPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/Cancelled';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 预计送货
   */
  static outStorage(
    params: {
      /** requestBody */
      body?: OrderMainStoragePostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/OutStorage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 实际出库
   */
  static actualOutStorage(
    params: {
      /** requestBody */
      body?: OrderMainActualOutStoragePostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/ActualOutStorage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 收货
   */
  static received(
    params: {
      /** requestBody */
      body?: StringEntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/Received';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 模拟支付
   */
  static mockPaid(
    params: {
      /** requestBody */
      body?: StringEntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/MockPaid';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 模拟支票支付
   */
  static mockChequePaid(
    params: {
      /** requestBody */
      body?: StringEntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/MockChequePaid';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 确认回款
   */
  static confirmPayment(
    params: {
      /** requestBody */
      body?: OrderMainConfirmPaymentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/ConfirmPayment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量下载发票
   */
  static batchDownloadInvoicePdf(
    params: {
      /**  */
      orderMainIds?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/OrderMain/BatchDownloadInvoicePdf';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { orderMainIds: params['orderMainIds'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PermissionService {
  /**
   *
   */
  static getAllPermissions(options: IRequestOptions = {}): Promise<PermissionDefinitionDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Permission/GetAllPermissions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDataPermissionScopeTypes(options: IRequestOptions = {}): Promise<DataPermissionScopeType[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Permission/GetDataPermissionScopeTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Product/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Product/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 父级分类路径 */
      parentIdPath?: string;
      /** 商品名称/品牌 */
      name?: string;
      /** 品牌Id（多个以,隔开） */
      brandIds?: string;
      /** 规格参数Id（多个以,隔开） */
      specParamIds?: string;
      /** 是否按照销量降序 */
      isSortBySalesVolume?: boolean;
      /**  */
      notShowProductIds?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Product/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        ParentIdPath: params['parentIdPath'],
        Name: params['name'],
        BrandIds: params['brandIds'],
        SpecParamIds: params['specParamIds'],
        IsSortBySalesVolume: params['isSortBySalesVolume'],
        NotShowProductIds: params['notShowProductIds'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Product/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Product/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductBasicParamService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductBasicParamCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBasicParamDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBasicParam/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBasicParamDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBasicParam/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 参数名称 */
      name?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBasicParamDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBasicParam/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Name: params['name'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductBasicParamCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBasicParamDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBasicParam/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBasicParam/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductBrandService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductBrandCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBrandDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBrand/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBrandDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBrand/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 品牌名称 */
      name?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBrandDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBrand/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Name: params['name'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductBrandCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductBrandDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBrand/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductBrand/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductCategoryService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductCategory/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductCategory/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 分类名称 */
      name?: string;
      /** 导航Id */
      navId?: number;
      /** 是否包含商品数量 */
      isIncludeProductCount?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductCategoryDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductCategory/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Name: params['name'],
        NavId: params['navId'],
        IsIncludeProductCount: params['isIncludeProductCount'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductCategoryCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductCategoryDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductCategory/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductCategory/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductMappingService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductMapping/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductMapping/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 原始商品Id */
      sourceProductId?: number;
      /** 关联类型 */
      type?: ProductMappingType;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductMappingDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductMapping/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        SourceProductId: params['sourceProductId'],
        Type: params['type'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductMappingCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductMappingDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductMapping/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductMapping/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductRevisePriceRecordService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductRevisePriceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRevisePriceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductRevisePriceRecord/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRevisePriceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductRevisePriceRecord/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取调价日志数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 商品skuId */
      productSkuId?: number;
      /** 商品编号（系统自动生成） */
      number?: string;
      /** 用户账号 */
      userName?: string;
      /** 用户编码 */
      customerCode?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRevisePriceRecordDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductRevisePriceRecord/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        ProductSkuId: params['productSkuId'],
        Number: params['number'],
        UserName: params['userName'],
        CustomerCode: params['customerCode'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductRevisePriceRecordCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRevisePriceRecordDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductRevisePriceRecord/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductRevisePriceRecord/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取调价管理数据
   */
  static getMainAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 商品skuId */
      productSkuId?: number;
      /** 客户编号 */
      customerCode?: string;
      /** 会员名称 */
      realName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRevisePriceRecordMainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductRevisePriceRecord/GetMainAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        ProductSkuId: params['productSkuId'],
        CustomerCode: params['customerCode'],
        RealName: params['realName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductSkuService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductSkuCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSkuDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSkuDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static getWithCustomerCode(
    params: {
      /**  */
      id?: number;
      /**  */
      customerCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSkuDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/GetWithCustomerCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], customerCode: params['customerCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 商品编号（系统自动生成） */
      number?: string;
      /** 型号（用户手工输入） */
      model?: string;
      /** 商品名称 */
      name?: string;
      /** 父级分类路径 */
      parentIdPath?: string;
      /** 品牌Id */
      brandId?: number;
      /** 是否推荐 */
      isRecommend?: boolean;
      /** 上架最小量 */
      minAvailableStock?: number;
      /** 上架最大量 */
      maxAvailableStock?: number;
      /** 客户编号 */
      customerCode?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSkuDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Number: params['number'],
        Model: params['model'],
        Name: params['name'],
        ParentIdPath: params['parentIdPath'],
        BrandId: params['brandId'],
        IsRecommend: params['isRecommend'],
        MinAvailableStock: params['minAvailableStock'],
        MaxAvailableStock: params['maxAvailableStock'],
        CustomerCode: params['customerCode'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductSkuCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSkuDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 查询数量
   */
  static getCount(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 商品编号（系统自动生成） */
      number?: string;
      /** 型号（用户手工输入） */
      model?: string;
      /** 商品名称 */
      name?: string;
      /** 父级分类路径 */
      parentIdPath?: string;
      /** 品牌Id */
      brandId?: number;
      /** 是否推荐 */
      isRecommend?: boolean;
      /** 上架最小量 */
      minAvailableStock?: number;
      /** 上架最大量 */
      maxAvailableStock?: number;
      /** 客户编号 */
      customerCode?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSkuCountDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/GetCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Number: params['number'],
        Model: params['model'],
        Name: params['name'],
        ParentIdPath: params['parentIdPath'],
        BrandId: params['brandId'],
        IsRecommend: params['isRecommend'],
        MinAvailableStock: params['minAvailableStock'],
        MaxAvailableStock: params['maxAvailableStock'],
        CustomerCode: params['customerCode'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置上架库存
   */
  static setAvailableStock(
    params: {
      /** requestBody */
      body?: ProductSkuStockDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/SetAvailableStock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置预警库存
   */
  static setEarlyWarningStock(
    params: {
      /** requestBody */
      body?: ProductSkuStockDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/SetEarlyWarningStock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置是否推荐
   */
  static setRecommend(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/SetRecommend';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量上架
   */
  static batchOnShelf(
    params: {
      /** requestBody */
      body?: ProductSkuOnShelfPostDto[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/BatchOnShelf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 批量下架
   */
  static batchRemovedFromShelf(
    params: {
      /** requestBody */
      body?: Int64BatchId;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSku/BatchRemovedFromShelf';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductSpecParamService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductSpecParamCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSpecParamDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSpecParam/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSpecParamDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSpecParam/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 参数名称 */
      name?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSpecParamDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSpecParam/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Name: params['name'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: ProductSpecParamCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductSpecParamDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSpecParam/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ProductSpecParam/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProvinceService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProvinceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ProvinceCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      countryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { countryId: params['countryId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      countryId?: number;
      /**  */
      provinceName?: string;
      /**  */
      shortName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProvinceDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CountryId: params['countryId'],
        ProvinceName: params['provinceName'],
        ShortName: params['shortName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Province/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoleService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: RoleCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoles(
    params: {
      /**  */
      permission?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleListDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetRoles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Permission: params['permission'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: RoleCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static grantPermission(
    params: {
      /** requestBody */
      body?: RoleGrantPermissionInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GrantPermission';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllPermissions(options: IRequestOptions = {}): Promise<PermissionDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetAllPermissions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取分配给角色的权限数据权限分配
   */
  static getGrantedDataPermissionSettings(
    params: {
      /**  */
      roleId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DataPermissionSettingDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetGrantedDataPermissionSettings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { roleId: params['roleId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取已分配的权限信息
   */
  static getGrantedPermissionNames(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetGrantedPermissionNames';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleForEdit(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetRoleForEditOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetRoleForEdit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Role/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ScopeDataSourceService {
  /**
   * 获取数据源内容
   */
  static getItems(
    params: {
      /**  */
      providerName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ScopeDataSource/GetItems';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { providerName: params['providerName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有的数据源提供者
   */
  static getProviders(
    params: {
      /**  */
      permissionCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DisplayItem[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ScopeDataSource/GetProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { permissionCode: params['permissionCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取所有的支持数据权限控制的过滤器
   */
  static getAllSupportProviders(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/ScopeDataSource/GetAllSupportProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SessionService {
  /**
   *
   */
  static getCurrentLoginInformations(options: IRequestOptions = {}): Promise<GetCurrentLoginInformationsOutput> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Session/GetCurrentLoginInformations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SiteMessageService {
  /**
   * 获取未读消息的数量
   */
  static getUnreadCount(options: IRequestOptions = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/GetUnreadCount';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SiteMessageDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表
   */
  static getAll(
    params: {
      /** 关键字 */
      keyword?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SiteMessageDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置成已读
   */
  static read(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/Read';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置成已读
   */
  static readById(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SiteMessage/ReadById';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SupplierService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: SupplierCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 供应商名称 */
      supplierName?: string;
      /** 手机号 */
      mobilePhone?: string;
      /** 联系人 */
      realName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        SupplierName: params['supplierName'],
        MobilePhone: params['mobilePhone'],
        RealName: params['realName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: SupplierCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置是否可视出售价格
   */
  static setSalePriceVisible(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/SetSalePriceVisible';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 下载导入模板
   */
  static downloadTemplate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/DownloadTemplate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 供应商导入
   */
  static import(options: IRequestOptions = {}): Promise<ImportResult> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Supplier/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SupplierProductSaleDetailService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: SupplierProductSaleDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleDetail/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleDetail/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 销售记录主表Id */
      productSaleMainId?: number;
      /** 商品名称 */
      productName?: string;
      /** 商品编号 */
      productNumber?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleDetailDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleDetail/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        ProductSaleMainId: params['productSaleMainId'],
        ProductName: params['productName'],
        ProductNumber: params['productNumber'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: SupplierProductSaleDetailCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleDetailDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleDetail/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleDetail/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SupplierProductSaleMainService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: SupplierProductSaleMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleMain/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleMain/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 供应商Id */
      supplierId?: number;
      /** 销售月份 */
      salesTime?: string;
      /** 是否结算（状态） */
      isSettled?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleMainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleMain/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        SupplierId: params['supplierId'],
        SalesTime: params['salesTime'],
        IsSettled: params['isSettled'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: SupplierProductSaleMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplierProductSaleMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleMain/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleMain/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 结算
   */
  static settled(
    params: {
      /** requestBody */
      body?: SupplierProductSaleSettlementPostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SupplierProductSaleMain/Settled';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SwiperService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SwiperCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: SwiperCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据锚点编号获取轮播图列表
   */
  static getSwiperListByAnchor(
    params: {
      /**  */
      anchorName?: string;
      /**  */
      isMustImg?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/GetSwiperListByAnchor';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { anchorName: params['anchorName'], isMustImg: params['isMustImg'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      displayName?: string;
      /**  */
      cmsAnchorId?: number;
      /**  */
      anchorName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SwiperDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Swiper/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DisplayName: params['displayName'],
        CmsAnchorId: params['cmsAnchorId'],
        AnchorName: params['anchorName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SysSettingOrderService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: SysSettingOrderCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingOrder/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingOrder/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingOrderDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingOrder/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: SysSettingOrderCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingOrder/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingOrder/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取当前可用的数据
   */
  static getCurrent(options: IRequestOptions = {}): Promise<SysSettingOrderDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingOrder/GetCurrent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SysSettingTaxeService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: SysSettingTaxeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingTaxeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingTaxe/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingTaxeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingTaxe/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据省份Id获取税金
   */
  static getByProvinceId(
    params: {
      /**  */
      provinceId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingTaxeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingTaxe/GetByProvinceId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provinceId: params['provinceId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 省Id */
      provinceId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingTaxeDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingTaxe/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        ProvinceId: params['provinceId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: SysSettingTaxeCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SysSettingTaxeDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingTaxe/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingTaxe/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置默认
   */
  static setDefault(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/SysSettingTaxe/SetDefault';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateTenantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      isActive?: boolean;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        IsActive: params['isActive'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TenantDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Tenant/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TokenAuthService {
  /**
   *
   */
  static weChatAppletAuthenticate(
    params: {
      /** requestBody */
      body?: RegisterInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/WeChatAppletAuthenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticate(
    params: {
      /** requestBody */
      body?: AuthenticateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/Authenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refreshToken(options: IRequestOptions = {}): Promise<AuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/RefreshToken';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static wechatAuthenticate(
    params: {
      /** requestBody */
      body?: WechatAuthenticateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalAuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/WechatAuthenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExternalAuthenticationProviders(options: IRequestOptions = {}): Promise<ExternalLoginProviderInfoModel[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/GetExternalAuthenticationProviders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalAuthenticate(
    params: {
      /** requestBody */
      body?: ExternalAuthenticateModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalAuthenticateResultModel> {
    return new Promise((resolve, reject) => {
      let url = '/api/TokenAuth/ExternalAuthenticate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TownService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: TownCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: TownCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllList(
    params: {
      /**  */
      areaId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/GetAllList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { areaId: params['areaId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      townName?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        TownName: params['townName'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Town/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateUserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UserDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 启用
   */
  static activate(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Activate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 禁用
   */
  static deActivate(
    params: {
      /** requestBody */
      body?: Int64EntityDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/DeActivate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoles(options: IRequestOptions = {}): Promise<RoleDtoListResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetRoles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeLanguage(
    params: {
      /** requestBody */
      body?: ChangeUserLanguageDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangeLanguage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-Sms
   */
  static resetPassword4PortalSms(
    params: {
      /** requestBody */
      body?: ResetPassword4PortalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ResetPassword4PortalSms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改手机号-Sms
   */
  static changePhone4PortalSms(
    params: {
      /** requestBody */
      body?: ChangePhone4PortalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangePhone4PortalSms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 重置密码-Email
   */
  static resetPassword4PortalEmail(
    params: {
      /** requestBody */
      body?: ResetPassword4PortalEmailDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ResetPassword4PortalEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改头像
   */
  static changeAvatar(
    params: {
      /** requestBody */
      body?: ChangeAvatarDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangeAvatar';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改背景图片地址
   */
  static changeBackgroundImagePath(
    params: {
      /** requestBody */
      body?: ChangeBackgroundImagePathDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangeBackgroundImagePath';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取用户的所在地区
   */
  static getUserLocation(options: IRequestOptions = {}): Promise<UserLocationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetUserLocation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 设置用户类型
   */
  static setUserType(
    params: {
      /** requestBody */
      body?: UserTypePostDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/SetUserType';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 是否已完善信息
   */
  static isPerfected(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/IsPerfected';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改邮箱
   */
  static changeEmail4Portal(
    params: {
      /** requestBody */
      body?: ChangeEmail4PortalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/ChangeEmail4Portal';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 根据角色编号获取用户
   */
  static getUserListByRoleName(
    params: {
      /**  */
      roleName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetUserListByRoleName';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { roleName: params['roleName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      keyword?: string;
      /**  */
      isActive?: boolean;
      /**  */
      skipCount?: number;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/User/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Keyword: params['keyword'],
        IsActive: params['isActive'],
        SkipCount: params['skipCount'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VerificationCodeService {
  /**
   * 获取短信验证码
   */
  static getSmsVerificationCode(
    params: {
      /**  */
      phone: string;
      /**  */
      verificationCode?: VerificationCode;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VerificationCode/GetSMSVerificationCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { phone: params['phone'], verificationCode: params['verificationCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取邮箱验证码
   */
  static getEmailVerificationCode(
    params: {
      /**  */
      emailAddress: string;
      /**  */
      verificationCode?: VerificationCode;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VerificationCode/GetEmailVerificationCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { emailAddress: params['emailAddress'], verificationCode: params['verificationCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 检查验证码
   */
  static check(
    params: {
      /**  */
      input?: string;
      /**  */
      code?: string;
      /**  */
      verificationCode?: VerificationCode;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/VerificationCode/Check';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { input: params['input'], code: params['code'], verificationCode: params['verificationCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WarehouseService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: WarehouseCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Warehouse/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Warehouse/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 仓库名称 */
      name?: string;
      /** 仓库状态 */
      status?: EnableStatus;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Warehouse/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Name: params['name'],
        Status: params['status'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: WarehouseCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Warehouse/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Warehouse/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WarehouseInStorageMainService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: WarehouseInStorageMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseInStorageMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseInStorageMain/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseInStorageMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseInStorageMain/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 商品sku编号 */
      productSkuNumber?: string;
      /** 入库单号 */
      inStorageCode?: string;
      /** 供应商Id */
      supplierId?: number;
      /** 型号（用户手工输入） */
      model?: string;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseInStorageMainDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseInStorageMain/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        ProductSkuNumber: params['productSkuNumber'],
        InStorageCode: params['inStorageCode'],
        SupplierId: params['supplierId'],
        Model: params['model'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: WarehouseInStorageMainCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseInStorageMainDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseInStorageMain/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseInStorageMain/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WarehouseLocationService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: WarehouseLocationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseLocationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseLocation/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseLocationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseLocation/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 库位状态 */
      status?: EnableStatus;
      /** 仓库Id */
      warehouseId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseLocationDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseLocation/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        Status: params['status'],
        WarehouseId: params['warehouseId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: WarehouseLocationCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseLocationDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseLocation/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseLocation/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WarehouseSelfLiftinPointService {
  /**
   * 创建数据
   */
  static create(
    params: {
      /** requestBody */
      body?: WarehouseSelfLiftinPointCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseSelfLiftinPointDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseSelfLiftinPoint/Create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取详情数据
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseSelfLiftinPointDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseSelfLiftinPoint/Get';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取列表数据
   */
  static getAll(
    params: {
      /** 是否只看自己创建的数据 */
      isOnlyMine?: boolean;
      /** 仓库Id */
      warehouseId?: number;
      /** 每页记录数，用作替换MaxResultCount */
      pageSize?: number;
      /**  */
      skipCount?: number;
      /** 页码，用来替换SkipCount */
      pageIndex?: number;
      /**  */
      sorting?: string;
      /**  */
      maxResultCount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseSelfLiftinPointDtoPagedResultDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseSelfLiftinPoint/GetAll';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsOnlyMine: params['isOnlyMine'],
        WarehouseId: params['warehouseId'],
        PageSize: params['pageSize'],
        SkipCount: params['skipCount'],
        PageIndex: params['pageIndex'],
        Sorting: params['sorting'],
        MaxResultCount: params['maxResultCount']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 获取行政区划
   */
  static getAdministrativeDivision(
    options: IRequestOptions = {}
  ): Promise<WarehouseSelfLiftinPointAdministrativeDivisionDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseSelfLiftinPoint/GetAdministrativeDivision';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 修改数据
   */
  static update(
    params: {
      /** requestBody */
      body?: WarehouseSelfLiftinPointCreateOrUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WarehouseSelfLiftinPointDto> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseSelfLiftinPoint/Update';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * 删除数据
   */
  static delete(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/WarehouseSelfLiftinPoint/Delete';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WechatService {
  /**
   *
   */
  static receiveMessage(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Wechat/ReceiveMessage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static receiveMessage1(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Wechat/ReceiveMessage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTempQrCode(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = '/api/services/app/Wechat/GetTempQrCode';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WeiXinKfService {
  /**
   *
   */
  static messageCallback(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/WeiXinKf/MessageCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static messageCallback1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/api/WeiXinKf/MessageCallback';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface ApplicationInfoDto {
  /**  */
  version?: string;

  /**  */
  releaseDate?: Date;

  /**  */
  features?: object;
}

export interface ApplicationLanguageDto {
  /** 名称 */
  name?: string;

  /** 显示名称 */
  displayName?: string;

  /** 图标 */
  icon?: string;

  /** 是否禁用 */
  isDisabled?: boolean;

  /**  */
  id?: number;
}

export interface AreaCreateOrUpdateDto {
  /**  */
  cityId?: number;

  /**  */
  areaName?: string;

  /**  */
  id?: number;
}

export interface AreaDto {
  /**  */
  cityId?: number;

  /**  */
  areaName?: string;

  /**  */
  areaNameFr?: string;

  /**  */
  id?: number;
}

export interface AreaDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AreaDto[];
}

export interface AttachmentDto {
  /**  */
  fileName?: string;

  /** 文件的扩展名 */
  extension?: string;

  /** 是否为临时文件 */
  isTemp?: boolean;

  /**  */
  url?: string;

  /** 附件的宿主ID */
  hostId?: string;

  /**  */
  hostType?: AttachmentHostType;

  /** 文件的长度大小(单位：字节） */
  contentLength?: number;

  /** 文件的大小(自动计算的属性) */
  fileSize?: string;

  /**  */
  id?: number;
}

export interface AuditFlowCreateOrEditDto {
  /**  */
  title?: string;

  /**  */
  desc?: string;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  type?: AuditFlowType;

  /**  */
  scope?: number;

  /**  */
  key?: string;

  /**  */
  ruleConfig?: string;

  /**  */
  auditNodes?: AuditNodeCreateOrEditDto[];

  /**  */
  id?: string;
}

export interface AuditFlowCreateOrEditDtoGetForEditOutput {
  /**  */
  data?: AuditFlowCreateOrEditDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface AuditFlowCreateOrEditProxyDto {
  /** 标题 */
  title?: string;

  /** 描述 */
  desc?: string;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  type?: AuditFlowType;

  /**  */
  scope?: AuditFlowScope;

  /** Key */
  key?: string;

  /** 条件规则配置 */
  ruleConfig?: string;

  /** 节点 */
  auditNodes?: AuditNodeCreateOrEditDto[];

  /**  */
  id?: string;
}

export interface AuditFlowCreateOrEditProxyDtoGetForEditOutput {
  /**  */
  data?: AuditFlowCreateOrEditProxyDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface AuditFlowDefinitionDetailDto {
  /**  */
  groupName?: string;

  /**  */
  key?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  isRequired?: boolean;

  /**  */
  isSupportRules?: boolean;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  auditFlowId?: string;

  /**  */
  ruleConfig?: any | null;

  /**  */
  supportRules?: AuditFlowSupportRuleDto[];
}

export interface AuditFlowDefinitionListDto {
  /**  */
  groupName?: string;

  /**  */
  details?: AuditFlowDefinitionDetailDto[];
}

export interface AuditFlowDto {
  /**  */
  title?: string;

  /**  */
  desc?: string;

  /**  */
  status?: AuditFLowStatus;

  /**  */
  scope?: number;

  /**  */
  type?: AuditFlowType;

  /**  */
  nodesMaxIndex?: number;

  /**  */
  key?: string;

  /**  */
  isLatest?: boolean;

  /**  */
  ruleConfig?: string;

  /**  */
  sort?: number;

  /**  */
  auditNodes?: AuditNodeDto[];

  /**  */
  id?: string;
}

export interface AuditFlowDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AuditFlowDto[];
}

export interface AuditFlowScope {}

export interface AuditFlowSupportRuleDto {
  /**  */
  key?: string;

  /**  */
  displayName?: string;

  /**  */
  valueType?: AuditFlowConditionValueType;

  /**  */
  valueSource?: ValueSourceInfo;
}

export interface AuditFlowUpdateSortPostDto {
  /**  */
  ids?: string[];
}

export interface AuditNodeCreateOrEditDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  tenantId?: number;

  /**  */
  auditMethod?: AuditMethod;

  /**  */
  id?: string;
}

export interface AuditNodeDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  index?: number;

  /**  */
  auditFlowId?: string;

  /**  */
  tenantId?: number;

  /**  */
  iCanAudit?: boolean;

  /**  */
  auditMethod?: AuditMethod;

  /**  */
  sort?: number;

  /**  */
  id?: string;
}

export interface AuditNodePreDefineCreateOrEditDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  id?: string;
}

export interface AuditNodePreDefineCreateOrEditDtoGetForEditOutput {
  /**  */
  data?: AuditNodePreDefineCreateOrEditDto;

  /**  */
  schema?: any | null;

  /**  */
  uiSchema?: any | null;

  /**  */
  errorSchema?: any | null;
}

export interface AuditNodePreDefineDto {
  /**  */
  desc?: string;

  /**  */
  roleName?: string;

  /**  */
  roleId?: number;

  /**  */
  userName?: string;

  /**  */
  userId?: number;

  /**  */
  organizationUnitId?: number;

  /**  */
  userMini?: UserMiniDto;

  /**  */
  role?: RoleMiniDto;

  /**  */
  id?: string;
}

export interface AuditNodePreDefineDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: AuditNodePreDefineDto[];
}

export interface AuthenticateModel {
  /**  */
  userNameOrEmailAddress?: string;

  /**  */
  password?: string;

  /**  */
  rememberClient?: boolean;
}

export interface AuthenticateResultModel {
  /**  */
  accessToken?: string;

  /**  */
  encryptedAccessToken?: string;

  /**  */
  expireInSeconds?: number;

  /**  */
  userId?: number;

  /**  */
  isNormalLogin?: boolean;

  /**  */
  userType?: UserType;

  /**  */
  mobilePhone?: string;
}

export interface CanAuditRequestDto {
  /**  */
  auditFlowId?: string;

  /**  */
  audit?: number;

  /**  */
  auditStatus?: number;
}

export interface CartItemCreateOrUpdateDto {
  /** 商品skuId */
  productSkuId?: number;

  /** 添加到购物车的数量 */
  quantity?: number;

  /**  */
  id?: number;
}

export interface CartItemDto {
  /** 客户Id */
  customerId?: number;

  /**  */
  fkProduct?: ProductShortDto;

  /** 商品Id */
  productId?: number;

  /**  */
  fkProductSku?: ProductSkuShortDto;

  /** 商品skuId */
  productSkuId?: number;

  /** 添加到购物车的数量 */
  quantity?: number;

  /**  */
  id?: number;
}

export interface CartItemDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CartItemDto[];
}

export interface CartItemSelectedProductPriceDto {
  /**  */
  totalAmount?: number;

  /**  */
  productSkuTotalQuantity?: number;

  /**  */
  productSkuTotalPrice?: number;

  /**  */
  tranExpenses?: number;

  /**  */
  taxesGST?: number;

  /**  */
  taxesQST?: number;
}

export interface CartItemSelectedProductPriceQueryDto {
  /** 购物车Ids（逗号分割） */
  cartItemIds?: string;

  /**  */
  receivingMethod?: OrderReceivingMethod;

  /** 收货地址Id */
  receivingAddressMappingId?: number;

  /** 仓库自提点Id */
  warehouseSelfLiftinPointId?: number;
}

export interface ChangeAvatarDto {
  /** 头像 */
  avatar?: string;
}

export interface ChangeBackgroundImagePathDto {
  /** 背景图片地址 */
  backgroundImagePath?: string;
}

export interface ChangeEmail4PortalDto {
  /** 邮箱 */
  emailAddress?: string;

  /** 短信验证码 */
  code?: string;
}

export interface ChangeMobilePhoneDto {
  /** 新手机号 */
  newMobilePhone?: string;

  /** 短信验证码 */
  code?: string;
}

export interface ChangePasswordDto {
  /**  */
  currentPassword?: string;

  /**  */
  newPassword?: string;
}

export interface ChangePhone4PortalDto {
  /** 手机号码 */
  phone?: string;

  /** 短信验证码 */
  code?: string;
}

export interface ChangeUiThemeInput {
  /**  */
  theme?: string;
}

export interface ChangeUserLanguageDto {
  /**  */
  languageName?: string;
}

export interface CityCreateOrUpdateDto {
  /**  */
  cityName?: string;

  /**  */
  provinceId?: number;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface CityDto {
  /**  */
  cityName?: string;

  /**  */
  cityNameFr?: string;

  /**  */
  provinceId?: number;

  /**  */
  shortCode?: string;

  /**  */
  id?: number;
}

export interface CityDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CityDto[];
}

export interface CmsAnchorCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /** 锚点内容的建议宽度 */
  width?: number;

  /** 锚点内容的建议高度 */
  height?: number;

  /**  */
  id?: number;
}

export interface CmsAnchorDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /** 锚点内容的建议宽度 */
  width?: number;

  /** 锚点内容的建议高度 */
  height?: number;

  /**  */
  id?: number;
}

export interface CmsAnchorDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsAnchorDto[];
}

export interface CmsCategoryCreateOrUpdateDto {
  /**  */
  categoryCode?: string;

  /**  */
  displayName?: string;

  /**  */
  displayNameFr?: string;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  mobileTitleImagePath?: string;

  /**  */
  type?: CmsCategoryType;

  /**  */
  redirectUrl?: string;

  /**  */
  redirectUrlForWeixinMiniProgram?: string;

  /** 破格图片 */
  unconventionalImagePath?: string;

  /** 副标题 */
  contentAlias?: string;

  /** 栏目简介 */
  shortDesc?: string;

  /** 是否显示 */
  isShow?: boolean;

  /** 是否是导航 */
  isNavigation?: boolean;

  /** 导航商品分类关系 */
  productCategoryList?: NavProductCategoryMappingCreateOrUpdateDto[];

  /** 导航商品关系 */
  productList?: NavProductMappingCreateOrUpdateDto[];

  /**  */
  id?: number;
}

export interface CmsCategoryDto {
  /**  */
  categoryCode?: string;

  /**  */
  displayName?: string;

  /**  */
  displayNameFr?: string;

  /** 名称（用于下拉框） */
  fullName?: string;

  /**  */
  description?: string;

  /**  */
  parentId?: number;

  /**  */
  displayOrder?: number;

  /**  */
  titleImagePath?: string;

  /**  */
  mobileTitleImagePath?: string;

  /**  */
  type?: CmsCategoryType;

  /**  */
  redirectUrl?: string;

  /**  */
  redirectUrlForWeixinMiniProgram?: string;

  /** 破格图片 */
  unconventionalImagePath?: string;

  /** 副标题 */
  contentAlias?: string;

  /**  */
  shortDesc?: string;

  /**  */
  isHidden?: boolean;

  /** 是否显示 */
  isShow?: boolean;

  /** 是否是导航 */
  isNavigation?: boolean;

  /** 分类级别 */
  level?: number;

  /** 导航商品分类关系 */
  productCategoryList?: NavProductCategoryMappingDto[];

  /** 导航商品关系 */
  productList?: NavProductMappingDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CmsCategoryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CmsCategoryDto[];
}

export interface CmsCategoryUpdateSortDto {
  /** Id */
  ids?: number[];
}

export interface CountryCreateOrUpdateDto {
  /**  */
  countryName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface CountryDto {
  /**  */
  countryName?: string;

  /**  */
  countryNameFr?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface CountryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CountryDto[];
}

export interface CreateTenantDto {
  /**  */
  tenancyName?: string;

  /**  */
  name?: string;

  /**  */
  adminEmailAddress?: string;

  /**  */
  connectionString?: string;

  /**  */
  isActive?: boolean;
}

export interface CreateUserDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isActive?: boolean;

  /**  */
  roleNames?: string[];

  /**  */
  password?: string;
}

export interface CustomerBaseDto {
  /** 用户Id */
  userId?: number;

  /** 用户账号 */
  userName?: string;

  /** 姓名 */
  realName?: string;

  /** 手机号码 */
  mobilePhone?: string;

  /** 邮箱 */
  emailAddress?: string;

  /**  */
  sex?: Sex;

  /** 头像 */
  avatar?: string;

  /** 客户Id */
  customerId?: number;

  /** 客户编号 */
  customerCode?: string;

  /**  */
  customerType?: CustomerType;

  /** 会员客户-会员有效期 */
  memberValidity?: Date;
}

export interface CustomerBillAddressMappingCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 客户Id */
  customerId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /** 国家Id */
  countryId?: number;

  /** 省Id */
  provinceId?: number;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 邮政编码 */
  postalCode?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface CustomerBillAddressMappingDto {
  /** 客户Id */
  customerId?: number;

  /**  */
  fkCustomer?: CustomerShortDto;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /**  */
  fkCountry?: CountryDto;

  /** 国家Id */
  countryId?: number;

  /**  */
  fkProvince?: ProvinceDto;

  /** 省Id */
  provinceId?: number;

  /**  */
  fkCity?: CityDto;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 邮政编码 */
  postalCode?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface CustomerBillAddressMappingDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CustomerBillAddressMappingDto[];
}

export interface CustomerCreateOrUpdateDto {
  /** 企业客户-企业名称 */
  enterpriseName?: string;

  /** 企业客户-公司称呼 */
  enterpriseCall?: string;

  /** 姓 */
  surname?: string;

  /** 名 */
  name?: string;

  /**  */
  sex?: Sex;

  /** 联系人手机 */
  mobilePhone?: string;

  /** 联系人邮箱 */
  email?: string;

  /** 职称 */
  job?: string;

  /** 密码 */
  password?: string;

  /** 协议信息 */
  agreementInfo?: string;

  /** 客户送货地址关系 */
  receivingAddressList?: CustomerReceivingAddressMappingCreateOrUpdateDto[];

  /** 客户发票地址关系 */
  billAddressList?: CustomerBillAddressMappingCreateOrUpdateDto[];

  /**  */
  id?: number;
}

export interface CustomerCurrentInfoUpdateDto {
  /** 姓名 */
  realName?: string;

  /** 手机号码 */
  mobilePhone?: string;

  /**  */
  sex?: Sex;

  /** 邮箱 */
  emailAddress?: string;

  /** 头像 */
  avatar?: string;
}

export interface CustomerDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkUser?: UserDto;

  /** 用户Id */
  userId?: number;

  /**  */
  customerType?: CustomerType;

  /** 客户编号 */
  customerCode?: string;

  /** 会员客户-加入会员时间 */
  membershipTme?: Date;

  /** 会员客户-会员有效期 */
  memberValidity?: Date;

  /** 企业客户-企业名称 */
  enterpriseName?: string;

  /** 企业客户-公司称呼 */
  enterpriseCall?: string;

  /** 授权平台帐额 */
  accountQuota?: number;

  /**  */
  invoiceType?: InvoiceType;

  /** 协议信息 */
  agreementInfo?: string;

  /** 客户发票地址关系 */
  billAddressList?: CustomerBillAddressMappingDto[];

  /** 客户送货地址关系 */
  receivingAddressList?: CustomerReceivingAddressMappingDto[];

  /** 授权平台帐额锁定金额 */
  lockAmount?: number;

  /** 放账天数 */
  openAccountDays?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface CustomerDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CustomerDto[];
}

export interface CustomerOrderMainCreateOrUpdateDto {
  /** 客户Id */
  customerId?: number;

  /**  */
  receivingMethod?: OrderReceivingMethod;

  /** 发票地址Id */
  billAddressMappingId?: number;

  /** 收货地址Id */
  receivingAddressMappingId?: number;

  /**  */
  payMethod?: OrderPayMethod;

  /** 仓库自提点Id */
  warehouseSelfLiftinPointId?: number;

  /** 发票说明 */
  remark?: string;

  /** 购物车Id */
  cartItemIdList?: number[];

  /**  */
  id?: string;
}

export interface CustomerReceivingAddressMappingCreateOrUpdateDto {
  /** 客户Id */
  customerId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /** 国家Id */
  countryId?: number;

  /** 省Id */
  provinceId?: number;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 邮政编码 */
  postalCode?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface CustomerReceivingAddressMappingDto {
  /** 客户Id */
  customerId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /**  */
  fkCountry?: CountryDto;

  /** 国家Id */
  countryId?: number;

  /**  */
  fkProvince?: ProvinceDto;

  /** 省Id */
  provinceId?: number;

  /**  */
  fkCity?: CityDto;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 邮政编码 */
  postalCode?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface CustomerReceivingAddressMappingDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: CustomerReceivingAddressMappingDto[];
}

export interface CustomerSetEnterpriseAccountQuotaDto {
  /** 企业客户-授权平台帐额 */
  enterpriseAccountQuota?: number;

  /** 放账天数 */
  openAccountDays?: number;

  /**  */
  id?: number;
}

export interface CustomerShortDto {
  /**  */
  fkUser?: UserShortDto;

  /** 用户Id */
  userId?: number;

  /**  */
  customerType?: CustomerType;

  /** 客户编号 */
  customerCode?: string;

  /** 会员客户-加入会员时间 */
  membershipTme?: Date;

  /** 会员客户-会员有效期 */
  memberValidity?: Date;

  /** 企业客户-企业名称 */
  enterpriseName?: string;

  /** 企业客户-公司称呼 */
  enterpriseCall?: string;

  /** 授权平台帐额 */
  accountQuota?: number;

  /**  */
  invoiceType?: InvoiceType;

  /** 协议信息 */
  agreementInfo?: string;

  /**  */
  id?: number;
}

export interface DataDictionaryCreateOrUpdateDto {
  /** 键名 */
  key?: string;

  /** 显示的名称 */
  displayName?: string;

  /**  */
  parentId?: number;

  /** 显示顺序 */
  displayOrder?: number;

  /**  */
  id?: number;
}

export interface DataDictionaryDto {
  /**  */
  tenantId?: number;

  /**  */
  key?: string;

  /**  */
  displayName?: string;

  /**  */
  displayOrder?: number;

  /**  */
  parentId?: number;

  /**  */
  creatorUserId?: number;

  /**  */
  isCustom?: boolean;

  /**  */
  id?: number;
}

export interface DataDictionaryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: DataDictionaryDto[];
}

export interface DataDictionarySyncInput {
  /**  */
  id?: number;

  /**  */
  syncMode?: DataDictionarySyncMode;
}

export interface DataPermissionLimitDto {
  /** 宿主ID，可以是具体的id,也可以是`all`这个关键字 */
  hostId?: string;

  /**  */
  hostType?: DataPermissionLimitHostType;

  /**  */
  targetType?: DataPermissionLimitTargetType;

  /** 被授权的对象 */
  targetId?: string;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: string;
}

export interface DataPermissionLimitUpdateDto {
  /** 宿主ID，可以是具体的id,也可以是`all`这个关键字 */
  hostId?: string[];

  /**  */
  targetType?: DataPermissionLimitTargetType;

  /**  */
  hostType?: DataPermissionLimitHostType;

  /**  */
  targetId?: string[];

  /**  */
  id?: string;
}

export interface DataPermissionSettingDto {
  /**  */
  permissionCode?: string;

  /**  */
  scope?: DataPermissionScopeType;

  /**  */
  details?: string[];
}

export interface DisplayItem {
  /**  */
  id?: string;

  /**  */
  displayName?: string;
}

export interface ExcelRowImportError {
  /**  */
  errorMessage?: string;

  /**  */
  details?: string[];
}

export interface ExcelRowImportResult {
  /**  */
  model?: any | null;

  /**  */
  success?: boolean;

  /**  */
  error?: ExcelRowImportError;

  /**  */
  rowNumber?: number;

  /**  */
  resultType?: ImportResultType;
}

export interface ExternalAuthenticateModel {
  /**  */
  authProvider?: string;

  /**  */
  providerKey?: string;

  /**  */
  providerAccessCode?: string;
}

export interface ExternalAuthenticateResultModel {
  /**  */
  accessToken?: string;

  /**  */
  encryptedAccessToken?: string;

  /**  */
  expireInSeconds?: number;

  /**  */
  waitingForActivation?: boolean;
}

export interface ExternalLoginProviderInfoModel {
  /**  */
  name?: string;

  /**  */
  clientId?: string;
}

export interface FeedbackCreateOrUpdateDto {
  /** 标题 */
  title?: string;

  /** 内容 */
  content?: string;

  /** 联系方式 */
  contactType?: string;

  /** 反馈类型Id */
  typeCodeId?: number;

  /**  */
  sourceType?: FeedbackSourceType;

  /**  */
  id?: number;
}

export interface FeedbackDto {
  /**  */
  creatorUser?: UserShortDto;

  /**  */
  typeCode?: DataDictionaryDto;

  /** 反馈类型Id */
  typeCodeId?: number;

  /** 标题 */
  title?: string;

  /** 内容 */
  content?: string;

  /** 联系方式 */
  contactType?: string;

  /**  */
  sourceType?: FeedbackSourceType;

  /** 用户Id */
  accountId?: number;

  /** 回复人员Id */
  replyUserId?: number;

  /** 回复内容 */
  replyContent?: string;

  /** 机构用户Id */
  orgAccountId?: number;

  /**  */
  feedbackTime?: Date;

  /** 回复时间 */
  replyTime?: Date;

  /** 是否显示在官网 */
  isShowPortal?: boolean;

  /** 反馈信息回复 */
  feedbackReplies?: FeedbackReplyDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FeedbackDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FeedbackDto[];
}

export interface FeedbackReplyCreateOrUpdateDto {
  /** 反馈信息Id */
  feedbackId?: number;

  /** 回复内容 */
  content?: string;

  /**  */
  id?: number;
}

export interface FeedbackReplyDto {
  /**  */
  creatorUser?: UserShortDto;

  /** 反馈信息Id */
  feedbackId?: number;

  /** 回复内容 */
  content?: string;

  /** 是否隐藏 */
  isHide?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FeedbackReplyDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FeedbackReplyDto[];
}

export interface FinanceMemberPaymentOrderCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 客户Id */
  customerId?: number;

  /** 订单编号 */
  orderCode?: string;

  /** 第三方交易编号 */
  thirdPartyTransactionCode?: string;

  /** 会费金额 */
  membershipFeeAmount?: number;

  /**  */
  payMethod?: OrderPayMethod;

  /** 支付时间 */
  payTime?: Date;

  /**  */
  id?: number;
}

export interface FinanceMemberPaymentOrderDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkCustomer?: CustomerDto;

  /** 客户Id */
  customerId?: number;

  /** 订单编号 */
  orderCode?: string;

  /** 第三方交易编号 */
  thirdPartyTransactionCode?: string;

  /** 会费金额 */
  membershipFeeAmount?: number;

  /**  */
  payMethod?: OrderPayMethod;

  /** 支付时间 */
  payTime?: Date;

  /** 是否已支付 */
  isPaid?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FinanceMemberPaymentOrderDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FinanceMemberPaymentOrderDto[];
}

export interface FinanceSalesStatisticCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 订单Id */
  orderId?: string;

  /** 已付金额 */
  paidAmount?: number;

  /** 欠款金额 */
  arrearsAmount?: number;

  /** 还款截至日期 */
  repaymentDeadline?: Date;

  /**  */
  id?: number;
}

export interface FinanceSalesStatisticDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkOrder?: OrderMainDto;

  /** 订单Id */
  orderId?: string;

  /** 已付金额 */
  paidAmount?: number;

  /** 欠款金额 */
  arrearsAmount?: number;

  /** 还款截至日期 */
  repaymentDeadline?: Date;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface FinanceSalesStatisticDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: FinanceSalesStatisticDto[];
}

export interface FinanceSalesStatisticPagedResultRequestDto {
  /** 是否只看自己创建的数据 */
  isOnlyMine?: boolean;

  /** 订单编号 */
  orderCode?: string;

  /**  */
  status?: OrderStatus;

  /** 每页记录数，用作替换MaxResultCount */
  pageSize?: number;

  /**  */
  skipCount?: number;

  /** 页码，用来替换SkipCount */
  pageIndex?: number;

  /**  */
  sorting?: string;

  /**  */
  maxResultCount?: number;
}

export interface GetCurrentLoginInformationsOutput {
  /**  */
  application?: ApplicationInfoDto;

  /**  */
  user?: UserLoginInfoDto;

  /**  */
  permissions?: string[];

  /**  */
  roles?: string[];

  /**  */
  tenant?: TenantLoginInfoDto;
}

export interface GetRoleForEditOutput {
  /**  */
  role?: RoleEditDto;

  /**  */
  permissions?: PermissionDefinitionDto[];

  /**  */
  grantedPermissionNames?: string[];
}

export interface GuidEntityDto {
  /**  */
  id?: string;
}

export interface ImportResult {
  /**  */
  excelFileName?: string;

  /**  */
  importResults?: ExcelRowImportResult[];
}

export interface Int32EntityDto {
  /**  */
  id?: number;
}

export interface Int64BatchId {
  /** Id */
  idList?: number[];
}

export interface Int64EntityDto {
  /**  */
  id?: number;
}

export interface IsTenantAvailableInput {
  /**  */
  tenancyName?: string;
}

export interface IsTenantAvailableOutput {
  /**  */
  state?: TenantAvailabilityState;

  /**  */
  tenantId?: number;
}

export interface LanguageTextCreateDto {
  /** Key */
  key?: string;
}

export interface LanguageTextDeleteDto {
  /** Key */
  key?: string;
}

export interface LanguageTextDto {
  /** LanguageName */
  languageName?: string;

  /** Source */
  source?: string;

  /** Key */
  key?: string;

  /** Value */
  value?: string;

  /**  */
  id?: number;
}

export interface LanguageTextTreeDto {
  /** Key */
  key?: string;

  /** Values */
  values?: ValuesItem[];
}

export interface LanguageTextTreeDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: LanguageTextTreeDto[];
}

export interface LanguageTextUpdateKeyDto {
  /** OldKey */
  oldKey?: string;

  /** NewKey */
  newKey?: string;
}

export interface LanguageTextUpdateValueDto {
  /** LanguageName */
  languageName?: string;

  /** Key */
  key?: string;

  /** Value */
  value?: string;
}

export interface NavProductCategoryMappingCreateOrUpdateDto {
  /** 商品分类Id */
  productCategoryId?: number;

  /**  */
  id?: number;
}

export interface NavProductCategoryMappingDto {
  /** Cms分类Id */
  cmsCategoryId?: number;

  /**  */
  fkProductCategory?: ProductCategoryShortDto;

  /** 商品分类Id */
  productCategoryId?: number;

  /**  */
  id?: number;
}

export interface NavProductMappingCreateOrUpdateDto {
  /** 商品Id */
  productId?: number;

  /**  */
  id?: number;
}

export interface NavProductMappingDto {
  /** Cms分类Id */
  cmsCategoryId?: number;

  /**  */
  fkProduct?: ProductDto;

  /** 商品Id */
  productId?: number;

  /**  */
  id?: number;
}

export interface OrderCheckInfoDto {
  /** 订单Id */
  orderId?: string;

  /** 错误信息 */
  errorMsg?: string;

  /** 是否发生错误 */
  isHasError?: boolean;
}

export interface OrderCustomerBillAddressMappingShortDto {
  /** 客户Id */
  customerId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /**  */
  fkCountry?: CountryDto;

  /** 国家Id */
  countryId?: number;

  /**  */
  fkProvince?: ProvinceDto;

  /** 省Id */
  provinceId?: number;

  /**  */
  fkCity?: CityDto;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 邮政编码 */
  postalCode?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface OrderCustomerReceivingAddressMappingShortDto {
  /** 客户Id */
  customerId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /**  */
  fkCountry?: CountryDto;

  /** 国家Id */
  countryId?: number;

  /**  */
  fkProvince?: ProvinceDto;

  /** 省Id */
  provinceId?: number;

  /**  */
  fkCity?: CityDto;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 邮政编码 */
  postalCode?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  id?: number;
}

export interface OrderDetailDto {
  /** 客户Id */
  customerId?: number;

  /** 订单Id */
  orderId?: string;

  /**  */
  fkProduct?: ProductShortDto;

  /** 商品Id */
  productId?: number;

  /**  */
  fkProductSku?: ProductSkuShortDto;

  /** 商品skuId */
  productSkuId?: number;

  /** 数量 */
  quantity?: number;

  /** 价格 */
  price?: number;

  /**  */
  id?: number;
}

export interface OrderMainActualOutStoragePostDto {
  /** 物流公司 */
  logisticsCompany?: string;

  /** 物流单号 */
  logisticsOrderNo?: string;

  /** 出库仓库 */
  warehouseId?: number;

  /**  */
  id?: string;
}

export interface OrderMainCancelPostDto {
  /** 取消原因 */
  cancelReason?: string;

  /**  */
  id?: string;
}

export interface OrderMainConfirmPaymentDto {
  /** 第三方交易编号 */
  thirdPartyTransactionCode?: string;

  /** 支付时间 */
  payTime?: Date;

  /**  */
  id?: string;
}

export interface OrderMainCreateOrUpdateDto {
  /** 客户Id */
  customerId?: number;

  /**  */
  receivingMethod?: OrderReceivingMethod;

  /** 发票地址Id */
  billAddressMappingId?: number;

  /** 收货地址Id */
  receivingAddressMappingId?: number;

  /**  */
  payMethod?: OrderPayMethod;

  /** 仓库自提点Id */
  warehouseSelfLiftinPointId?: number;

  /** 发票说明 */
  remark?: string;

  /** 明细 */
  detailList?: ProductSkuStockDto[];

  /**  */
  id?: string;
}

export interface OrderMainDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkCustomer?: CustomerShortDto;

  /** 客户Id */
  customerId?: number;

  /** 订单编号 */
  orderCode?: string;

  /** 订单当日序号（日期+6位数的序号，如：2022JUN15000001） */
  orderIndexToday?: string;

  /** 第三方交易编号 */
  thirdPartyTransactionCode?: string;

  /**  */
  fkPlaceOrderOnBehalfHandler?: UserShortDto;

  /** 代下订单的经手人Id */
  placeOrderOnBehalfHandlerId?: number;

  /**  */
  sourceType?: OrderSourceType;

  /**  */
  status?: OrderStatus;

  /**  */
  payMethod?: OrderPayMethod;

  /**  */
  receivingMethod?: OrderReceivingMethod;

  /** 商品sku总数量 */
  productSkuTotalQuantity?: number;

  /** 商品sku总价格 */
  productSkuTotalPrice?: number;

  /** 税金GST */
  taxesGST?: number;

  /** 税金QST */
  taxesQST?: number;

  /** 税率GST */
  taxesGSTRate?: number;

  /** 税率QST */
  taxesQSTRate?: number;

  /** 运费 */
  tranExpenses?: number;

  /** 订单总金额（含税金+运费） */
  totalAmount?: number;

  /**  */
  fkBillAddressMapping?: OrderCustomerBillAddressMappingShortDto;

  /** 发票地址Id */
  billAddressMappingId?: number;

  /**  */
  fkReceivingAddressMapping?: OrderCustomerReceivingAddressMappingShortDto;

  /** 收货地址Id */
  receivingAddressMappingId?: number;

  /**  */
  fkWarehouseSelfLiftinPoint?: WarehouseSelfLiftinPointShortDto;

  /** 仓库自提点Id */
  warehouseSelfLiftinPointId?: number;

  /** 支付时间 */
  payTime?: Date;

  /**  */
  fkWarehouse?: Warehouse;

  /** 出货仓库Id */
  warehouseId?: number;

  /** 出库时间 */
  outStorageTime?: Date;

  /** 收货时间 */
  receivedTime?: Date;

  /** 取消时间 */
  cancelTime?: Date;

  /** 取消原因 */
  cancelReason?: string;

  /** 出库编号 */
  outStorageCode?: string;

  /**  */
  outStorageType?: OutStorageType;

  /**  */
  fkOutStorageHandler?: UserShortDto;

  /** 出库的经手人Id */
  outStorageHandlerId?: number;

  /** 物流公司 */
  logisticsCompany?: string;

  /** 物流单号 */
  logisticsOrderNo?: string;

  /** 预计送达日期 */
  expectedDeliveryDate?: Date;

  /** 是否扣减库存 */
  isDeductionStock?: boolean;

  /** 生成的发票路径 */
  generatedInvoicePath?: string;

  /** 发票说明 */
  remark?: string;

  /** 订单明细表 */
  detailList?: OrderDetailDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: string;
}

export interface OrderMainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: OrderMainDto[];
}

export interface OrderMainStoragePostDto {
  /** 预计送达日期 */
  expectedDeliveryDate?: Date;

  /**  */
  id?: string;
}

export interface OrdinaryCustomerCreateDto {
  /** 短信验证码 */
  code?: string;

  /** 姓名 */
  realName?: string;

  /** 手机号码 */
  mobilePhone?: string;

  /** 邮箱 */
  emailAddress?: string;

  /** 密码 */
  password?: string;

  /**  */
  receivingAddress?: CustomerReceivingAddressMappingCreateOrUpdateDto;

  /**  */
  id?: number;
}

export interface PermissionDefinitionDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  properties?: object;

  /**  */
  children?: PermissionDefinitionDto[];
}

export interface PermissionDefinitionScope {}

export interface PermissionDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  id?: number;
}

export interface PermissionDtoListResultDto {
  /**  */
  items?: PermissionDto[];
}

export interface PreparePlaceOrderOnBehalfPostDto {
  /** 邮箱 */
  emailAddress?: string;

  /** 客户编号 */
  customerCode?: string;
}

export interface ProductBasicParamCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 参数名称 */
  name?: string;

  /** 参数名称（法语） */
  nameFr?: string;

  /** 参数描述 */
  description?: string;

  /**  */
  inputType?: ProductParamInputType;

  /** 可选值列表 */
  inputList?: string;

  /**  */
  id?: number;
}

export interface ProductBasicParamDto {
  /** 租户Id */
  tenantId?: number;

  /** 参数名称 */
  name?: string;

  /** 参数名称（法语） */
  nameFr?: string;

  /** 名称（用于下拉框） */
  fullName?: string;

  /** 参数描述 */
  description?: string;

  /**  */
  inputType?: ProductParamInputType;

  /** 可选值列表 */
  inputList?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductBasicParamDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductBasicParamDto[];
}

export interface ProductBasicParamMappingShortDto {
  /** 商品Id */
  productId?: number;

  /**  */
  fkBasicParam?: ProductBasicParamShortDto;

  /** 商品基础参数Id */
  basicParamId?: number;

  /** 参数值 */
  value?: string;

  /** 参数值（法语） */
  valueFr?: string;

  /**  */
  id?: number;
}

export interface ProductBasicParamShortDto {
  /** 参数名称 */
  name?: string;

  /** 参数名称（法语） */
  nameFr?: string;

  /** 参数全称 */
  fullName?: string;

  /** 参数描述 */
  description?: string;

  /**  */
  inputType?: ProductParamInputType;

  /** 可选值列表 */
  inputList?: string;

  /**  */
  id?: number;
}

export interface ProductBrandCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 品牌名称 */
  name?: string;

  /** 品牌名称（法语） */
  nameFr?: string;

  /** 品牌描述 */
  description?: string;

  /** 品牌图片地址 */
  imagePath?: string;

  /** 排序 */
  displayOrder?: number;

  /**  */
  id?: number;
}

export interface ProductBrandDto {
  /** 租户Id */
  tenantId?: number;

  /** 品牌名称 */
  name?: string;

  /** 品牌名称（法语） */
  nameFr?: string;

  /** 名称（用于下拉框） */
  fullName?: string;

  /** 品牌描述 */
  description?: string;

  /** 品牌图片地址 */
  imagePath?: string;

  /** 排序 */
  displayOrder?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductBrandDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductBrandDto[];
}

export interface ProductCategoryCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 父级分类Id */
  parentId?: number;

  /** 分类名称 */
  name?: string;

  /** 分类名称（法语） */
  nameFr?: string;

  /** 分类描述 */
  description?: string;

  /** 排序 */
  displayOrder?: number;

  /**  */
  id?: number;
}

export interface ProductCategoryDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkParent?: ProductCategoryDto;

  /** 父级分类Id */
  parentId?: number;

  /** 父级分类路径 */
  parentIdPath?: string;

  /** 分类级别 */
  level?: number;

  /** 分类名称 */
  name?: string;

  /** 分类名称（法语） */
  nameFr?: string;

  /** 名称（用于下拉框） */
  fullName?: string;

  /** 分类描述 */
  description?: string;

  /** 排序 */
  displayOrder?: number;

  /** 父级分类路径 */
  parentNamePath?: string;

  /** 商品数量 */
  productCount?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductCategoryDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductCategoryDto[];
}

export interface ProductCategoryShortDto {
  /**  */
  fkParent?: ProductCategoryShortDto;

  /** 父级分类Id */
  parentId?: number;

  /** 父级分类路径 */
  parentIdPath?: string;

  /** 分类级别 */
  level?: number;

  /** 分类名称 */
  name?: string;

  /** 分类描述 */
  description?: string;

  /** 排序 */
  displayOrder?: number;

  /** 父级分类路径 */
  parentNamePath?: string;

  /**  */
  id?: number;
}

export interface ProductCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 分类Id */
  categoryId?: number;

  /** 品牌Id */
  brandId?: number;

  /** 商品名称 */
  name?: string;

  /** 商品名称（法语） */
  nameFr?: string;

  /** 商品主图Id */
  mainImageId?: number;

  /** 商品描述 */
  description?: string;

  /** 商品描述（法语） */
  descriptionFr?: string;

  /** 说明书 */
  instructionsPath?: string;

  /** 安装视频 */
  installationVideoPath?: string;

  /** 仓库自提点Id */
  warehouseSelfLiftinPointId?: number;

  /** 基础参数 */
  basicParamList?: ProductParamCreateOrUpdateDto[];

  /** 商品sku */
  skuList?: ProductSkuCreateOrUpdateDto[];

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface ProductDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkCategory?: ProductCategoryDto;

  /** 分类Id */
  categoryId?: number;

  /**  */
  fkBrand?: ProductBrandDto;

  /** 品牌Id */
  brandId?: number;

  /** 商品名称 */
  name?: string;

  /** 商品名称（法语） */
  nameFr?: string;

  /** 名称（用于下拉框） */
  fullName?: string;

  /**  */
  fkMainImage?: AttachmentDto;

  /**  */
  imageList?: AttachmentDto[];

  /** 商品主图Id */
  mainImageId?: number;

  /** 商品描述 */
  description?: string;

  /** 商品描述（法语） */
  descriptionFr?: string;

  /** 说明书 */
  instructionsPath?: string;

  /** 安装视频 */
  installationVideoPath?: string;

  /**  */
  fkWarehouseSelfLiftinPoint?: WarehouseSelfLiftinPointDto;

  /** 仓库自提点Id */
  warehouseSelfLiftinPointId?: number;

  /** 销量 */
  salesVolume?: number;

  /** 商品库存 */
  totalStock?: number;

  /** 商品基础参数关系 */
  basicParamMappingList?: ProductBasicParamMappingShortDto[];

  /** 商品sku规格参数关系 */
  specParamMappingList?: ProductSpecParamMappingShortDto[];

  /** 商品sku */
  skuList?: ProductSkuShortDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductDto[];
}

export interface ProductMappingCreateOrUpdateDto {
  /** 原始商品Id */
  sourceProductId?: number;

  /** 目标商品Id */
  targetProductIds?: number[];

  /** 目标商品skuId */
  targetProductSkuIds?: number[];

  /**  */
  type?: ProductMappingType;

  /**  */
  id?: number;
}

export interface ProductMappingDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkSourceProduct?: ProductDto;

  /** 原始商品Id */
  sourceProductId?: number;

  /**  */
  fkTargetProduct?: ProductDto;

  /** 目标商品Id */
  targetProductId?: number;

  /**  */
  fkTargetProductSku?: ProductSkuDto;

  /** 目标商品skuId */
  targetProductSkuId?: number;

  /**  */
  type?: ProductMappingType;

  /**  */
  id?: number;
}

export interface ProductMappingDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductMappingDto[];
}

export interface ProductParamCreateOrUpdateDto {
  /** 参数值 */
  value?: string;

  /** 参数值（法语） */
  valueFr?: string;

  /**  */
  id?: number;
}

export interface ProductRevisePriceRecordCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 商品skuId */
  productSkuId?: number;

  /** 客户Id */
  customerId?: number;

  /** 修改后的工厂价 */
  revisedFactoryPrice?: number;

  /** 备注 */
  remark?: string;

  /**  */
  id?: number;
}

export interface ProductRevisePriceRecordDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  creatorUser?: UserShortDto;

  /**  */
  fkProductSku?: ProductSkuDto;

  /** 商品skuId */
  productSkuId?: number;

  /**  */
  fkCustomer?: CustomerShortDto;

  /** 客户Id */
  customerId?: number;

  /** 原始的工厂价 */
  originalFactoryPrice?: number;

  /** 修改后的工厂价 */
  revisedFactoryPrice?: number;

  /** 备注 */
  remark?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductRevisePriceRecordDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductRevisePriceRecordDto[];
}

export interface ProductRevisePriceRecordMainDto {
  /** 会员ID */
  customerId?: number;

  /** 会员编号 */
  customerCode?: string;

  /** 会员名称 */
  customerRealName?: string;

  /** 原始的工厂价 */
  factoryPrice?: number;

  /** 修改后的工厂价 */
  revisedFactoryPrice?: number;

  /** 备注 */
  remark?: string;

  /** 调整时间 */
  creationTime?: Date;

  /** 操作人 */
  operatorRealName?: string;

  /**  */
  id?: number;
}

export interface ProductRevisePriceRecordMainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductRevisePriceRecordMainDto[];
}

export interface ProductShortDto {
  /** 分类Id */
  categoryId?: number;

  /**  */
  fkBrand?: ProductBrandDto;

  /** 品牌Id */
  brandId?: number;

  /** 商品名称 */
  name?: string;

  /** 商品名称 */
  nameFr?: string;

  /**  */
  fkMainImage?: AttachmentDto;

  /** 商品主图Id */
  mainImageId?: number;

  /** 商品描述 */
  description?: string;

  /** 商品描述 */
  descriptionFr?: string;

  /** 说明书 */
  instructionsPath?: string;

  /** 安装视频 */
  installationVideoPath?: string;

  /** 商品库存 */
  totalStock?: number;

  /** 销量 */
  salesVolume?: number;

  /**  */
  id?: number;
}

export interface ProductSkuCountDto {
  /** 全部商品 */
  all?: number;

  /** 未上架 */
  notOnShelf?: number;

  /** 已上架 */
  onShelf?: number;

  /** 已下架 */
  removedFromShelf?: number;
}

export interface ProductSkuCreateOrUpdateDto {
  /** 规格参数 */
  specParamList?: ProductParamCreateOrUpdateDto[];

  /** 图片地址 */
  imagePath?: string;

  /** 计量单位 */
  unitId?: number;

  /** 型号（用户手工输入） */
  model?: string;

  /**  */
  id?: number;
}

export interface ProductSkuDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkProduct?: ProductDto;

  /** 商品Id */
  productId?: number;

  /** 图片地址 */
  imagePath?: string;

  /** 编号（系统自动生成） */
  number?: string;

  /** 型号（用户手工输入） */
  model?: string;

  /** 商品库存 */
  totalStock?: number;

  /** 上架库存 */
  availableStock?: number;

  /** 预警库存 */
  earlyWarningStock?: number;

  /** 最小起购量 */
  minPurchaseQuantity?: number;

  /** 销量 */
  salesVolume?: number;

  /** 销售价格 */
  salePrice?: number;

  /** 会员价格 */
  memberPrice?: number;

  /** 工厂价 */
  factoryPrice?: number;

  /**  */
  status?: ProductSkuStatus;

  /** 是否推荐 */
  isRecommend?: boolean;

  /**  */
  fkUnit?: DataDictionaryDto;

  /** 计量单位 */
  unitId?: number;

  /** 商品sku规格参数关系 */
  specParamMappingStr?: string;

  /** 商品sku规格参数关系（法语） */
  specParamMappingStrFr?: string;

  /** 是否正在同步库存 */
  isSyncingStock?: boolean;

  /** 修改后的工厂价 */
  revisedFactoryPrice?: number;

  /** 商品sku规格参数关系 */
  specParamMappingList?: ProductSpecParamMappingShortDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductSkuDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductSkuDto[];
}

export interface ProductSkuOnShelfPostDto {
  /** 上架库存 */
  availableStock?: number;

  /** 销售价格 */
  salePrice?: number;

  /** 会员价格 */
  memberPrice?: number;

  /** 工厂价 */
  factoryPrice?: number;

  /** 最小起购量 */
  minPurchaseQuantity?: number;

  /**  */
  id?: number;
}

export interface ProductSkuShortDto {
  /** 商品Id */
  productId?: number;

  /** 图片地址 */
  imagePath?: string;

  /** 编号（系统自动生成） */
  number?: string;

  /** 型号（用户手工输入） */
  model?: string;

  /** 商品库存 */
  totalStock?: number;

  /** 上架库存 */
  availableStock?: number;

  /** 预警库存 */
  earlyWarningStock?: number;

  /** 最小起购量 */
  minPurchaseQuantity?: number;

  /** 销量 */
  salesVolume?: number;

  /** 销售价格 */
  salePrice?: number;

  /** 会员价格 */
  memberPrice?: number;

  /** 工厂价 */
  factoryPrice?: number;

  /**  */
  status?: ProductSkuStatus;

  /** 是否推荐 */
  isRecommend?: boolean;

  /**  */
  fkUnit?: DataDictionaryDto;

  /** 计量单位 */
  unitId?: number;

  /** 商品sku规格参数关系 */
  specParamMappingStr?: string;

  /** 商品sku规格参数关系（法语） */
  specParamMappingStrFr?: string;

  /** 是否正在同步库存 */
  isSyncingStock?: boolean;

  /** 前端显示的价格（已经针对不同的客户类型做了区分） */
  viewPrice?: number;

  /** 商品sku规格参数关系 */
  specParamMappingList?: ProductSpecParamMappingShortDto[];

  /** 是否启用 */
  isActive?: boolean;

  /**  */
  id?: number;
}

export interface ProductSkuStockDto {
  /**  */
  quantity?: number;

  /**  */
  id?: number;
}

export interface ProductSpecParamCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 参数名称 */
  name?: string;

  /** 规格名称（法语） */
  nameFr?: string;

  /** 参数描述 */
  description?: string;

  /**  */
  inputType?: ProductParamInputType;

  /** 可选值列表 */
  inputList?: string;

  /**  */
  id?: number;
}

export interface ProductSpecParamDto {
  /** 租户Id */
  tenantId?: number;

  /** 参数名称 */
  name?: string;

  /** 规格名称（法语） */
  nameFr?: string;

  /** 名称（用于下拉框） */
  fullName?: string;

  /** 参数描述 */
  description?: string;

  /**  */
  inputType?: ProductParamInputType;

  /** 可选值列表 */
  inputList?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface ProductSpecParamDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProductSpecParamDto[];
}

export interface ProductSpecParamMappingShortDto {
  /** 商品Id */
  productId?: number;

  /** 商品skuId */
  productSkuId?: number;

  /**  */
  fkSpecParam?: ProductSpecParamShortDto;

  /** 商品规格参数Id */
  specParamId?: number;

  /** 参数值 */
  value?: string;

  /** 参数值（法语） */
  valueFr?: string;

  /**  */
  id?: number;
}

export interface ProductSpecParamShortDto {
  /** 参数名称 */
  name?: string;

  /** 规格名称（法语） */
  nameFr?: string;

  /** 规格全称 */
  fullName?: string;

  /** 参数描述 */
  description?: string;

  /**  */
  inputType?: ProductParamInputType;

  /** 可选值列表 */
  inputList?: string;

  /**  */
  id?: number;
}

export interface ProvinceCreateOrUpdateDto {
  /**  */
  provinceName?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface ProvinceDto {
  /**  */
  provinceName?: string;

  /**  */
  provinceNameFr?: string;

  /**  */
  shortName?: string;

  /**  */
  id?: number;
}

export interface ProvinceDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: ProvinceDto[];
}

export interface RegisterInput {
  /**  */
  surname?: string;

  /**  */
  name?: string;

  /**  */
  userName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  password?: string;

  /**  */
  roleNames?: string;

  /**  */
  sex?: Sex;

  /**  */
  captchaResponse?: string;

  /**  */
  code?: string;

  /**  */
  userRegisterType?: UserRegisterType;

  /**  */
  ticket?: string;

  /**  */
  wechatNumber?: string;

  /**  */
  openId?: string;

  /**  */
  miniOpenId?: string;

  /**  */
  unionId?: string;

  /**  */
  encryptedData?: string;

  /**  */
  iv?: string;

  /**  */
  session_key?: string;

  /**  */
  scene?: string;

  /**  */
  realName?: string;

  /**  */
  mobilePhone?: string;
}

export interface RegisterOutput {
  /**  */
  userId?: number;

  /**  */
  canLogin?: boolean;
}

export interface ResetPassword4PortalDto {
  /** 手机号码 */
  phone?: string;

  /** 新密码 */
  newPassword?: string;

  /** 短信验证码 */
  code?: string;
}

export interface ResetPassword4PortalEmailDto {
  /** 邮箱 */
  emailAddress?: string;

  /** 新密码 */
  newPassword?: string;

  /** 验证码 */
  code?: string;
}

export interface ResetPasswordDto {
  /**  */
  adminPassword?: string;

  /**  */
  userId?: number;

  /**  */
  newPassword?: string;
}

export interface RoleCreateOrUpdateDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /**  */
  roleScopes?: PermissionDefinitionScope[];

  /**  */
  id?: number;
}

export interface RoleDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  normalizedName?: string;

  /**  */
  description?: string;

  /**  */
  grantedPermissions?: string[];

  /**  */
  id?: number;
}

export interface RoleDtoListResultDto {
  /**  */
  items?: RoleDto[];
}

export interface RoleDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: RoleDto[];
}

export interface RoleEditDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  description?: string;

  /**  */
  isStatic?: boolean;

  /**  */
  id?: number;
}

export interface RoleGrantPermissionInput {
  /**  */
  roleId?: number;

  /**  */
  grantedPermissions?: string[];

  /**  */
  dataPermissionSettings?: DataPermissionSettingDto[];
}

export interface RoleListDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  isStatic?: boolean;

  /**  */
  isDefault?: boolean;

  /**  */
  creationTime?: Date;

  /**  */
  id?: number;
}

export interface RoleListDtoListResultDto {
  /**  */
  items?: RoleListDto[];
}

export interface RoleMiniDto {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  id?: number;
}

export interface SelectListGroup {
  /**  */
  disabled?: boolean;

  /**  */
  name?: string;
}

export interface SelectListItem {
  /**  */
  disabled?: boolean;

  /**  */
  group?: SelectListGroup;

  /**  */
  selected?: boolean;

  /**  */
  text?: string;

  /**  */
  value?: string;
}

export interface SiteMessageDto {
  /**  */
  title?: string;

  /**  */
  content?: string;

  /**  */
  hasReaded?: boolean;

  /**  */
  creationTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  jsonData?: string;

  /**  */
  url?: string;

  /**  */
  id?: string;
}

export interface SiteMessageDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SiteMessageDto[];
}

export interface StringEntityDto {
  /**  */
  id?: string;
}

export interface SupplierCreateOrUpdateDto {
  /** 供应商名称 */
  supplierName?: string;

  /** 联系人 */
  realName?: string;

  /** 联系电话 */
  mobilePhone?: string;

  /** 联系邮箱 */
  email?: string;

  /**  */
  id?: number;
}

export interface SupplierDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkUser?: UserDto;

  /** 用户Id */
  userId?: number;

  /** 供应商编号 */
  supplierCode?: string;

  /** 供应商名称 */
  supplierName?: string;

  /** 是否可视出售价格 */
  isVisibleSalePrice?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface SupplierDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SupplierDto[];
}

export interface SupplierProductSaleDetailCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 商品销售主表Id */
  productSaleMainId?: number;

  /** 商品skuId */
  productSkuId?: number;

  /** 销售时间 */
  salesTime?: Date;

  /** 数量 */
  quantity?: number;

  /** 成本单价 */
  costPrice?: number;

  /** 成本总价 */
  totalCostPrice?: number;

  /**  */
  id?: number;
}

export interface SupplierProductSaleDetailDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkProductSaleMain?: SupplierProductSaleMainDto;

  /** 商品销售主表Id */
  productSaleMainId?: number;

  /**  */
  fkProductSku?: ProductSkuDto;

  /** 商品skuId */
  productSkuId?: number;

  /** 销售时间 */
  salesTime?: Date;

  /** 数量 */
  quantity?: number;

  /** 成本单价 */
  costPrice?: number;

  /** 成本总价 */
  totalCostPrice?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface SupplierProductSaleDetailDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SupplierProductSaleDetailDto[];
}

export interface SupplierProductSaleMainCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 供应商Id */
  supplierId?: number;

  /** 销售时间 */
  salesTime?: Date;

  /** 成本总价 */
  totalCostPrice?: number;

  /** 实际结算金额 */
  actualSettlementAmount?: number;

  /** 结算时间 */
  settlementTime?: Date;

  /** 结算的经手人Id */
  settlementHandlerId?: number;

  /**  */
  id?: number;
}

export interface SupplierProductSaleMainDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkSupplier?: SupplierDto;

  /** 供应商Id */
  supplierId?: number;

  /** 销售时间 */
  salesTime?: Date;

  /** 销售总价 */
  totalSalesPrice?: number;

  /** 成本总价 */
  totalCostPrice?: number;

  /** 实际结算金额 */
  actualSettlementAmount?: number;

  /** 结算时间 */
  settlementTime?: Date;

  /**  */
  fkSettlementHandler?: UserDto;

  /** 结算的经手人Id */
  settlementHandlerId?: number;

  /** 是否已结算(结算状态) */
  isSettled?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface SupplierProductSaleMainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SupplierProductSaleMainDto[];
}

export interface SupplierProductSaleSettlementPostDto {
  /** 实际结算金额 */
  actualSettlementAmount?: number;

  /**  */
  id?: number;
}

export interface SwiperCreateOrUpdateDto {
  /**  */
  redirectUrl?: string;

  /**  */
  displayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /** 名称 */
  displayName?: string;

  /**  */
  remark?: string;

  /**  */
  cmsAnchorId?: number;

  /**  */
  id?: number;
}

export interface SwiperDto {
  /**  */
  redirectUrl?: string;

  /**  */
  displayOrder?: number;

  /**  */
  titleImageUrl?: string;

  /** 名称 */
  displayName?: string;

  /**  */
  cmsAnchorId?: number;

  /**  */
  remark?: string;

  /**  */
  anchor?: CmsAnchorDto;

  /**  */
  id?: number;
}

export interface SwiperDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SwiperDto[];
}

export interface SysSettingOrderCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  inventoryDeductionMethod?: InventoryDeductionMethod;

  /** 待付款订单取消时间，单位：分钟 */
  cancelTimeOfPendingPayOrder?: number;

  /** 订单金额阈值 */
  orderAmountThreshold?: number;

  /** 订单金额小于等于阈值的运费 */
  lessThanOrEqualTranExpenses?: number;

  /** 订单金额大于阈值的运费 */
  greaterThanTranExpenses?: number;

  /**  */
  id?: number;
}

export interface SysSettingOrderDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  inventoryDeductionMethod?: InventoryDeductionMethod;

  /** 订单金额阈值 */
  orderAmountThreshold?: number;

  /** 订单金额小于等于阈值的运费 */
  lessThanOrEqualTranExpenses?: number;

  /** 订单金额大于阈值的运费 */
  greaterThanTranExpenses?: number;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface SysSettingOrderDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SysSettingOrderDto[];
}

export interface SysSettingTaxeCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 省Id */
  provinceId?: number;

  /** 税金GST */
  taxesGST?: number;

  /** 税金QST */
  taxesQST?: number;

  /** 备注 */
  remark?: string;

  /**  */
  id?: number;
}

export interface SysSettingTaxeDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkProvince?: ProvinceDto;

  /** 省Id */
  provinceId?: number;

  /** 税金GST */
  taxesGST?: number;

  /** 税金QST */
  taxesQST?: number;

  /** 备注 */
  remark?: string;

  /** 是否默认 */
  isDefault?: boolean;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface SysSettingTaxeDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: SysSettingTaxeDto[];
}

export interface TenantDto {
  /**  */
  tenancyName?: string;

  /**  */
  name?: string;

  /**  */
  isActive?: boolean;

  /**  */
  id?: number;
}

export interface TenantDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TenantDto[];
}

export interface TenantLoginInfoDto {
  /**  */
  tenancyName?: string;

  /**  */
  name?: string;

  /**  */
  id?: number;
}

export interface TownCreateOrUpdateDto {
  /**  */
  areaId?: number;

  /**  */
  townName?: string;

  /**  */
  id?: number;
}

export interface TownDto {
  /**  */
  areaId?: number;

  /**  */
  townName?: string;

  /**  */
  townNameFr?: string;

  /**  */
  id?: number;
}

export interface TownDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: TownDto[];
}

export interface UploadResult {
  /**  */
  hostType?: AttachmentHostType;

  /**  */
  attachmentId?: number;

  /**  */
  fileName?: string;

  /**  */
  url?: string;
}

export interface UserDto {
  /**  */
  userName?: string;

  /** 姓名 */
  realName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  sex?: Sex;

  /**  */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /** 状态 */
  isActive?: boolean;

  /**  */
  fullName?: string;

  /**  */
  lastLoginTime?: Date;

  /** 注册时间 */
  creationTime?: Date;

  /**  */
  roleNames?: string[];

  /** 头像 */
  avatar?: string;

  /** 身份证号 */
  idCardNo?: string;

  /** 银行卡号 */
  bankCardNo?: string;

  /** 开户行 */
  bankDeposit?: string;

  /**  */
  id?: number;
}

export interface UserDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: UserDto[];
}

export interface UserLocationDto {
  /** 省Id */
  provinceId?: number;

  /**  */
  province?: ProvinceDto;

  /** 市Id */
  cityId?: number;

  /**  */
  city?: CityDto;

  /** 区Id */
  areaId?: number;

  /**  */
  area?: AreaDto;

  /** 详细地址 */
  address?: string;
}

export interface UserLoginInfoDto {
  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  userName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  avatar?: string;

  /**  */
  realName?: string;

  /**  */
  headImage?: string;

  /**  */
  userType?: UserType;

  /**  */
  id?: number;
}

export interface UserMiniDto {
  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  surname?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  id?: number;
}

export interface UserShortDto {
  /**  */
  userName?: string;

  /**  */
  realName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isActive?: boolean;

  /**  */
  job?: string;

  /**  */
  avatar?: string;

  /**  */
  userType?: UserType;

  /**  */
  sex?: Sex;

  /**  */
  tags?: string[];

  /**  */
  id?: number;
}

export interface UserTypePostDto {
  /**  */
  userType?: UserType;
}

export interface ValuesItem {
  /** LanguageName */
  languageName?: string;

  /** Value */
  value?: string;
}

export interface ValueSourceInfo {
  /**  */
  source?: string;

  /**  */
  bindValue?: string;

  /**  */
  bindText?: string;
}

export interface Warehouse {
  /**  */
  tenantId?: number;

  /**  */
  name?: string;

  /**  */
  status?: EnableStatus;

  /**  */
  description?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface WarehouseCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 仓库名称 */
  name?: string;

  /**  */
  status?: EnableStatus;

  /** 仓库描述 */
  description?: string;

  /**  */
  id?: number;
}

export interface WarehouseDto {
  /** 租户Id */
  tenantId?: number;

  /** 仓库名称 */
  name?: string;

  /**  */
  status?: EnableStatus;

  /** 仓库描述 */
  description?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface WarehouseDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: WarehouseDto[];
}

export interface WarehouseInStorageDetailCreateOrUpdateDto {
  /** 商品skuId */
  productSkuId?: number;

  /** 入库数量 */
  inStorageQuantity?: number;

  /** 成本价 */
  costPrice?: number;
}

export interface WarehouseInStorageDetailDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkProductSku?: ProductSkuShortDto;

  /** 商品skuId */
  productSkuId?: number;

  /** 入库数量 */
  inStorageQuantity?: number;

  /** 成本价 */
  costPrice?: number;

  /**  */
  id?: number;
}

export interface WarehouseInStorageMainCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 对应采购单号 */
  purchaseCode?: string;

  /**  */
  inStorageType?: InStorageType;

  /** 供应商Id */
  supplierId?: number;

  /** 仓库Id */
  warehouseId?: number;

  /** 仓库库位Id */
  warehouseLocationId?: number;

  /** 入库日期 */
  inStorageTime?: Date;

  /** 入库的经手人Id */
  inStorageHandlerId?: number;

  /** 是否已入库 */
  isWarehoused?: boolean;

  /** 入库单明细 */
  detailList?: WarehouseInStorageDetailCreateOrUpdateDto[];

  /**  */
  savedAttachments?: number[];

  /**  */
  id?: number;
}

export interface WarehouseInStorageMainDto {
  /** 租户Id */
  tenantId?: number;

  /** 入库单号 */
  inStorageCode?: string;

  /** 对应采购单号 */
  purchaseCode?: string;

  /**  */
  inStorageType?: InStorageType;

  /**  */
  fkSupplier?: SupplierDto;

  /** 供应商Id */
  supplierId?: number;

  /**  */
  fkWarehouse?: WarehouseDto;

  /** 仓库Id */
  warehouseId?: number;

  /**  */
  fkWarehouseLocation?: WarehouseLocationDto;

  /** 仓库库位Id */
  warehouseLocationId?: number;

  /** 入库日期 */
  inStorageTime?: Date;

  /**  */
  fkInStorageHandler?: UserShortDto;

  /** 入库的经手人Id */
  inStorageHandlerId?: number;

  /** 是否已入库 */
  isWarehoused?: boolean;

  /** 总数量 */
  totalQuantity?: number;

  /** 总金额 */
  totalAmount?: number;

  /** 入库单明细 */
  detailList?: WarehouseInStorageDetailDto[];

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface WarehouseInStorageMainDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: WarehouseInStorageMainDto[];
}

export interface WarehouseLocationCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 仓库Id */
  warehouseId?: number;

  /** 库位-架 */
  frame?: string;

  /** 库位-层 */
  layer?: string;

  /** 库位-位 */
  position?: string;

  /**  */
  status?: EnableStatus;

  /** 库位描述 */
  description?: string;

  /**  */
  id?: number;
}

export interface WarehouseLocationDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkWarehouse?: WarehouseDto;

  /** 仓库Id */
  warehouseId?: number;

  /** 库位-架 */
  frame?: string;

  /** 库位-层 */
  layer?: string;

  /** 库位-位 */
  position?: string;

  /**  */
  status?: EnableStatus;

  /** 库位描述 */
  description?: string;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface WarehouseLocationDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: WarehouseLocationDto[];
}

export interface WarehouseSelfLiftinPointAdministrativeDivisionDto {
  /** 省 */
  provinces?: ProvinceDto[];

  /** 市 */
  cities?: CityDto[];
}

export interface WarehouseSelfLiftinPointCreateOrUpdateDto {
  /** 租户Id */
  tenantId?: number;

  /** 仓库Id */
  warehouseId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /** 省Id */
  provinceId?: number;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 备注 */
  remark?: string;

  /**  */
  addressType?: AddressType;

  /**  */
  id?: number;
}

export interface WarehouseSelfLiftinPointDto {
  /** 租户Id */
  tenantId?: number;

  /**  */
  fkWarehouse?: WarehouseDto;

  /** 仓库Id */
  warehouseId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /**  */
  fkProvince?: ProvinceDto;

  /** 省Id */
  provinceId?: number;

  /**  */
  fkCity?: CityDto;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 备注 */
  remark?: string;

  /**  */
  addressType?: AddressType;

  /**  */
  isDeleted?: boolean;

  /**  */
  deleterUserId?: number;

  /**  */
  deletionTime?: Date;

  /**  */
  lastModificationTime?: Date;

  /**  */
  lastModifierUserId?: number;

  /**  */
  creationTime?: Date;

  /**  */
  creatorUserId?: number;

  /**  */
  id?: number;
}

export interface WarehouseSelfLiftinPointDtoPagedResultDto {
  /**  */
  totalCount?: number;

  /**  */
  items?: WarehouseSelfLiftinPointDto[];
}

export interface WarehouseSelfLiftinPointShortDto {
  /** 仓库Id */
  warehouseId?: number;

  /** 联系人 */
  contacts?: string;

  /** 联系电话 */
  contactNumber?: string;

  /** 联系邮箱 */
  contactEmailAddress?: string;

  /**  */
  fkProvince?: ProvinceDto;

  /** 省Id */
  provinceId?: number;

  /**  */
  fkCity?: CityDto;

  /** 市Id */
  cityId?: number;

  /** 详细地址 */
  address?: string;

  /** 备注 */
  remark?: string;

  /**  */
  addressType?: AddressType;

  /**  */
  id?: number;
}

export interface WechatAuthenticateModel {
  /**  */
  ticket?: string;
}

export enum AddressType {
  'WarehouseSelfLiftinPoint' = 'WarehouseSelfLiftinPoint'
}

export enum AttachmentHostType {
  'Temp' = 'Temp',
  'CmsContentAttachment' = 'CmsContentAttachment',
  'CmsCategory' = 'CmsCategory',
  'ProductBrand' = 'ProductBrand',
  'Product' = 'Product',
  'ProductSku' = 'ProductSku',
  'Warehousing' = 'Warehousing',
  'Swiper' = 'Swiper'
}

export enum AuditFlowConditionValueType {
  'Enum' = 'Enum',
  'Number' = 'Number',
  'DataSource' = 'DataSource',
  'String' = 'String'
}

export enum AuditFLowStatus {
  'Closed' = 'Closed',
  'Open' = 'Open'
}

export enum AuditFlowType {
  'AuditOne' = 'AuditOne',
  'AuditAll' = 'AuditAll'
}

export enum AuditMethod {
  'AnyOnePassed' = 'AnyOnePassed',
  'AllApproved' = 'AllApproved',
  'AllPassed' = 'AllPassed',
  'AllParticipateAudit' = 'AllParticipateAudit'
}

export enum CmsCategoryType {
  'Content' = 'Content',
  'Article' = 'Article'
}

export enum CustomerType {
  'Ordinary' = 'Ordinary',
  'Member' = 'Member',
  'Enterprise' = 'Enterprise'
}

export enum DataDictionarySyncMode {
  'MinimumEffect' = 'MinimumEffect',
  'Force' = 'Force'
}

export enum DataPermissionLimitHostType {
  'CmsCategory' = 'CmsCategory'
}

export enum DataPermissionLimitTargetType {
  'User' = 'User',
  'Role' = 'Role'
}

export enum DataPermissionScopeType {
  'All' = 'All',
  'Mine' = 'Mine',
  'MyDepartment' = 'MyDepartment',
  'MyDepartmentAndSubs' = 'MyDepartmentAndSubs',
  'Specified' = 'Specified'
}

export enum EnableStatus {
  'Disable' = 'Disable',
  'Enable' = 'Enable'
}

export enum FeedbackSourceType {
  'Reception' = 'Reception',
  'Backstage' = 'Backstage'
}

export enum ImportResultType {
  'Inserted' = 'Inserted',
  'Updated' = 'Updated',
  'NotFound' = 'NotFound',
  'ValidationError' = 'ValidationError',
  'Error' = 'Error',
  'DataConvertFailed' = 'DataConvertFailed',
  'Exist' = 'Exist'
}

export enum InStorageType {
  'PurchaseWarehousing' = 'PurchaseWarehousing'
}

export enum InventoryDeductionMethod {
  'CreateOrder' = 'CreateOrder',
  'Pay' = 'Pay'
}

export enum InvoiceType {
  'Personal' = 'Personal',
  'Enterprise' = 'Enterprise'
}

export enum OrderPayMethod {
  'CreditCard' = 'CreditCard',
  'AccountQuota' = 'AccountQuota',
  'ChequePayment' = 'ChequePayment'
}

export enum OrderReceivingMethod {
  'LogisticsDelivery' = 'LogisticsDelivery',
  'DoorToDoorSelfDelivery' = 'DoorToDoorSelfDelivery'
}

export enum OrderSourceType {
  'OP' = 'OP',
  'PC' = 'PC',
  'H5' = 'H5'
}

export enum OrderStatus {
  'ToBePaid' = 'ToBePaid',
  'Paid' = 'Paid',
  'Cancelled' = 'Cancelled',
  'Issued' = 'Issued',
  'PaidAndIssued' = 'PaidAndIssued',
  'Received' = 'Received',
  'PaidAndReceived' = 'PaidAndReceived'
}

export enum OrderStatusTab {
  'All' = 'All',
  'UnPaid' = 'UnPaid',
  'Pending' = 'Pending',
  'Complete' = 'Complete'
}

export enum OutStorageType {
  'Order' = 'Order'
}

export enum ProductMappingType {
  'Recommend' = 'Recommend',
  'Needed' = 'Needed'
}

export enum ProductParamInputType {
  'ManualInput' = 'ManualInput',
  'DropDownOptions' = 'DropDownOptions'
}

export enum ProductSkuStatus {
  'NotOnShelf' = 'NotOnShelf',
  'OnShelf' = 'OnShelf',
  'RemovedFromShelf' = 'RemovedFromShelf'
}

export enum Sex {
  'Men' = 'Men',
  'Women' = 'Women'
}

export enum TenantAvailabilityState {
  'Available' = 'Available',
  'InActive' = 'InActive',
  'NotFound' = 'NotFound'
}

export enum UserRegisterType {
  'OP' = 'OP',
  'FrontendUser' = 'FrontendUser'
}

export enum UserType {
  'OP' = 'OP'
}

export enum VerificationCode {
  'RegisterVercificationCodeSMS' = 'RegisterVercificationCodeSMS',
  'RetrivePasswordVercificationCodeSMS' = 'RetrivePasswordVercificationCodeSMS',
  'ChangePhoneVercificationCodeSMS' = 'ChangePhoneVercificationCodeSMS',
  'RegisterVercificationCodeEmail' = 'RegisterVercificationCodeEmail',
  'RetrivePasswordVercificationCodeEmail' = 'RetrivePasswordVercificationCodeEmail',
  'ChangeEmailVercificationCodeEmail' = 'ChangeEmailVercificationCodeEmail'
}
