export default {
  basic: {
    detail: "查看",
    select: "请选择",
    input: "请输入",
    enable: "启用",
    disable: "禁用",
    create: "新建",
    edit: "编辑",
    delete: "删除",
    setAccountAmount: "设置帐额",
    confirm: "确定",
    cancel: "取消",
    return: "返回",
    updateSuccess: "更新成功！",
    createSuccess: "创建成功！",
    deleteSuccess: "删除成功！",
    enableSuccess: "启用成功！",
    disableSuccess: "禁用成功！",
    tip: "提示",
    disableTip: "您确定禁用吗？",
    deleteTip: "您确定删除吗？",
    export: "导出",
    index: "序号",
    operation: "操作",
    search: "查询",
    resetSearch: "重置刷新",
    yuan: "元",
    updateCompleted: "更新成功",
  },
  customer: {
    index: "序号",
    customerCode: "客户编号",
    realName: "客户姓名",
    sex: "性别",
    mobilePhone: "手机号",
    emailAddress: "邮箱",
    membershipTme: "加入会员时间",
    memberValidity: "有效期",
    status: "状态",
    effective: "有效的",
    wasDue: "已过期",
    enable: "启用",
    disable: "禁用",
    operation: "操作",
    basicInfo: "基础信息",
    orderInvoiceInfo: "发票信息",
    headerName: "发票抬头",
    address: "地址",
    contactNumber: "联系人电话",
    addressInfo: "地址信息",
    contacts: "联系人全名",
    details: "详情",
    creationTime: "注册时间",
    enterpriseName: "公司名（注册）",
    enterpriseCall: "公司称呼",
    accountQuota: "平台帐额",
    isActive: "状态",
    enterpriseAccountQuota: "授权平台帐额",
    setAccountQuota: "设置平台帐额",
    men: "男",
    women: "女",
    surname: "联系人姓",
    name: "联系人名",
    contactEmailAddress: "联系人邮箱",
    postalCode: "邮政编码",
    province: "省",
    city: "市",
    invoiceHeader: "发票抬头",
    contactInfo: "联系人信息",
    invoiceAddress: "账单地址",
    receiveAddress: "送货地址",
    category: "所在行业",
    addReceiveAddress: "添加送货地址",
    addInvoiceAddress: "添加账单地址",
    defaultAddress: "默认地址",
    default: "默认",
    tips: {
      enterpriseName: "请输入公司名称（注册）",
      enterpriseCall: "请输入公司称呼",
      realName: "请输入联系人",
      sex: "请选择联系人性别",
      mobilePhone: "请输入联系人手机",
      email: "请输入联系人邮箱",
      emailFormat: "请填写正确的邮箱地址",
      enterpriseAccountQuota: "请输入授权平台帐额",
      categoryArray: "请选择所在行业",
      surname: "请输入联系人姓",
      name: "请输入联系人名",
    },
  },
  financeSaleStatistic: {
    index: "序号",
    orderCode: "商品订单编号",
    customerType: "客户类型",
    enterprise: "企业客户",
    member: "会员客户",
    ordinary: "普通客户",
    totalAmount: "订单金额",
    status: "订单状态",
    toBePaid: "待支付",
    paid: "已支付、待出库",
    issued: "已出库、待收货",
    received: "已收货（已完成）",
    cancelled: "已取消",
    creationTime: "下单时间",
    paidAmount: "已付金额",
    arrearsAmount: "欠款金额",
    repaymentDeadline: "还款截至时间",
    operation: "操作",
    salesStatistics: "销售统计表",
  },
  wareHouseSearch: {
    number: "商品编号",
    name: "商品名称",
    Category: "商品类型",
    totalStock: "商品库存",
    availableStock: "上架数量",
    earlyWarningStock: "库存预警",
    unitId: "计量单位",
    warningSet: "预警设置",
    currentEarlyWarningStock: "当前库存预警",
    quantity: "修改库存预警",
    tips: {
      quantity: "请输入库存预警",
    },
  },
  wareHouseLocation: {
    name: "仓库名称",
    description: "仓库简介",
    status: "状态",
    creationTime: "创建时间",
    create: "新增仓库",
    locationManagement: "库位管理",
    selfLiftinPointsManagement: "自提点管理",
    locationCreate: "新增库位",
    locationName: "库位名称",
    locationDescription: "库位简介",
    locationStatus: "库位状态",
    frame: "库位 - 架",
    layer: "库位 - 层",
    position: "库位 - 位",
    details: "详情",
    contacts: "联系人",
    contactNumber: "联系电话",
    contactEmailAddress: "联系邮箱",
    address: "自提点地址",
    addressType: "地址类型",
    createSelfLiftingPoint: "新增自提点",
    warehouseSelfLiftinPoint: "仓库自提点",
    province: "省",
    city: "市",
    remark: "备案说明",
    tips: {
      name: "请输入仓库名称",
      description: "请输入备注说明",
      status: "请选择状态",
      frame: "请输入库位-架",
      layer: "请输入库位-层",
      position: "请输入库位-位",
      contacts: "请输入联系人",
      contactNumber: "请输入联系电话",
      contactEmailAddress: "请输入联系邮箱",
      address: "请输入详细地址",
      remark: "请输入备注说明",
      province: "请选择省",
      city: "请选择市",
    },
  },
  warehouseInStorageMain: {
    productSkuNumber: "商品编号",
    inStorageCode: "商品入库编号",
    purchaseCode: "对应采购单据",
    supplier: "供应商",
    totalQuantity: "商品数量",
    inStorageType: "入库类型",
    isWarehoused: "入库状态",
    warehoused: "已入库",
    notWarehoused: "未入库",
    warehouse: "所在仓库/库位",
    inStorageTime: "入库时间",
    inStorageHandler: "入库操作人",
    createWareHousing: "新增入库单",
    basicInfo: "基础信息",
    totalAmount: "总金额",
    supplierName: "供应商",
    currentWarehouse: "所在仓库",
    currentLocation: "所在库位",
    WarehousingInfo: "入库信息",
    frame: "架",
    layer: "层",
    position: "位",
    warehouseSelfLiftinPoint: "仓库自提点",
    selectProduct: "选择商品",
    productName: "商品名称",
    productSpecParamMappingStr: "商品规格",
    productNumber: "商品编号",
    productBrand: "商品品牌",
    productCategory: "商品类型",
    productUnit: "计量单位",
    productInStorageQuantity: "入库数量",
    productCostPrice: "成本价",
    productIsWarehoused: "入库状态",
    warehousingOrder: "入库单",
    attachmentUpload: "附件上传",
    details: "详情",
    detailList: "商品详情列表",
    totalStock: "商品库存",
    availableStock: "上架库存",
    earlyWarningStock: "预警库存",
    minPurchaseQuantity: "最小起购量",
    salesVolume: "销量",
    salePrice: "销售价格",
    memberPrice: "会员价格",
    attachments: "附件",
    tips: {
      productSkuNumber: "请输入商品编号",
      inStorageCode: "请输入商品入库编号",
      supplier: "请选择供应商",
      purchaseCode: "请输入对应采购单号",
      currentWarehouse: "请选择仓库",
      currentLocation: "请选择库位",
      inStorageTime: "请选择入库时间",
      inStorageType: "请选择入库类型",
      productInStorageQuantity: "请输入入库数量",
      productCostPrice: "请输入成本价",
      inStorageHandler: "请选择入库操作人",
      selectProduct: "请选择商品",
      province: "请选择省",
      city: "请选择市",
      selectIOneData: "请选择至少一条数据",
    },
  },
  warehouseExManagement: {
    productNumber: "商品编号",
    orderCode: "订单编号",
    userName: "买家账号",
    outStorageCode: "出库编号",
    productSkuTotalPrice: "商品总额",
    outStorageType: "出库类型",
    status: "出库状态",
    toBeDelivered: "待出库",
    issued: "已出库",
    outStorageTime: "出库时间",
    fkOutStorageHandler: "经手人",
  },
  invoice: {
    order: "订单",
    invoice: "发货单",
    bill: "发票",
    downLoad: "下载",
    orderInformation: "订单信息",
    creationTime: "下单时间",
    receivingMethod: "收货方式",
    productName: "商品",
    specParamMappingStr: "商品规格",
    quantity: "数量",
    productSkuTotalQuantity: "商品总数量",
    receivingInformation: "收货信息",
    warehouseSelfLiftingPointInformation: "自提点信息",
    receivingAddress: "收货地址",
    warehouseSelfLiftingPoint: "自提点",
  },
  memberPaymentOrder: {
    customerCode: "会员编号",
    isPaid: "状态",
    userName: "会员姓名",
    mobilePhone: "电话",
    emailAddress: "邮箱",
    membershipFeeAmount: "会费金额",
    orderCode: "订单号",
    orderStatus: "订单状态",
    paid: "已支付",
    unPaid: "未支付",
    payMethod: "支付方式",
    payTime: "支付时间",
    creationTime: "创建时间",
  },
  salesManagement: {
    name: "请输入商品名称",
    parentIdPath: "请选择商品类型",
    brandId: "请选择商品品牌",
    isRecommend: "请选择是否推荐",
    yes: "是",
    no: "否",
    minAvailableStock: "上架最小量",
    maxAvailableStock: "上架最大量",
    batchRemovedFromShelf: "批量下架",
    batchSetAvailableStock: "批量上架",
    orderOf: "代下订单",
    selectAll: "全选",
    productName: "商品名称",
    specParamMappingStr: "规格",
    number: "商品编号",
    parentNamePath: "商品类型",
    factoryPrice: "工厂价",
    salePrice: "售价",
    memberPrice: "会员价",
    availableStock: "上架数量",
    salesVolume: "已售",
    status: "任务状态",
    notOnShelf: "未上架",
    removedFromShelf: "已下架",
    onShelf: "已上架",
    detail: "详情",
    setAvailableStock: "上架",
    takeDown: "下架",
    priceAdjustmentManagement: "调价管理",
    removedSuccessfully: "下架成功",
    atLeastOnePieceOfData: "请选择至少一条数据",
    atLeastOneDelistedOrUnlistedData: "请选择至少一条下架或未上架数据",
    bulkOffShelves: "批量下架成功",
    atLeastOneListedOrUnlistedData: "请选择至少一条上架或未上架数据",
    serialNumber: "编号",
    specifications: "规格型号",
    commodityStocks: "商品库存",
    numberOfShelves: "上架数量",
    quantity: "请输入上架数量",
    productInformation: "商品信息",
    minPurchaseQuantity: "最小起购量",
    goodsOnShelf: "商品上架",
    productDetails: "商品详情",
    createDate: "创建日期",
    customerType: "客户类型：",
    enterpriseUsers: "企业用户",
    emailAddress: "邮箱",
    customerCode: "系统编码",
    onSave: "信息匹配成功",
    mailbox: "请输入邮箱",
    systemCode: "请输入系统编码",
  },
  pricingManagement: {
    pricingManagement: "调价管理",
    customerCode: "请输入会员编号",
    realName: "请输入会员名称",
    logList: "调价日志",
    code: "会员编号",
    customerRealName: "会员名称",
    revisedFactoryPrice: "当前价格",
    remark: "备注",
    creationTime: "调整时间",
    operatorRealName: "操作人",
    modifyPrice: "修改价格",
    editPrice: "编辑价格",
    revisedPrice: "修改价：",
    factoryPrice: "工厂价：",
    remarks: "备注：",
    createSuccess: "创建成功",
    modifiedPrice: "请输入修改价",
    number: "请输入商品编号",
    userName: "请输入用户账号",
    userCode: "请输入用户编码",
    originalFactoryPrice: "销售价格",
    userRealName: "用户账号",
    userCustomerCode: "用户编码",
  },
  startOrderOf: {
    orderDetails: "下单详情",
    buyerInformation: "买家信息",
    userName: "用户账号",
    customerCode: "用户编号",
    receivingInformation: "收货信息",
    receivingMethod: "收货方式：",
    receivingAddressMappingId: "收货地址：",
    selectReceivingAddress: "切换地址",
    warehouseSelfLiftingPointId: "自提点：",
    selectWarehouseSelfLiftingPointId: "请选择自提点",
    billAddressMappingId: "发票地址：",
    switchShippingAddress: "切换收货地址",
    contacts: "联系人姓名",
    contactNumber: "联系人电话",
    provinceId: "联系人地址",
    contactEmailAddress: "联系人邮箱",
    postalCode: "邮政编码",
    switchBillAddress: "切换发票地址",
    invoice: "发票抬头",
    orderInformation: "订单信息",
    selectCommodity: "选择商品",
    productName: "商品名称",
    specParamMappingStr: "商品规格",
    number: "商品编号",
    parentNamePath: "商品类型",
    factoryPrice: "工厂价",
    availableStock: "上架数量",
    unitId: "计量单位",
    minPurchaseQuantity: "最小起购量",
    orderQuantity: "下单数量",
    totalPrice: "总价",
    productCount: "商品数量：",
    allTotal: "总价格：",
    save: "确认订单",
    enterOrderQuantity: "请输入下单数量",
    orderFailed: "下单失败",
    success: "下单成功",
    selectReceivingMethod: "请选择收货方式",
    selectReceivingAddressMappingId: "请选择收货地址",
    selectBillAddressMappingId: "请选择发票地址",
    salePrice: "售价",
    productNumber: "请输入商品编号",
    name: "请输入商品标题",
    parentIdPath: "请选择商品类型",
    brandId: "请选择商品品牌",
    atLeastOnePieceOfData: "请选择至少一条数据",
    batchSelection: "确认批量选择吗？",
  },
  orderManagement: {
    orderCode: "请输入订单编号",
    userName: "请输入用户账号",
    receivingMethod: "请选择收货方式",
    status: "请选择订单状态",
    orderTime: "下单时间",
    beginTime: "请选择时间范围起始",
    endTime: "请选择时间范围结束",
    code: "订单编号",
    productSkuTotalPrice: "商品总额",
    totalAmount: "订单总额",
    outStorageTime: "下单时间",
    fkCustomer: "下单用户",
    receiptMethod: "收货方式",
    payMethod: "支付方式",
    orderStatus: "订单状态",
    closeTransaction: "关闭交易",
    orderDelivery: "立即发货",
    completeDelivery: "完成送货",
    cancelReason: "原因：",
    reason: "请填写原因",
    orderShipped: "订单发货",
    logisticsCompany: "物流公司：",
    logisticsOrderNo: "物流单号：",
    enterLogisticsOrderNo: "请输入物流单号",
    expectedDeliveryDate: "预计送达日期：",
    selectExpectedDeliveryDate: "请选择预计送达日期",
    notice: "注意：当前订单的收货方式为“自提”，请确认当前商品在自提点是否已经完成备货，点击确认后系统会自动发动邮件提醒用户前来取货。",
    orderClosed: "订单已关闭",
    orderHasBeenShipped: "订单已发货",
    deliveryCompleted: "你确定送货完成了吗？",
    successfulOperation: "操作成功！",
  },
  orderDetail: {
    details: "详情",
    step1: "1.买家下单",
    step2: "2.买家付款",
    step3: "3.发货",
    step4: "4.确认收货",
    step: "2.取消交易",
    payTime: "付款时间",
    outStorageTime: "发货时间",
    receivedTime: "成交时间",
    cancelTime: "操作时间",
    orderStatus: "当前订单状态：",
    status1: "商品已拍下，等待买家付款（如果商品被恶意拍下，您可以后台取消订单哟~）",
    status2: "商品已拍下并付款，等待卖家发货",
    status3: "取消订单，交易关闭",
    status4: "卖家已发货，等待买家收货",
    status5: "买家确认收货，交易成功",
    sealTheDeal: "完成交易",
    cancelReason: "取消原因：",
    buyerInformation: "买家信息",
    realName: "用户账号：",
    customerCode: "用户编号：",
    payMethod: "支付方式：",
    address: "收货地址：",
    fkWarehouseSelfLiftingPoint: "自提点：",
    orderInformation: "订单信息",
    orderCode: "订单编号：",
    creationTime: "下单时间：",
    receivingMethod: "收货方式：",
    name: "商品",
    specParamMappingStr: "商品规格",
    quantity: "单价（元）*数量",
    amountDetails: "金额明细",
    productSkuTotalQuantity: "商品总数量：",
    productSkuTotalPrice: "商品总价：",
    tranExpenses: "商品运费：",
    taxesGST: "税金（GST）：",
    taxesQST: "税金（QST）：",
    totalAmount: "合计：",
    invoiceInformation: "发票信息",
    invoice: "发票抬头",
    invoiceAmount: "发票金额",
    payeeInformation: "收票人信息",
  },
  commodityClassification: {
    name: "分类名称：",
    id: "分类ID",
    level: "分类级别",
    description: "分类描述：",
    displayOrder: "排序：",
    childrenCreate: "添加子级类目",
    create: "添加分类",
    classification: "所属分类：",
    addClassification: "添加分类",
    editClassification: "编辑分类",
    tips: {
      name: "请输入分类名称",
      classification: "请选择所属分类",
    },
    createSuccessfully: "分类创建成功",
  },
  commoditySpecifications: {
    name: "规格名称",
    inputType: "参数值类型",
    value: "规格值",
    description: "备注",
    creationTime: "创建时间",
    displayOrder: "排序",
    addSpecificationParams: "添加规格参数",
    editSpecificationParams: "编辑规格参数",
    addSpecification: "添加规格",
    tips: {
      name: "请输入规格名称",
      inputType: "请选择参数值类型",
    },
  },
  commodityBasicParams: {
    name: "属性名称",
    inputType: "类型",
    inputList: "属性值",
    description: "备注",
    creationTime: "创建时间",
    addBasicParams: "添加基础参数",
    editBasicParams: "编辑基础参数",
    tips: {
      name: "请输入属性名称",
      inputType: "请选择属性类型",
    },
  },
  commodityBrand: {
    name: "品牌名称：",
    imagePath: "品牌图片：",
    displayOrder: "排序：",
    description: "备注：",
    creationTime: "创建时间",
    addBrand: "添加品牌",
    editBrand: "编辑品牌",
    uploadPictures: "上传图片",
    tips: {
      name: "请输入品牌名称",
      type: "上传图片只能是 png，jpeg，jpg，gif 格式！",
      size: "上传图片大小不能超过 1MB！",
    },
    tip: "上传图片的最佳尺寸：300像素*300像素，其他尺寸会影响效果，格式png，jpeg，jpg，gif，大小不超过1M。",
    primaryClassification: "一级分类",
    createSuccessfully: "品牌创建成功",
  },
  commoditySalesRecord: {
    supplierName: "供应商",
    salesTime: "销售月份",
    totalSalesPrice: "销售总价",
    totalCostPrice: "成本总价",
    actualSettlementAmount: "实际结算金额",
    isSettled: "状态",
    settled: "已结算",
    toBeSettled: "待结算",
    settlement: "结算",
    salesDetails: "销售明细",
    tips: {
      actualSettlementAmount: "请输入实际结算金额",
      success: "结算成功",
    },
  },
  cmsCategory: {
    categoryCode: "栏目编码",
    displayName: "栏目名称",
    categoryType: "栏目类型",
    contentCategory: "内容栏目",
    articleCategory: "文章栏目",
    createMainCategory: "新建一级栏目",
    createSubCategory: "新建子栏目",
    contentAlias: "副标题",
    displayOrder: "显示顺序",
    type: "栏目类型",
    shortDesc: "栏目简介",
    redirectUrl: "PC端路径",
    redirectUrlForWeixinMiniProgram: "小程序端路径",
    titleImagePath: "PC端图片",
    mobileTitleImagePath: "小程序图片",
    unconventionalImagePath: "破格图片",
    description: "描述",
    basicInfo: "基础信息",
    pathConfig: "路径配置",
    pictureConfig: "图片配置",
    editCategory: "编辑栏目",
    createCategory: "新增栏目",
    content: "内容",
    article: "文章",
    tips: {
      displayName: "请输入栏目名称",
      categoryCode: "请输入栏目编码",
    },
  },
  cmsAnchor: {
    displayName: "锚点名称",
    name: "编号",
    width: "宽度",
    height: "高度",
    tips: {
      name: "请输入编号",
      displayName: "请输入锚点名称",
      width: "宽度必须为数字",
      widthRange: "范围在0-10000",
      height: "高度必须为数字",
      heightRange: "范围在0-10000",
    },
  },
  cmsSwiper: {
    displayName: "轮播图名称",
    anchor: "所属锚点",
    titleImageUrl: "图片",
    redirectUrl: "跳转地址",
    displayOrder: "显示顺序",
    remark: "备注",
    tips: {
      displayName: "请输入广告名称",
      titleImageUrl: "广告图片未上传",
      cmsAnchorId: "请选择锚点",
      displayOrder: "请输入显示顺序",
      displayOrderType: "显示顺序必须为数字",
    },
  },
  cmsNavigation: {
    level: "导肮级别",
    displayName: "导肮名称",
    description: "描述",
    displayOrder: "排序",
    creationTime: "创建时间",
    isShow: "是否显示",
    titleImagePath: "导航图片",
    yes: "是",
    no: "否",
    createMainNavigation: "创建主导航",
    createSubNavigation: "创建子导航",
    editSubNavigation: "编辑子导航",
    editNavigation: "编辑导航",
    createNavigation: "创建导航",
    selectProduct: "选择商品",
    productName: "商品名称",
    productSpecParamMappingStr: "商品规格",
    productNumber: "商品编号",
    productAvailableStock: "上架数量",
    productFactoryPrice: "工厂价",
    productUnit: "计量单位",
    categoryIds: "关联商品分类",
    tips: {
      displayName: "请输入导肮名称",
      categoryIds: "请选择商品分类",
    },
  },
  commodity: {
    allProductTypes: "全部商品类型",
    commodityManagement: "商品管理",
    name: "商品名称/品牌",
    addProduct: "添加商品",
    productName: "商品信息",
    brandName: "商品品牌",
    parentNamePath: "商品类型",
    totalStock: "商品库存",
    editCommodity: "商品编辑",
    part1: "1.选择商品分类",
    categoryId: "商品分类",
    selectCategoryId: "请选择商品分类",
    addCategory: "新增分类",
    brandId: "请选择商品品牌",
    addBrand: "新增品牌",
    part2: "2.商品基本信息",
    commodityName: "商品名称",
    enterCommodityName: "请输入商品名称",
    productAttributes: "商品属性",
    basicParamIds: "选择属性：",
    selectAll: "全部",
    productPicture: "商品图片",
    size: "上传图片的最佳尺寸：720像素*720像素，其他尺寸会影响页效果，格式png，jpeg，jpg，gif。大小不超过2M，默认第一张为主图封面",
    mainImage: "商品主图",
    setAsMasterGraph: "设为主图",
    deleteImage: "删除图片",
    description: "商品描述",
    instructionsPath: "说明书",
    instructionsTip:"上传的附件格式支持PDF、WORD、PPT，大小不超过 5 M最佳（仅限上传一个文件）",
    instructionsText: "上传附件",
    installationVideoPath: "安装视频",
    installationVideoTip: "大小不超过200M最佳",
    installationVideoText: "上传视频",
    viewAttachments: "查看附件",
    part3: "3.其他信息",
    warehouseSelfLiftingPointId: "自提点",
    submit: "提交",
    pictureSize: "上传图片大小不能超过 2MB！",
    skuList: "请选择商品规格",
    enterDescription: "请填写商品描述",
    savedAttachments: "请上传商品图片",
    specParamMappingStr: "商品规格",
    selectSpecification: "选择规格：",
    add: "添加",
    id: "编号",
    unitId: "计量单位",
  },
  languageTexts: {
    addKey: "新增",
    enterKey: "请输入键",
    enterValue: "请输入内容",
  }
};
