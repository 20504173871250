const permissions = {
  Pages_AlbumPhotos: "Pages_AlbumPhotos", // 相册管理

  Pages_anchor: "Pages_anchor", // 锚点管理
  Pages_anchor_Create: "Pages_anchor_Create", // 锚点管理-创建
  Pages_anchor_Delete: "Pages_anchor_Delete", // 锚点管理-删除
  Pages_anchor_Edit: "Pages_anchor_Edit", // 锚点管理-修改

  Pages_CMS: "Pages_CMS", // 内容管理

  Pages_CmsCategory: "Pages_CmsCategory", // 栏目管理
  Pages_CmsCategory_Add: "Pages_CmsCategory_Add", // 栏目管理-添加栏目
  Pages_CmsCategory_Delete: "Pages_CmsCategory_Delete", // 栏目管理-删除栏目
  Pages_CmsCategory_Update: "Pages_CmsCategory_Update", // 栏目管理-更新栏目

  Pages_CmsContent: "Pages_CmsContent", // 文章管理
  Pages_CmsContent_Create: "Pages_CmsContent_Create", // 文章管理-创建
  Pages_CmsContent_Delete: "Pages_CmsContent_Delete", // 文章管理-删除
  Pages_CmsContent_Edit: "Pages_CmsContent_Edit", // 文章管理-修改

  Pages_CmsContentTrash: "Pages_CmsContentTrash", // 内容废纸篓
  Pages_CmsContentTrash_Delete: "Pages_CmsContentTrash_Delete", // 内容废纸篓-彻底删除
  Pages_CmsContentTrash_Restore: "Pages_CmsContentTrash_Restore", // 内容废纸篓-恢复

  Pages_Friend: "Pages_Friend", // 合作方管理

  Pages_Notify: "Pages_Notify", // 通知模板管理

  Pages_OrganizationUsers: "Pages_OrganizationUsers", // 合作方用户管理
  Pages_OrganizationUsers_Create: "Pages_OrganizationUsers_Create", // 合作方用户管理-创建
  Pages_OrganizationUsers_Delete: "Pages_OrganizationUsers_Delete", // 合作方用户管理-删除
  Pages_OrganizationUsers_Edit: "Pages_OrganizationUsers_Edit", // 合作方用户管理-修改
  Pages_OrganizationUsers_ResetPassword:
    "Pages_OrganizationUsers_ResetPassword", // 合作方用户管理-重置密码

  Pages_Permission: "Pages_Permission", // 权限管理

  Pages_Swiper: "Pages_Swiper", // 轮播图管理
  Pages_Swiper_Create: "Pages_Swiper_Create", // 轮播图管理-创建
  Pages_Swiper_Delete: "Pages_Swiper_Delete", // 轮播图管理-删除
  Pages_Swiper_Edit: "Pages_Swiper_Edit", // 轮播图管理-修改

  Pages_TenantSettings_AliyunOss: "Pages_TenantSettings.AliyunOss", // AliyunOss

  Pages_TenantSettings_Sms: "Pages_TenantSettings.Sms", // 短信设置

  Pages_AuditFlow: "Pages.AuditFlow", // 审核流程定义
  Pages_AuditFlow_Create: "Pages.AuditFlow:Create", // 审核流程定义-创建
  Pages_AuditFlow_Edit: "Pages.AuditFlow:Edit", // 审核流程定义-修改
  Pages_AuditFlow_ResetPassword: "Pages.AuditFlow:ResetPassword", // 审核流程定义-重置密码

  Pages_AuditNodePreDefinition: "Pages.AuditNodePreDefinition", // 审核节点预定义
  Pages_AuditNodePreDefinition_Create: "Pages.AuditNodePreDefinition:Create", // 审核节点预定义-创建
  Pages_AuditNodePreDefinition_Edit: "Pages.AuditNodePreDefinition:Edit", // 审核节点预定义-修改
  Pages_AuditNodePreDefinition_ResetPassword:
    "Pages.AuditNodePreDefinition:ResetPassword", // 审核节点预定义-重置密码

  Pages_BankReconciliation: "Pages.BankReconciliation", // 银行对账
  Pages_BankReconciliation_Export: "Pages.BankReconciliation:Export", // 银行对账-导出

  Pages_Dashboard: "Pages.Dashboard", // Dashboard
  "Pages_Dashboard_Pages.Dashboard.ExternalProject":
    "Pages.Dashboard:Pages.Dashboard.ExternalProject", // Dashboard-外部项目
  "Pages_Dashboard_Pages.Dashboard.InternalProject":
    "Pages.Dashboard:Pages.Dashboard.InternalProject", // Dashboard-内部项目

  Pages_DataDictionaries: "Pages.DataDictionaries", // 数据字典
  Pages_DataDictionaries_CreateNode: "Pages.DataDictionaries:CreateNode", // 数据字典-创建字典项
  Pages_DataDictionaries_CreateValue: "Pages.DataDictionaries:CreateValue", // 数据字典-创建字典值
  Pages_DataDictionaries_DeleteNode: "Pages.DataDictionaries:DeleteNode", // 数据字典-删除字典项
  Pages_DataDictionaries_DeleteValue: "Pages.DataDictionaries:DeleteValue", // 数据字典-删除字典值
  Pages_DataDictionaries_EditNode: "Pages.DataDictionaries:EditNode", // 数据字典-修改字典项
  Pages_DataDictionaries_EditValue: "Pages.DataDictionaries:EditValue", // 数据字典-修改字典值

  Pages_Departments: "Pages.Departments", // 部门管理
  Pages_Departments_Create: "Pages.Departments:Create", // 部门管理-创建
  Pages_Departments_Delete: "Pages.Departments:Delete", // 部门管理-删除
  Pages_Departments_Edit: "Pages.Departments:Edit", // 部门管理-修改

  Pages_DonationIncome: "Pages.DonationIncome", // 资金捐赠管理
  Pages_DonationIncome_Audit: "Pages.DonationIncome:Audit", // 资金捐赠管理-审核
  Pages_DonationIncome_Create: "Pages.DonationIncome:Create", // 资金捐赠管理-创建
  Pages_DonationIncome_Delete: "Pages.DonationIncome:Delete", // 资金捐赠管理-删除
  Pages_DonationIncome_Edit: "Pages.DonationIncome:Edit", // 资金捐赠管理-修改
  Pages_DonationIncome_Export: "Pages.DonationIncome:Export", // 资金捐赠管理-导出
  Pages_DonationIncome_Import: "Pages.DonationIncome:Import", // 资金捐赠管理-导入
  Pages_DonationIncome_StartAudit: "Pages.DonationIncome:StartAudit", // 资金捐赠管理-提交/重新审核

  Pages_DueDiligenceRecord: "Pages.DueDiligenceRecord", // 尽职调查报告
  Pages_DueDiligenceRecord_Audit: "Pages.DueDiligenceRecord:Audit", // 尽职调查报告-审核
  Pages_DueDiligenceRecord_Create: "Pages.DueDiligenceRecord:Create", // 尽职调查报告-创建
  Pages_DueDiligenceRecord_Delete: "Pages.DueDiligenceRecord:Delete", // 尽职调查报告-删除
  Pages_DueDiligenceRecord_Edit: "Pages.DueDiligenceRecord:Edit", // 尽职调查报告-修改

  Pages_ExpenseRecord: "Pages.ExpenseRecord", // 支出记录单管理
  Pages_ExpenseRecord_Audit: "Pages.ExpenseRecord:Audit", // 支出记录单管理-审核
  Pages_ExpenseRecord_ChangeApply: "Pages.ExpenseRecord:ChangeApply", // 支出记录单管理-变更申请
  Pages_ExpenseRecord_Create: "Pages.ExpenseRecord:Create", // 支出记录单管理-创建
  Pages_ExpenseRecord_Delete: "Pages.ExpenseRecord:Delete", // 支出记录单管理-删除
  Pages_ExpenseRecord_Edit: "Pages.ExpenseRecord:Edit", // 支出记录单管理-修改
  Pages_ExpenseRecord_ExpendTime: "Pages.ExpenseRecord:ExpendTime", // 支出记录单管理-支出时间
  Pages_ExpenseRecord_Export: "Pages.ExpenseRecord:Export", // 支出记录单管理-导出
  Pages_ExpenseRecord_Import: "Pages.ExpenseRecord:Import", // 支出记录单管理-导入
  Pages_ExpenseRecord_StartAudit: "Pages.ExpenseRecord:StartAudit", // 支出记录单管理-提交/重新审核

  Pages_ExternalProgressReports: "Pages.ExternalProgressReports", // 中期报告管理
  Pages_ExternalProgressReports_Audit: "Pages.ExternalProgressReports:Audit", // 中期报告管理-审核
  Pages_ExternalProgressReports_Create: "Pages.ExternalProgressReports:Create", // 中期报告管理-创建
  Pages_ExternalProgressReports_Delete: "Pages.ExternalProgressReports:Delete", // 中期报告管理-删除
  Pages_ExternalProgressReports_Edit: "Pages.ExternalProgressReports:Edit", // 中期报告管理-修改
  Pages_ExternalProgressReports_FillNotice:
    "Pages.ExternalProgressReports:FillNotice", // 中期报告管理- 发送填写通知
  Pages_ExternalProgressReports_StartAudit:
    "Pages.ExternalProgressReports:StartAudit", // 中期报告管理-提交/重新审核

  Pages_ExternalProject: "Pages.ExternalProject", // 外部项目

  Pages_ExternalProjectCloseReport: "Pages.ExternalProjectCloseReport", // 结项评估报告
  Pages_ExternalProjectCloseReport_Audit:
    "Pages.ExternalProjectCloseReport:Audit", // 结项评估报告-审核
  Pages_ExternalProjectCloseReport_Create:
    "Pages.ExternalProjectCloseReport:Create", // 结项评估报告-创建
  Pages_ExternalProjectCloseReport_Delete:
    "Pages.ExternalProjectCloseReport:Delete", // 结项评估报告-删除
  Pages_ExternalProjectCloseReport_Edit:
    "Pages.ExternalProjectCloseReport:Edit", // 结项评估报告-修改
  Pages_ExternalProjectCloseReport_FillNotice:
    "Pages.ExternalProjectCloseReport:FillNotice", // 结项评估报告- 发送填写通知
  Pages_ExternalProjectCloseReport_StartAudit:
    "Pages.ExternalProjectCloseReport:StartAudit", // 结项评估报告-提交/重新审核

  Pages_ExternalProjectLetterIntent: "Pages.ExternalProjectLetterIntent", // 意向书管理
  Pages_ExternalProjectLetterIntent_Audit:
    "Pages.ExternalProjectLetterIntent:Audit", // 意向书管理-审核
  Pages_ExternalProjectLetterIntent_Delete:
    "Pages.ExternalProjectLetterIntent:Delete", // 意向书管理-删除
  Pages_ExternalProjectLetterIntent_Edit:
    "Pages.ExternalProjectLetterIntent:Edit", // 意向书管理-修改
  Pages_ExternalProjectLetterIntent_StartAudit:
    "Pages.ExternalProjectLetterIntent:StartAudit", // 意向书管理-提交/重新审核

  Pages_ExternalProjectMonthlyPlan: "Pages.ExternalProjectMonthlyPlan", // 项目月计划管理
  Pages_ExternalProjectMonthlyPlan_Audit:
    "Pages.ExternalProjectMonthlyPlan:Audit", // 项目月计划管理-审核
  Pages_ExternalProjectMonthlyPlan_Create:
    "Pages.ExternalProjectMonthlyPlan:Create", // 项目月计划管理-创建
  Pages_ExternalProjectMonthlyPlan_Delete:
    "Pages.ExternalProjectMonthlyPlan:Delete", // 项目月计划管理-删除
  Pages_ExternalProjectMonthlyPlan_Edit:
    "Pages.ExternalProjectMonthlyPlan:Edit", // 项目月计划管理-修改
  Pages_ExternalProjectMonthlyPlan_StartAudit:
    "Pages.ExternalProjectMonthlyPlan:StartAudit", // 项目月计划管理-提交/重新审核

  Pages_ExternalProjectMonthlySummarize:
    "Pages.ExternalProjectMonthlySummarize", // 项目月总结管理
  Pages_ExternalProjectMonthlySummarize_Audit:
    "Pages.ExternalProjectMonthlySummarize:Audit", // 项目月总结管理-审核
  Pages_ExternalProjectMonthlySummarize_Create:
    "Pages.ExternalProjectMonthlySummarize:Create", // 项目月总结管理-创建
  Pages_ExternalProjectMonthlySummarize_Delete:
    "Pages.ExternalProjectMonthlySummarize:Delete", // 项目月总结管理-删除
  Pages_ExternalProjectMonthlySummarize_Edit:
    "Pages.ExternalProjectMonthlySummarize:Edit", // 项目月总结管理-修改
  Pages_ExternalProjectMonthlySummarize_StartAudit:
    "Pages.ExternalProjectMonthlySummarize:StartAudit", // 项目月总结管理-提交/重新审核

  Pages_ExternalProjectPartnerCapitalApply:
    "Pages.ExternalProjectPartnerCapitalApply", // 合作方资金申请
  Pages_ExternalProjectPartnerCapitalApply_Audit:
    "Pages.ExternalProjectPartnerCapitalApply:Audit", // 合作方资金申请-审核
  Pages_ExternalProjectPartnerCapitalApply_Create:
    "Pages.ExternalProjectPartnerCapitalApply:Create", // 合作方资金申请-创建
  Pages_ExternalProjectPartnerCapitalApply_Delete:
    "Pages.ExternalProjectPartnerCapitalApply:Delete", // 合作方资金申请-删除
  Pages_ExternalProjectPartnerCapitalApply_Edit:
    "Pages.ExternalProjectPartnerCapitalApply:Edit", // 合作方资金申请-修改

  Pages_ExternalProjectPhases: "Pages.ExternalProjectPhases", // 项目周期
  Pages_ExternalProjectPhases_Stop: "Pages.ExternalProjectPhases:Stop", // 项目周期-终止

  Pages_ExternalProjectProcessMonitoring:
    "Pages.ExternalProjectProcessMonitoring", // 监测记录管理
  Pages_ExternalProjectProcessMonitoring_Audit:
    "Pages.ExternalProjectProcessMonitoring:Audit", // 监测记录管理-审核
  Pages_ExternalProjectProcessMonitoring_Create:
    "Pages.ExternalProjectProcessMonitoring:Create", // 监测记录管理-创建
  Pages_ExternalProjectProcessMonitoring_Delete:
    "Pages.ExternalProjectProcessMonitoring:Delete", // 监测记录管理-删除
  Pages_ExternalProjectProcessMonitoring_Edit:
    "Pages.ExternalProjectProcessMonitoring:Edit", // 监测记录管理-修改
  Pages_ExternalProjectProcessMonitoring_FillNotice:
    "Pages.ExternalProjectProcessMonitoring:FillNotice", // 监测记录管理-发送填写通知
  Pages_ExternalProjectProcessMonitoring_StartAudit:
    "Pages.ExternalProjectProcessMonitoring:StartAudit", // 监测记录管理-提交/重新审核

  Pages_ExternalProjectProjectInvite: "Pages.ExternalProjectProjectInvite", // 项目邀约
  Pages_ExternalProjectProjectInvite_Create:
    "Pages.ExternalProjectProjectInvite:Create", // 项目邀约-创建
  Pages_ExternalProjectProjectInvite_Delete:
    "Pages.ExternalProjectProjectInvite:Delete", // 项目邀约-删除

  Pages_ExternalProjectProposal: "Pages.ExternalProjectProposal", // 计划书管理
  Pages_ExternalProjectProposal_Audit: "Pages.ExternalProjectProposal:Audit", // 计划书管理-审核
  Pages_ExternalProjectProposal_ChangeManager:
    "Pages.ExternalProjectProposal:ChangeManager", // 计划书管理-项目负责人修改
  Pages_ExternalProjectProposal_ChangeProjectReportSubmitDate:
    "Pages.ExternalProjectProposal:ChangeProjectReportSubmitDate", // 计划书管理-项目报告提交时间设置
  Pages_ExternalProjectProposal_ChangeProjectType:
    "Pages.ExternalProjectProposal:ChangeProjectType", // 计划书管理-修改项目类型
  Pages_ExternalProjectProposal_Delete: "Pages.ExternalProjectProposal:Delete", // 计划书管理-删除
  Pages_ExternalProjectProposal_Edit: "Pages.ExternalProjectProposal:Edit", // 计划书管理-修改
  Pages_ExternalProjectProposal_projectBudgetEdit:
    "Pages.ExternalProjectProposal:projectBudgetEdit", // 计划书管理-维护项目预算表
  Pages_ExternalProjectProposal_ProjectResultEdit:
    "Pages.ExternalProjectProposal:ProjectResultEdit", // 计划书管理-维护项目成果
  Pages_ExternalProjectProposal_PublishControl:
    "Pages.ExternalProjectProposal:PublishControl", // 计划书管理-发布控制
  Pages_ExternalProjectProposal_StartAudit:
    "Pages.ExternalProjectProposal:StartAudit", // 计划书管理-提交/重新审核
  Pages_ExternalProjectProposal_TitleImageUpdate:
    "Pages.ExternalProjectProposal:TitleImageUpdate", // 计划书管理-修改标题图片

  Pages_ExternalProjectProposalChangeApply:
    "Pages.ExternalProjectProposalChangeApply", // 计划书变更管理
  Pages_ExternalProjectProposalChangeApply_Audit:
    "Pages.ExternalProjectProposalChangeApply:Audit", // 计划书变更管理-审核
  Pages_ExternalProjectProposalChangeApply_Create:
    "Pages.ExternalProjectProposalChangeApply:Create", // 计划书变更管理-创建
  Pages_ExternalProjectProposalChangeApply_Delete:
    "Pages.ExternalProjectProposalChangeApply:Delete", // 计划书变更管理-删除
  Pages_ExternalProjectProposalChangeApply_Edit:
    "Pages.ExternalProjectProposalChangeApply:Edit", // 计划书变更管理-修改
  Pages_ExternalProjectProposalChangeApply_StartAudit:
    "Pages.ExternalProjectProposalChangeApply:StartAudit", // 计划书变更管理-提交/重新审核

  Pages_ExternalProjectSummaryReport: "Pages.ExternalProjectSummaryReport", // 自评报告管理
  Pages_ExternalProjectSummaryReport_Audit:
    "Pages.ExternalProjectSummaryReport:Audit", // 自评报告管理-审核
  Pages_ExternalProjectSummaryReport_Create:
    "Pages.ExternalProjectSummaryReport:Create", // 自评报告管理-创建
  Pages_ExternalProjectSummaryReport_Delete:
    "Pages.ExternalProjectSummaryReport:Delete", // 自评报告管理-删除
  Pages_ExternalProjectSummaryReport_Edit:
    "Pages.ExternalProjectSummaryReport:Edit", // 自评报告管理-修改
  Pages_ExternalProjectSummaryReport_FillNotice:
    "Pages.ExternalProjectSummaryReport:FillNotice", // 自评报告管理- 发送填写通知
  Pages_ExternalProjectSummaryReport_StartAudit:
    "Pages.ExternalProjectSummaryReport:StartAudit", // 自评报告管理-提交/重新审核

  Pages_Finance: "Pages.Finance", // 财务管理

  Pages_FinanceManage: "Pages.FinanceManage", // 项目报表

  Pages_FriendLink: "Pages.FriendLink", // 合作伙伴
  Pages_FriendLink_Create: "Pages.FriendLink:Create", // 合作伙伴-创建
  Pages_FriendLink_Delete: "Pages.FriendLink:Delete", // 合作伙伴-删除
  Pages_FriendLink_Edit: "Pages.FriendLink:Edit", // 合作伙伴-修改

  Pages_Fund: "Pages.Fund", // 基金管理

  Pages_FundApply_Audit: "Pages.FundApply:Audit", // 基金申请审核

  Pages_FundMaintain: "Pages.FundMaintain", // 基金管理

  Pages_FundModifyApply: "Pages.FundModifyApply", // 基金会修改审核

  Pages_FundUser: "Pages.FundUser", // 基金成员管理

  Pages_InternalPersonalYearEndSummary: "Pages.InternalPersonalYearEndSummary", // 个人年终总结
  Pages_InternalPersonalYearEndSummary_Audit:
    "Pages.InternalPersonalYearEndSummary:Audit", // 个人年终总结-审核
  Pages_InternalPersonalYearEndSummary_Create:
    "Pages.InternalPersonalYearEndSummary:Create", // 个人年终总结-创建
  Pages_InternalPersonalYearEndSummary_Delete:
    "Pages.InternalPersonalYearEndSummary:Delete", // 个人年终总结-删除
  Pages_InternalPersonalYearEndSummary_Edit:
    "Pages.InternalPersonalYearEndSummary:Edit", // 个人年终总结-修改
  Pages_InternalPersonalYearEndSummary_StartAudit:
    "Pages.InternalPersonalYearEndSummary:StartAudit", // 个人年终总结-提交/重新审核

  Pages_InternalProject: "Pages.InternalProject", // 内部项目

  Pages_InternalProjectBeneficiary: "Pages.InternalProjectBeneficiary", // 受益人管理
  Pages_InternalProjectBeneficiary_Create:
    "Pages.InternalProjectBeneficiary:Create", // 受益人管理-创建
  Pages_InternalProjectBeneficiary_Delete:
    "Pages.InternalProjectBeneficiary:Delete", // 受益人管理-删除
  Pages_InternalProjectBeneficiary_Edit:
    "Pages.InternalProjectBeneficiary:Edit", // 受益人管理-修改

  Pages_InternalProjectBeneficiaryServiceRecord:
    "Pages.InternalProjectBeneficiaryServiceRecord", // 受益人服务记录
  Pages_InternalProjectBeneficiaryServiceRecord_Audit:
    "Pages.InternalProjectBeneficiaryServiceRecord:Audit", // 受益人服务记录-审核
  Pages_InternalProjectBeneficiaryServiceRecord_Create:
    "Pages.InternalProjectBeneficiaryServiceRecord:Create", // 受益人服务记录-创建
  Pages_InternalProjectBeneficiaryServiceRecord_Delete:
    "Pages.InternalProjectBeneficiaryServiceRecord:Delete", // 受益人服务记录-删除
  Pages_InternalProjectBeneficiaryServiceRecord_Edit:
    "Pages.InternalProjectBeneficiaryServiceRecord:Edit", // 受益人服务记录-修改

  Pages_InternalProjectMonthlyPlan: "Pages.InternalProjectMonthlyPlan", // 月度计划管理
  Pages_InternalProjectMonthlyPlan_Audit:
    "Pages.InternalProjectMonthlyPlan:Audit", // 月度计划管理-审核
  Pages_InternalProjectMonthlyPlan_Create:
    "Pages.InternalProjectMonthlyPlan:Create", // 月度计划管理-创建
  Pages_InternalProjectMonthlyPlan_Delete:
    "Pages.InternalProjectMonthlyPlan:Delete", // 月度计划管理-删除
  Pages_InternalProjectMonthlyPlan_Edit:
    "Pages.InternalProjectMonthlyPlan:Edit", // 月度计划管理-修改
  Pages_InternalProjectMonthlyPlan_StartAudit:
    "Pages.InternalProjectMonthlyPlan:StartAudit", // 月度计划管理-提交/重新审核

  Pages_InternalProjectMonthlySummarize:
    "Pages.InternalProjectMonthlySummarize", // 月总结管理
  Pages_InternalProjectMonthlySummarize_Audit:
    "Pages.InternalProjectMonthlySummarize:Audit", // 月总结管理-审核
  Pages_InternalProjectMonthlySummarize_Create:
    "Pages.InternalProjectMonthlySummarize:Create", // 月总结管理-创建
  Pages_InternalProjectMonthlySummarize_Delete:
    "Pages.InternalProjectMonthlySummarize:Delete", // 月总结管理-删除
  Pages_InternalProjectMonthlySummarize_Edit:
    "Pages.InternalProjectMonthlySummarize:Edit", // 月总结管理-修改
  Pages_InternalProjectMonthlySummarize_StartAudit:
    "Pages.InternalProjectMonthlySummarize:StartAudit", // 月总结管理-提交/重新审核

  Pages_InternalProjectPartnerCapitalApply:
    "Pages.InternalProjectPartnerCapitalApply", // 合作方资金申请
  Pages_InternalProjectPartnerCapitalApply_Audit:
    "Pages.InternalProjectPartnerCapitalApply:Audit", // 合作方资金申请-审核
  Pages_InternalProjectPartnerCapitalApply_Create:
    "Pages.InternalProjectPartnerCapitalApply:Create", // 合作方资金申请-创建
  Pages_InternalProjectPartnerCapitalApply_Delete:
    "Pages.InternalProjectPartnerCapitalApply:Delete", // 合作方资金申请-删除
  Pages_InternalProjectPartnerCapitalApply_Edit:
    "Pages.InternalProjectPartnerCapitalApply:Edit", // 合作方资金申请-修改
  Pages_InternalProjectPartnerCapitalApply_StartAudit:
    "Pages.InternalProjectPartnerCapitalApply:StartAudit", // 合作方资金申请-提交/重新审核

  Pages_InternalProjectPhases: "Pages.InternalProjectPhases", // 项目周期
  Pages_InternalProjectPhases_Stop: "Pages.InternalProjectPhases:Stop", // 项目周期-终止

  Pages_InternalProjectProjectInvite: "Pages.InternalProjectProjectInvite", // 项目邀约
  Pages_InternalProjectProjectInvite_Copy:
    "Pages.InternalProjectProjectInvite:Copy", // 项目邀约-复制项目
  Pages_InternalProjectProjectInvite_Create:
    "Pages.InternalProjectProjectInvite:Create", // 项目邀约-创建
  Pages_InternalProjectProjectInvite_CustomFormEdit:
    "Pages.InternalProjectProjectInvite:CustomFormEdit", // 项目邀约-自定义表单
  Pages_InternalProjectProjectInvite_Delete:
    "Pages.InternalProjectProjectInvite:Delete", // 项目邀约-删除
  Pages_InternalProjectProjectInvite_Edit:
    "Pages.InternalProjectProjectInvite:Edit", // 项目邀约-修改

  Pages_InternalProjectProposal: "Pages.InternalProjectProposal", // 项目立项管理
  Pages_InternalProjectProposal_Audit: "Pages.InternalProjectProposal:Audit", // 项目立项管理-审核
  Pages_InternalProjectProposal_BeneficiaryMapping:
    "Pages.InternalProjectProposal:BeneficiaryMapping", // 项目立项管理-受益人管理
  Pages_InternalProjectProposal_ChangeManager:
    "Pages.InternalProjectProposal:ChangeManager", // 项目立项管理-修改项目负责人
  Pages_InternalProjectProposal_ChangeProjectType:
    "Pages.InternalProjectProposal:ChangeProjectType", // 项目立项管理-修改项目类型
  Pages_InternalProjectProposal_Delete: "Pages.InternalProjectProposal:Delete", // 项目立项管理-删除
  Pages_InternalProjectProposal_Edit: "Pages.InternalProjectProposal:Edit", // 项目立项管理-修改
  Pages_InternalProjectProposal_OrganizationUnitMapping:
    "Pages.InternalProjectProposal:OrganizationUnitMapping", // 项目立项管理-合作方管理
  Pages_InternalProjectProposal_ProjectResultEdit:
    "Pages.InternalProjectProposal:ProjectResultEdit", // 项目立项管理-维护项目成果
  Pages_InternalProjectProposal_PublishControl:
    "Pages.InternalProjectProposal:PublishControl", // 项目立项管理-发布控制
  Pages_InternalProjectProposal_StartAudit:
    "Pages.InternalProjectProposal:StartAudit", // 项目立项管理-提交/重新审核
  Pages_InternalProjectProposal_TitleImageUpdate:
    "Pages.InternalProjectProposal:TitleImageUpdate", // 项目立项管理-修改标题图片
  Pages_InternalProjectProposal_UpdateBudgetItems:
    "Pages.InternalProjectProposal:UpdateBudgetItems", // 项目立项管理-维护项目预算

  Pages_InternalProjectSchoolServiceRecord:
    "Pages.InternalProjectSchoolServiceRecord", // 组织服务记录管理
  Pages_InternalProjectSchoolServiceRecord_Audit:
    "Pages.InternalProjectSchoolServiceRecord:Audit", // 组织服务记录管理-审核
  Pages_InternalProjectSchoolServiceRecord_Create:
    "Pages.InternalProjectSchoolServiceRecord:Create", // 组织服务记录管理-创建
  Pages_InternalProjectSchoolServiceRecord_Delete:
    "Pages.InternalProjectSchoolServiceRecord:Delete", // 组织服务记录管理-删除
  Pages_InternalProjectSchoolServiceRecord_Edit:
    "Pages.InternalProjectSchoolServiceRecord:Edit", // 组织服务记录管理-修改

  Pages_InternalProjectSummaryReports: "Pages.InternalProjectSummaryReports", // 总结报告管理
  Pages_InternalProjectSummaryReports_Audit:
    "Pages.InternalProjectSummaryReports:Audit", // 总结报告管理-审核
  Pages_InternalProjectSummaryReports_Create:
    "Pages.InternalProjectSummaryReports:Create", // 总结报告管理-创建
  Pages_InternalProjectSummaryReports_Delete:
    "Pages.InternalProjectSummaryReports:Delete", // 总结报告管理-删除
  Pages_InternalProjectSummaryReports_Edit:
    "Pages.InternalProjectSummaryReports:Edit", // 总结报告管理-修改
  Pages_InternalProjectSummaryReports_FillNotice:
    "Pages.InternalProjectSummaryReports:FillNotice", // 总结报告管理- 发送填写通知
  Pages_InternalProjectSummaryReports_StartAudit:
    "Pages.InternalProjectSummaryReports:StartAudit", // 总结报告管理-提交/重新审核

  Pages_MessageCenter: "Pages.MessageCenter", // 基金消息

  Pages_MoneyOtherIncome: "Pages.MoneyOtherIncome", // 其他资金收入管理
  Pages_MoneyOtherIncome_Audit: "Pages.MoneyOtherIncome:Audit", // 其他资金收入管理-审核
  Pages_MoneyOtherIncome_Create: "Pages.MoneyOtherIncome:Create", // 其他资金收入管理-创建
  Pages_MoneyOtherIncome_Delete: "Pages.MoneyOtherIncome:Delete", // 其他资金收入管理-删除
  Pages_MoneyOtherIncome_Edit: "Pages.MoneyOtherIncome:Edit", // 其他资金收入管理-修改
  Pages_MoneyOtherIncome_Export: "Pages.MoneyOtherIncome:Export", // 其他资金收入管理-导出
  Pages_MoneyOtherIncome_Import: "Pages.MoneyOtherIncome:Import", // 其他资金收入管理-导入
  Pages_MoneyOtherIncome_StartAudit: "Pages.MoneyOtherIncome:StartAudit", // 其他资金收入管理-提交/重新审核

  Pages_MyAudit: "Pages.MyAudit", // 我的审核列表

  Pages_NotifyList: "Pages.NotifyList", // 通知模板
  Pages_NotifyList_Create: "Pages.NotifyList:Create", // 通知模板-创建
  Pages_NotifyList_Delete: "Pages.NotifyList:Delete", // 通知模板-删除
  Pages_NotifyList_Edit: "Pages.NotifyList:Edit", // 通知模板-修改

  Pages_NotifyRecordOrganizationInvestigation:
    "Pages.NotifyRecordOrganizationInvestigation", // 尽职调查通知
  Pages_NotifyRecordOrganizationInvestigation_Create:
    "Pages.NotifyRecordOrganizationInvestigation:Create", // 尽职调查通知-创建
  Pages_NotifyRecordOrganizationInvestigation_Delete:
    "Pages.NotifyRecordOrganizationInvestigation:Delete", // 尽职调查通知-删除

  Pages_NotifyRecordProjectAssessmentPlan:
    "Pages.NotifyRecordProjectAssessmentPlan", // 评估计划通知
  Pages_NotifyRecordProjectAssessmentPlan_Create:
    "Pages.NotifyRecordProjectAssessmentPlan:Create", // 评估计划通知-创建
  Pages_NotifyRecordProjectAssessmentPlan_Delete:
    "Pages.NotifyRecordProjectAssessmentPlan:Delete", // 评估计划通知-删除

  Pages_Organization: "Pages.Organization", // 合作方维护
  Pages_Organization_Create: "Pages.Organization:Create", // 合作方维护-创建
  Pages_Organization_Delete: "Pages.Organization:Delete", // 合作方维护-删除
  Pages_Organization_Edit: "Pages.Organization:Edit", // 合作方维护-修改
  Pages_Organization_Import: "Pages.Organization:Import", // 合作方维护-导入

  Pages_OrganizationApply: "Pages.OrganizationApply", // 合作方审核
  Pages_OrganizationApply_Audit: "Pages.OrganizationApply:Audit", // 合作方审核-审核
  Pages_OrganizationApply_Delete: "Pages.OrganizationApply:Delete", // 合作方审核-删除
  Pages_OrganizationApply_ImproveTheDueDiligenceReport:
    "Pages.OrganizationApply:ImproveTheDueDiligenceReport", // 合作方审核-完善尽职调查报告

  Pages_PartnerCustomForm: "Pages.PartnerCustomForm", // 合作方表单维护

  Pages_PartnerInvite: "Pages.PartnerInvite", // 合作方邀约
  Pages_PartnerInvite_Create: "Pages.PartnerInvite:Create", // 合作方邀约-创建
  Pages_PartnerInvite_Delete: "Pages.PartnerInvite:Delete", // 合作方邀约-删除
  Pages_PartnerInvite_Edit: "Pages.PartnerInvite:Edit", // 合作方邀约-修改
  Pages_PartnerInvite_Import: "Pages.PartnerInvite:Import", // 合作方邀约-导入

  Pages_PermissionDefinition: "Pages.PermissionDefinition", // 权限定义

  Pages_Project: "Pages.Project", // 项目管理

  Pages_ProjectProjectSeries: "Pages.ProjectProjectSeries", // 项目系列管理
  Pages_ProjectProjectSeries_Create: "Pages.ProjectProjectSeries:Create", // 项目系列管理-创建
  Pages_ProjectProjectSeries_Delete: "Pages.ProjectProjectSeries:Delete", // 项目系列管理-删除
  Pages_ProjectProjectSeries_Edit: "Pages.ProjectProjectSeries:Edit", // 项目系列管理-修改

  Pages_Roles: "Pages.Roles", // 角色管理
  Pages_Roles_Create: "Pages.Roles:Create", // 角色管理-创建
  Pages_Roles_Delete: "Pages.Roles:Delete", // 角色管理-删除
  Pages_Roles_Edit: "Pages.Roles:Edit", // 角色管理-修改
  Pages_Roles_GrantPermissions: "Pages.Roles:GrantPermissions", // 角色管理-分配权限

  Pages_System: "Pages.System", // 系统设置

  Pages_Users: "Pages.Users", // 用户管理
  Pages_Users_Create: "Pages.Users:Create", // 用户管理-创建
  Pages_Users_Delete: "Pages.Users:Delete", // 用户管理-删除
  Pages_Users_Edit: "Pages.Users:Edit", // 用户管理-修改
  Pages_Users_ResetPassword: "Pages.Users:ResetPassword", // 用户管理-重置密码
};
export default permissions;
