export function getActualPageIndex() {
  const historyParams = window.sessionStorage.getItem("HISTORY_SEARCH_PARAMS");
  const historyUrlSame = window.sessionStorage.getItem(
    "HISTORY_SEARCH_URL_SAME"
  );
  if (historyParams && Boolean(historyUrlSame)) {
    const historyParameter = JSON.parse(historyParams);
    return historyParameter?.PageIndex ?? 0;
  }
  return -1;
}

export function getActualPageSize() {
  const historyParams = window.sessionStorage.getItem("HISTORY_SEARCH_PARAMS");
  const historyUrlSame = window.sessionStorage.getItem("HISTORY_SEARCH_path");
  console.log(window.location.hash, "this.$route.path");
  if (historyParams && window.location.hash === historyUrlSame) {
    const parameters = JSON.parse(historyParams);
    return parameters?.PageSize ?? 0;
  }
  return -1;
}

export function clearHistorySearchParams() {
  window.sessionStorage.setItem("HISTORY_SEARCH_PARAMS", "");
  window.sessionStorage.setItem("HISTORY_SEARCH_URL", "");
}
