import i18n from "@/lang";
import store from "@/store";
import { LanguageTextDto } from "@/api/appService";

export default {
  getLanguageText(key: string): string {
    const languageText = store.getters["user/languageTextTree"];
    const lan = localStorage.getItem("lang") === "zh" ? "zh-Hans" : "en";
    const languageTextTree = languageText?.filter((item: LanguageTextDto) => item.languageName === lan);
    return (
      languageTextTree?.find((item: LanguageTextDto) => item.key === key)
        ?.value ?? ""
    );
  }
};
