

















import { compile } from "path-to-regexp";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteRecord, Route } from "vue-router";

@Component({
  name: "Breadcrumb",
})
export default class extends Vue {
  private breadcrumbs: RouteRecord[] = [];

  @Watch("$route")
  private onRouteChange(route: Route) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith("/redirect/")) {
      return;
    }
    this.getBreadcrumb();
  }

  created() {
    this.getBreadcrumb();
  }

  private getBreadcrumb() {
    let matched = (this as any).$route.matched.filter(
      (item: RouteRecord) => item.meta && (item.meta as any).title
    );
    const first = matched[0];
    if (!this.isDashboard(first)) {
      const dashboardRoute = {
        path: "/dashboard",
        meta: {
          // title: "Dashboard",
        },
      };

      matched = [dashboardRoute].concat(matched);
    }
    this.breadcrumbs = matched.filter((item: RouteRecord) => {
      return (
        item.meta &&
        (item.meta as any).title &&
        (item.meta as any).breadcrumb !== false
      );
    });
  }

  private isDashboard(route: RouteRecord) {
    const name = route && route.meta && (route.meta as any).title;
    return name === "Dashboard";
  }

  private pathCompile(path: string) {
    // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    const { params } = (this as any).$route;
    const toPath = compile(path);
    return toPath(params);
  }

  private handleLink(item: any) {
    const { redirect, path } = item;
    if (redirect) {
      (this as any).$router.push(redirect);
      return;
    }
    (this as any).$router.push(this.pathCompile(path));
  }
}
