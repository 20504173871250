import { RouteConfig } from "vue-router";
import { ActionContext } from "vuex";
import { PermissionDefinitionDto } from "@/api/appService";
import { createNgTree, INgNode } from "@/utils/tree";

export interface GenerateMenuInput {
  permissions: string[];
  roles: string[];
  permissionDefinitions: PermissionDefinitionDto[];
}

export class MenuItem {
  private _name: string;
  private _displayName: string;
  private _url?: string;
  private _icon?: string;
  private _isFunction: boolean;
  private _children: MenuItem[] = [];
  private _isGranted: boolean;

  constructor(
    permission: PermissionDefinitionDto,
    grantedPermissions: string[]
  ) {
    if (permission && grantedPermissions) {
      const properties = (permission.properties || {}) as any;

      this._name = permission.name ?? "";
      this._displayName = permission.displayName ?? "";
      this._url = properties.url;
      this._icon = properties.icon;
      this._isFunction = properties.function === true;
      this._isGranted = grantedPermissions.some((name) => name === this._name);

      if (permission.children) {
        this._children = permission.children
          .map((item) => new MenuItem(item, grantedPermissions))
          .filter((item) => !item.isFunction && item.isGranted);
      }
    } else {
      this._name = "";
      this._displayName = "";
      this._isFunction = true;
      this._isGranted = false;
    }
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get displayName(): string {
    return this._displayName;
  }

  set displayName(value: string) {
    this._displayName = value;
  }

  get url(): string | undefined {
    return this._url ?? "";
  }

  set url(value: string | undefined) {
    this._url = value;
  }

  get icon(): string | undefined {
    return this._icon;
  }

  set icon(value: string | undefined) {
    this._icon = value;
  }

  get isFunction(): boolean {
    return this._isFunction;
  }

  set isFunction(value: boolean) {
    this._isFunction = value;
  }

  get children(): MenuItem[] {
    return this._children;
  }

  set children(value: MenuItem[]) {
    this._children = value;
  }

  get isGranted(): boolean {
    return this._isGranted;
  }

  set isGranted(value: boolean) {
    this._isGranted = value;
  }
}

export interface PermissionState {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
  menus: MenuItem[];
}

export default {
  namespaced: true,
  state: () =>
    ({
      routes: [],
      dynamicRoutes: [],
      menus: [],
    } as PermissionState),
  mutations: {
    SET_ROUTES(state: PermissionState, routes: RouteConfig[]) {
      state.routes = routes;
    },
    SET_MENUS(state: PermissionState, menus: MenuItem[]) {
      state.menus = menus;
    },
  },
  actions: {
    generateMenu(
      context: ActionContext<PermissionState, any>,
      input: GenerateMenuInput
    ): Promise<void> {
      return new Promise<void>((resolve) => {
        if (!input.permissions || !input.permissions.length) {
          resolve();
          return;
        }

        const accessedMenus = input.permissionDefinitions
          .map((item) => new MenuItem(item, input.permissions))
          .filter((item) => !item.isFunction && item.isGranted);

        context.commit("SET_MENUS", accessedMenus);

        resolve();
      });
    },
  },
  getters: {
    menus(state: PermissionState): MenuItem[] {
      return state.menus;
    },
  },
};
