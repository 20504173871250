import moment from "moment";
import { DataDictionaryDto, SelectListItem } from "@/api/appService";

moment.locale("zh-cn");

const formatDate = (value: any, arg: string | undefined) => {
  if (value) {
    if (arg) {
      if (arg === "fromNow") {
        return moment(String(value)).fromNow();
      }
      return moment(String(value)).format(arg);
    }
    return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "";
  }
};

function formatYearMonthDayDate(value: any, arg: string | undefined): string {
  if (value) {
    if (arg) {
      if (arg === "fromNow") {
        return moment(String(value)).fromNow();
      }
      return moment(String(value)).format(arg);
    }
    return moment(String(value)).format("YYYY-MM-DD");
  } else {
    return "";
  }
}

function formatYearMonth(value: any): string {
  if (value) return moment(value).format("YYYY-MM");
  else return "";
}

function formatYearMonthData(value: any): string {
  if (value) return moment(value).format("YYYY年MM月");
  else return "";
}

function currency(value: any): string {
  if (value) {
    return "$" + parseFloat(value).toFixed(2);
  }
  return value;
}

function formatIncomeType(incomeType?: string): string {
  switch (incomeType) {
    case "Cash":
      return "现金";

    case "Transfer":
      return "转账";
    default:
      return "";
  }
}

function formatCapitalProperty(capitalProperty?: string): string {
  switch (capitalProperty) {
    case "NonFinite":
      return "非限定性";

    case "Finite":
      return "限定性";
    default:
      return "";
  }
}

function formatIncomeCategory(incomeCategory?: string): string {
  switch (incomeCategory) {
    case "Interest":
      return "利息";

    case "Investment":
      return "投资收益";

    case "Other":
      return "其他";
    default:
      return "";
  }
}

function formatCurrencyType(currencyType?: string): string {
  switch (currencyType) {
    case "RMB":
      return "人民币";
    default:
      return "";
  }
}

function formatCurrency(value?: number): string {
  if (value) {
    value = Math.round(Number(value) * Math.pow(10, 2)) / Math.pow(10, 2); //四舍五入
    //不足补位
    return Number(value).toFixed(2);
  } else {
    return "";
  }
}

function getSingleColumnSum(list?: any[]): number {
  let sum = 0;
  list?.forEach((item) => {
    if (item.money) sum += parseFloat(item.money);
  });
  return sum;
}

function formatMoney(value?: any): string {
  if (!value) {
    return value;
  }
  value = Math.round(Number(value) * Math.pow(10, 2)) / Math.pow(10, 2); //四舍五入
  const money = value.toFixed(2).replace(/(\d)(?=(\d{4})+\.)/g, "$1'");
  return money;
}

function formatYesOrNo(value?: boolean): string {
  switch (value) {
    case true:
      return "是";
    case false:
      return "否";
    default:
      return "否";
  }
}

function formatIsSynced(value?: boolean): string {
  switch (value) {
    case true:
      return "已同步";
    case false:
      return "未同步";
    default:
      return "未同步";
  }
}

function formatIsEnabled(value?: boolean): string {
  switch (value) {
    case true:
      return "已启用";
    case false:
      return "已禁用";
    default:
      return "已禁用";
  }
}

function formatEnumValue(value: string, values: SelectListItem[]): string {
  const enumValue = values.find((item) => item.value === value);
  return enumValue?.text ?? "N/A";
}

function formatByDataDictionary(
  value: string | number | undefined | null,
  values: DataDictionaryDto[],
  filterKey = "id"
): string {
  if (!value) {
    return "";
  }
  if (!values || !values.length) {
    return "";
  }
  let dataDictionaryItem: DataDictionaryDto | undefined;
  if (filterKey === "id") {
    const compareValue = value as number;
    dataDictionaryItem = values.find((item) => item.id === compareValue);
  } else {
    const compareValue = value.toString();
    dataDictionaryItem = values.find((item) => item.key === compareValue);
  }

  return dataDictionaryItem?.displayName ?? value.toString();
}

function formatDataDictionaries(
  value: DataDictionaryDto[] | null | undefined
): string {
  if (value) {
    return value.map((item) => item.displayName).join(",");
  } else {
    return "";
  }
}

function formatUpperCase(value: any): string {
  if (value) {
    return value.toUpperCase();
  }
  return "";
}

export default {
  formatDate,
  formatYearMonthDayDate,
  formatYearMonth,
  formatYearMonthData,
  currency,
  formatIncomeType,
  formatCapitalProperty,
  formatCurrencyType,
  formatCurrency,
  formatIncomeCategory,
  getSingleColumnSum,
  formatMoney,
  formatYesOrNo,
  formatIsSynced,
  formatIsEnabled,
  formatEnumValue,
  formatByDataDictionary,
  formatDataDictionaries,
  formatUpperCase,
};
