import {DataDictionaryDto, SelectListItem} from "@/api/appService";
import api from "@/api";
import {ActionContext} from "vuex";

class DataDictionaryFetchState {
  private typeName: string;
  private loading = true;
  private _items: DataDictionaryDto[] = [];
  private _promise?: Promise<DataDictionaryDto[]>;

  constructor(typeName: string) {
    this.typeName = typeName;
  }

  fetch(): Promise<DataDictionaryDto[]> {
    return new Promise<DataDictionaryDto[]>((resolve, reject) => {
      if (!this.loading) {
        resolve(this._items);
      } else {
        api.dataDictionary
          .getDataDictionaryByKey({key: this.typeName})
          .then((res: DataDictionaryDto[]) => {
            this.loading = false;
            this._items = res;
            resolve(res);
          });
      }
    });
  }

  get isOnLoading() {
    return this.loading;
  }

  get promise(): Promise<DataDictionaryDto[]> {
    if (this._promise) {
      return this._promise;
    } else {
      this._promise = this.fetch();
      return this._promise;
    }
  }

  get items(): DataDictionaryDto[] {
    return this._items;
  }
}

export interface DataDictionaryState {
  dataDictionaryValues: { [key: string]: DataDictionaryDto[] };
  allStates: { [key: string]: DataDictionaryFetchState };
}

interface SetDataDictionaryValueInput {
  key: string;
  values: DataDictionaryDto[];
}

interface SetFetchStateInput {
  key: string;
  fetch: DataDictionaryFetchState;
}

export default {
  namespaced: true,
  state: () =>
    ({
      dataDictionaryValues: {},
      allStates: {},
    } as DataDictionaryState),
  mutations: {
    SET_DATA_DICTIONARY_VALUE(
      state: DataDictionaryState,
      payload: SetDataDictionaryValueInput
    ) {
      state.dataDictionaryValues[payload.key] = payload.values;
    },
    SET_FETCH(state: DataDictionaryState, payload: SetFetchStateInput) {
      state.allStates[payload.key] = payload.fetch;
    },
  },
  actions: {
    fetch(
      context: ActionContext<DataDictionaryState, any>,
      rootKey: string
    ): Promise<DataDictionaryDto[]> {
      return new Promise<DataDictionaryDto[]>((resolve, reject) => {
        const cacheValue = context.state.dataDictionaryValues[rootKey];
        if (cacheValue) {
          resolve(cacheValue);
        } else {
          let state = context.state.allStates[rootKey];
          if (!state) {
            state = new DataDictionaryFetchState(rootKey);
            context.commit("SET_FETCH", {
              key: rootKey,
              fetch: state,
            } as SetFetchStateInput);
          }
          if (state.isOnLoading) {
            state.promise.then((res) => {
              context.commit("SET_DATA_DICTIONARY_VALUE", {
                key: rootKey,
                values: res,
              } as SetDataDictionaryValueInput);
              resolve(res);
            });
          } else {
            resolve(state.items);
          }
        }
      });
    },
  },
};
