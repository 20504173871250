import {RouteConfig} from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const productSkuRouter = [
  {
    path: "productSku",
    component: AppContent,
    name: "productSku",
    redirect: "/productSku",
    meta: {
      title: "销售管理"
    },
    children: [
      {
        path: "salesManagement",
        name: "salesManagement",
        component: () =>
          import(
            "@/views/productSku/salesManagement/list.vue"
            ),
        meta: {
          title: "销售管理",
        },
      },
      {
        path: "salesManagement/detail/:id",
        name: "salesManagementDetail",
        component: () => import("@/views/productSku/salesManagement/detail.vue"),
        meta: {
          title: "商品详情",
        },
      },
      {
        path: "projectMapping/:id",
        name: "projectMapping",
        component: () => import("@/views/commodity/commodity/components/projectMapping.vue"),
        meta: {
          title: "商品关联关系",
        },
      },
      {
        path: "pricingManagement/detail/:id",
        name: "pricingManagementDetail",
        component: () => import("@/views/productSku/pricingManagement/list.vue"),
        meta: {
          title: "调价管理",
        },
      },
      {
        path: "pricingManagement/logList/:id",
        name: "adjustLogList",
        component: () => import("@/views/productSku/pricingManagement/adjustLogList.vue"),
        meta: {
          title: "调价日志",
        },
      },
      {
        path: "order/startOrderOf/:email/:code",
        name: "startOrderOf",
        component: () => import("@/views/productSku/order/startOrderOf.vue"),
        meta: {
          title: "代下订单",
        },
      },
      {
        path: "orderManagement",
        name: "orderManagement",
        component: () => import("@/views/productSku/orderManagement/list.vue"),
        meta: {
          title: "订单管理",
        },
      },
      {
        path: "orderDetail/:id",
        name: "orderDetail",
        component: () => import("@/views/productSku/orderManagement/detail.vue"),
        meta: {
          title: "订单详情",
        },
      },
      {
        path: "invoiceDetail/:id",
        name: "invoiceDetail",
        component: () => import("@/views/productSku/orderManagement/invoice.vue"),
        meta: {
          title: "发票",
        },
      },
    ],
  }
];

export default productSkuRouter;
