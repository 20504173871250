import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const adminRouter: RouteConfig[] = [
  {
    path: "admin",
    component: AppContent,
    name: "admin",
    redirect: "",
    meta: {
      title: "系统设置"
    },
    children: [
      {
        path: "dataDictionaries",
        name: "dataDictionaries",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/admin/dataDictionaries/list.vue"
            ),
        meta: {
          title: "数据字典"
        }
      },
      {
        path: "sysSettingTaxe",
        name: "sysSettingTaxe",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/admin/sysSettingTaxe/list.vue"
            ),
        meta: {
          title: "税金配置管理"
        }
      },
      {
        path: "sysSettingTaxeCreate",
        name: "sysSettingTaxeCreate",
        component: () =>
          import(
            "@/views/admin/sysSettingTaxe/edit.vue"
            ),
        meta: {
          title: "新增税金配置"
        }
      },
      {
        path: "sysSettingTaxeEdit/:id",
        name: "sysSettingTaxeEdit",
        component: () =>
          import(
            "@/views/admin/sysSettingTaxe/edit.vue"
            ),
        meta: {
          title: "编辑税金配置"
        }
      },
      {
        path: "sysSettingOrder",
        name: "sysSettingOrder",
        component: () =>
          import(
            "@/views/admin/sysSettingOrder/list.vue"
            ),
        meta: {
          title: "订单设置"
        }
      },
      {
        path: "languageTexts",
        name: "languageTexts",
        component: () =>
          import(
            "@/views/admin/languageTexts/list.vue"
            ),
        meta: {
          title: "多语言配置"
        }
      }
    ]
  }
];

export default adminRouter;
