























import { Component, Prop, Vue } from "vue-property-decorator";
import SidebarItem from "./SidebarItem.vue";
import variables from "@/styles/_variables.scss";
import Logo from "./SidebarLogo.vue";
import { MenuItem } from "@/store/module/permission";

@Component({
  name: "SideBar",
  components: {
    SidebarItem,
    Logo,
  },
})
export default class extends Vue {
  created() {
    this.$store.dispatch("user/initializeUser").then(() => {
      console.log("initializedUser");
    });
  }

  get sidebar() {
    return this.$store.getters["app/sidebar"];
  }

  get menus(): MenuItem[] {
    return this.$store.getters["permission/menus"];
  }

  get variables() {
    return variables;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }
}
