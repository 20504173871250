export default {
  basic: {
    detail: "Detail",
    select: "Please Select",
    input: "Please Input",
    enable: "Enable",
    disable: "Disable",
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    setAccountAmount: "Set Account Amount",
    confirm: "Confirm",
    cancel: "Cancel",
    return: "Return",
    updateSuccess: "Update Success!",
    createSuccess: "Create Success!",
    deleteSuccess: "Delete Success!",
    enableSuccess: "Enable Success!",
    disableSuccess: "Disable Success!",
    tip: "Tip",
    disableTip: "Are you sure it's disabled?",
    deleteTip: "Are you sure about the deletion?",
    export: "Export",
    index: "index",
    operation: "Operations",
    search: "Query",
    resetSearch: "Reset Refresh",
    yuan: "yuan",
    updateCompleted: "Update succeeded",
  },
  customer: {
    index: "index",
    customerCode: "Customer Code",
    realName: " Real Name",
    sex: "Sex",
    mobilePhone: "Mobile Phone",
    emailAddress: "Email",
    membershipTme: "Membership Time",
    memberValidity: "Member Validity",
    status: "Status",
    effective: "Effective",
    wasDue: "Was Due",
    enable: "Enable",
    disable: "Disable",
    operation: "Operations",
    basicInfo: "Basic Information",
    orderInvoiceInfo: "Invoice Information",
    headerName: "Invoice Title",
    address: "Address",
    contactNumber: "Contact Phone",
    addressInfo: "Address Information",
    contacts: "Contacts",
    details: "details",
    creationTime: "Creation Time",
    enterpriseName: "Enterprise Name（Register）",
    enterpriseCall: "Enterprise Call",
    accountQuota: "Account Quota",
    isActive: "Status",
    enterpriseAccountQuota: "Amount of authorized platform account",
    setAccountQuota: "Set Account Quota",
    men: "Men",
    women: "Women",
    surname: "Surname",
    name: "Name",
    contactEmailAddress: "Email",
    postalCode: "Postal Code",
    province: "Province",
    city: "City",
    invoiceHeader: "Invoice Header",
    contactInfo: "Contact Information",
    invoiceAddress: "Billing Address",
    receiveAddress: "Shipping Address",
    category: "Industry",
    addReceiveAddress: "Add Shipping Address",
    addInvoiceAddress: "Add Billing Address",
    defaultAddress: "Default Address",
    default: "Default",
    tips: {
      enterpriseName: "Please enter enterprise name（Register）",
      enterpriseCall: "Please enter enterprise call",
      realName: "Please enter contact name",
      sex: "Please select contact gender",
      mobilePhone: "Please enter the contact phone",
      email: "Please enter contact email",
      emailFormat: "Please fill in the correct email address",
      enterpriseAccountQuota:
        "Please enter the authorized platform account amount",
      categoryArray: "Please select your industry",
      surname: "Please enter the last name of the contact",
      name: "Please enter the contact name",
    },
  },
  financeSaleStatistic: {
    index: "index",
    orderCode: "Commodity Order No.",
    customerType: "Customer Type",
    enterprise: "Enterprise Customers",
    member: "Member Customers",
    ordinary: "Ordinary Customers",
    totalAmount: "Order Amount",
    status: "Order Status",
    toBePaid: "Unpaid",
    paid: "Paid、For outbound",
    issued: "Out of storage, waiting for receipt",
    received: "Goods received (completed)",
    cancelled: "Cancelled",
    creationTime: "Order Time",
    paidAmount: "Paid Amount",
    arrearsAmount: "Debt Amount",
    repaymentDeadline: "Repayment Due Time",
    operation: "Operations",
    salesStatistics: "Sales Statistics List",
  },
  wareHouseSearch: {
    number: "Product ID",
    name: "Product Name ",
    Category: "Product Types",
    totalStock: "Commodity Stocks",
    availableStock: "Number Of Stores",
    earlyWarningStock: "Inventory Warning",
    unitId: "Unit Of Measurement",
    warningSet: "Warning Set",
    currentEarlyWarningStock: "Current inventory alert",
    quantity: "Modify inventory alert",
    tips: {
      quantity: "Please enter stock alert",
    },
  },
  wareHouseLocation: {
    name: "Warehouse Name",
    description: "Warehouse Description",
    status: "Warehouse Status",
    creationTime: "Creation Time",
    create: "Create WareHouse",
    locationManagement: "Location Management",
    selfLiftinPointsManagement: "Self-lift Point Management",
    locationCreate: "Create Location",
    locationName: "Location Name",
    locationDescription: "Location Description",
    locationStatus: "Location Status",
    frame: "Location - Frame",
    layer: "Location - Layer",
    position: "Location - Position",
    details: "Details",
    contacts: "Contacts",
    contactNumber: "Contact Number",
    contactEmailAddress: "Contact Email",
    address: "Address",
    addressType: "Address Type",
    createSelfLiftingPoint: "Create Self Lifting Point",
    warehouseSelfLiftinPoint: "Warehouse Pick-up Point",
    province: "Province",
    city: "City",
    remark: "Remark",
    tips: {
      name: "Please enter the warehouse name",
      description: "Please enter remarks",
      status: "Please select a status",
      frame: "Please enter location - Frame",
      layer: "Please enter location - Layer",
      position: "Please enter location - Position",
      contacts: "Please enter contact",
      contactNumber: "Please enter the contact number",
      contactEmailAddress: "Please enter the contact email",
      address: "Please enter the detailed address",
      remark: "Please enter remark",
      province: "Please select Province",
      city: "Please select a city",
    },
  },
  warehouseInStorageMain: {
    productSkuNumber: "Item Number",
    inStorageCode: "Item Warehousing No",
    purchaseCode: "Corresponding Purchase Document",
    supplier: "Supplier",
    totalQuantity: "Goods Quantity",
    inStorageType: "Warehousing Type",
    isWarehoused: "Warehousing Status",
    warehoused: "Warehoused",
    notWarehoused: "Not Warehoused",
    warehouse: "Warehouse/Location",
    inStorageTime: "Warehousing Time",
    inStorageHandler: "Warehousing Operator",
    createWareHousing: "Create WareHousing Order",
    basicInfo: "Basic Information",
    totalAmount: "Total Amount",
    supplierName: "Supplier",
    currentWarehouse: "Warehouse",
    currentLocation: "Location",
    WarehousingInfo: "Warehousing Information",
    frame: "Frame",
    layer: "Layer",
    position: "Position",
    warehouseSelfLiftinPoint: "Warehouse Pick-up Point",
    selectProduct: "Select Product",
    productName: "Commodity Name",
    productSpecParamMappingStr: "Commodity Specifications",
    productNumber: "Item Number",
    productBrand: "Commodity Brand",
    productCategory: "Commodity Type",
    productUnit: "Measurement Unit",
    productInStorageQuantity: "Receipt Quantity",
    productCostPrice: "Cost Price",
    productIsWarehoused: "Warehousing Status",
    warehousingOrder: "Warehousing Order",
    attachmentUpload: "Attachment Upload",
    details: "Details",
    detailList: "List of Product Details",
    totalStock: "Commodity Inventory",
    availableStock: "Available Stock",
    earlyWarningStock: "Early Warning Inventory",
    minPurchaseQuantity: "Minimum Purchase Quantity",
    salesVolume: "Sales Volume",
    salePrice: "Sale Price",
    memberPrice: "Member Price",
    attachments: "Attachments",
    tips: {
      productSkuNumber: "Please enter the product number",
      inStorageCode: "Please enter the product warehousing number",
      supplier: "Please select a supplier",
      purchaseCode: "Please enter the corresponding Po No",
      currentWarehouse: "Please select a warehouse",
      currentLocation: "Please select a location",
      inStorageTime: "Please select the warehousing time",
      inStorageType: "Please select receipt type",
      productInStorageQuantity: "Please enter the stock in quantity",
      productCostPrice: "Please enter cost price",
      inStorageHandler: "Please select the warehousing operator",
      selectProduct: "Please select a product",
      province: "Please select Province",
      city: "Please select a city",
      selectIOneData: "Please select at least one piece of data",
    },
  },
  warehouseExManagement: {
    productNumber: "Product Number",
    orderCode: "Order Number",
    userName: "Buyer Account",
    outStorageCode: "Issue No",
    productSkuTotalPrice: "Total Merchandise",
    outStorageType: "Issue Type",
    status: "Outbound Status",
    toBeDelivered: "To be delivered",
    issued: "Issued",
    outStorageTime: "Delivery Time",
    fkOutStorageHandler: "Handler",
  },
  invoice: {
    order: "Order",
    invoice: "Invoice",
    bill: "Invoice",
    downLoad: "DownLoad",
    orderInformation: "Order Information",
    creationTime: "Order Time",
    receivingMethod: "Receiving Method",
    productName: "Commodity",
    specParamMappingStr: "Product Specifications",
    quantity: "Quantity",
    productSkuTotalQuantity: "Total Quantity Of Goods",
    receivingInformation: "Receiving Information",
    warehouseSelfLiftingPointInformation: "Pick Up Point Information",
    receivingAddress: "Receiving Address",
    warehouseSelfLiftingPoint: "Self Lifting Point",
  },
  memberPaymentOrder: {
    customerCode: "Member Number",
    isPaid: "Status",
    userName: "Member Name",
    mobilePhone: "MobilePhone",
    emailAddress: "EmailAddress",
    membershipFeeAmount: "Amount Of Membership Fee",
    orderCode: "Order Number",
    orderStatus: "Order Status",
    paid: "Paid",
    unPaid: "Unpaid",
    payMethod: "Payment Method",
    payTime: "Payment Time",
    creationTime: "Creation Time",
  },
  salesManagement: {
    name: "Please enter product name",
    parentIdPath: "Please select a product type",
    brandId: "Please select the product brand",
    isRecommend: "Please choose whether to recommend",
    yes: "Yes",
    no: "No",
    minAvailableStock: "MinAvailableStock",
    maxAvailableStock: "Maximum Stock Available",
    batchRemovedFromShelf: "Batch Removed",
    batchSetAvailableStock: "Batch Listing",
    orderOf: "Order On Behalf Of",
    selectAll: "Select All",
    productName: "Product Name",
    specParamMappingStr: "Spec",
    number: "Product Number",
    parentNamePath: "Product Type",
    factoryPrice: "Factory Price",
    salePrice: "Selling Price",
    memberPrice: "Member Price",
    availableStock: "Number of Stocks",
    salesVolume: "Sold",
    status: "Task Status",
    notOnShelf: "Not on Shelf",
    removedFromShelf: "Removed",
    onShelf: "On Shelf",
    detail: "Detail",
    setAvailableStock: "On the shelf",
    takeDown: "Take Down",
    priceAdjustmentManagement: "Price Adjustment Management",
    removedSuccessfully: "Successfully removed",
    atLeastOnePieceOfData: "Please select at least one piece of data",
    atLeastOneDelistedOrUnlistedData: "Please select at least one delisted or unlisted data",
    bulkOffShelves: "Successfully removed in bulk",
    atLeastOneListedOrUnlistedData: "Please select at least one listed or unlisted data",
    serialNumber: "Number",
    specifications: "Specification model",
    commodityStocks: "Commodity Stocks",
    numberOfShelves: "Number of Shelves",
    quantity: "Please enter the quantity on the shelf",
    productInformation: "Product Information",
    minPurchaseQuantity: "Minimum Purchase Quantity",
    goodsOnShelf: "Goods on Shelf",
    productDetails: "Product Details",
    createDate: "Create Date",
    customerType: "Customer Type:",
    enterpriseUsers: "Enterprise User",
    emailAddress: "Mailbox",
    customerCode: "System Code",
    onSave: "Information Matching Succeeded",
    mailbox: "Please enter your mailbox",
    systemCode: "Please enter the system code",
  },
  pricingManagement: {
    pricingManagement: "Price Adjustment Management",
    customerCode: "Please enter the member number",
    realName: "Please enter the member name",
    logList: "Price Adjustment Log",
    code: "Member Number",
    customerRealName: "Member Name",
    revisedFactoryPrice: "Current Price",
    remark: "Remarks",
    creationTime: "Adjustment Time",
    operatorRealName: "Operator",
    modifyPrice: "Modify Price",
    editPrice: "Edit Price",
    revisedPrice: "Modified Price:",
    factoryPrice: "Factory Price:",
    remarks: "remarks:",
    createSuccess: "Created successfully",
    modifiedPrice: "Please enter the modified price",
    number: "Please enter the product number",
    userName: "Please enter user account",
    userCode: "Please enter the user code",
    originalFactoryPrice: "Sales Price",
    userRealName: "User Account",
    userCustomerCode: "User Code",
  },
  startOrderOf: {
    orderDetails: "Order Details",
    buyerInformation: "Buyer Information",
    userName: "User Account",
    customerCode: "User Number",
    receivingInformation: "Receiving Information",
    receivingMethod: "Receiving Method:",
    receivingAddressMappingId: "Receiving Address:",
    selectReceivingAddress: "Switch Addresses",
    warehouseSelfLiftingPointId: "Self-lifting Point:",
    selectWarehouseSelfLiftingPointId: "Please select a self-lifting point",
    billAddressMappingId: "Invoice Address:",
    switchShippingAddress: "Switch Shipping Address",
    contacts: "Contact Name",
    contactNumber: "Contact Phone Number",
    provinceId: "Contact Address",
    contactEmailAddress: "Contact Email",
    postalCode: "Postal Code",
    switchBillAddress: "Switch Invoice Address",
    invoice: "Invoice Header",
    orderInformation: "Order Information",
    selectCommodity: "Select Commodity",
    productName: "Product Name",
    specParamMappingStr: "Commodity specification",
    number: "Product Number",
    parentNamePath: "Product Type",
    factoryPrice: "Factory Price",
    availableStock: "Number of Stocks",
    unitId: "Unit of Measure",
    minPurchaseQuantity: "Minimum Purchase Quantity",
    orderQuantity: "Order Quantity",
    totalPrice: "Total Price",
    productCount: "Number of products:",
    allTotal: "Total price:",
    save: "Confirm Order",
    enterOrderQuantity: "Please enter the order quantity",
    orderFailed: "Order failed",
    success: "Order successfully placed",
    selectReceivingMethod: "Please select the receiving method",
    selectReceivingAddressMappingId: "Please select the delivery address",
    selectBillAddressMappingId: "Please select an invoice address",
    salePrice: "Selling Price",
    productNumber: "Please enter the product number",
    name: "Please enter the product title",
    parentIdPath: "Please select a product type",
    brandId: "Please select the product brand",
    atLeastOnePieceOfData: "Please select at least one piece of data",
    batchSelection: "Confirm batch selection?",
  },
  orderManagement: {
    orderCode: "Please enter the order number",
    userName: "Please enter a user account",
    receivingMethod: "Please select the receiving method",
    status: "Please select order status",
    orderTime: "Order Time",
    beginTime: "Please select the start of the time range",
    endTime: "Please select the end of the time range",
    code: "Order Number",
    productSkuTotalPrice: "Total Product",
    totalAmount: "Total Order Amount",
    outStorageTime: "Order Time",
    fkCustomer: "Order User",
    receiptMethod: "Receipt Method",
    payMethod: "Payment Method",
    orderStatus: "Order Status",
    closeTransaction: "Close Transaction",
    orderDelivery: "Immediate Shipment",
    completeDelivery: "Complete Delivery",
    cancelReason: "Reason:",
    reason: "Please fill in the reason",
    orderShipped: "Order Shipped",
    logisticsCompany: "Logistics company:",
    logisticsOrderNo: "Logistics order number:",
    enterLogisticsOrderNo: "Please enter the logistics order number",
    expectedDeliveryDate: "Expected Delivery Date:",
    selectExpectedDeliveryDate: "Please select an estimated delivery date",
    notice: "Note: The current order receiving method is \"self-pickup\". Please confirm whether the current product has been stocked at the self-pickup point. After clicking confirm, the system will automatically send an email to remind the user to come and pick up the order.",
    orderClosed: "Order has been closed",
    orderHasBeenShipped: "Order Shipped",
    deliveryCompleted: "Are you sure the delivery is complete?",
    successfulOperation: "The operation was successful!",
  },
  orderDetail: {
    details: "Details",
    step1: "1. Buyer places an order",
    step2: "2. Buyer payment",
    step3: "3. Shipping",
    step4: "4. Confirm receipt",
    step: "2. Cancel the transaction",
    payTime: "Payment Time",
    outStorageTime: "Shipping Time",
    receivedTime: "Deal Time",
    cancelTime: "Operation Time",
    orderStatus: "Current order status:",
    status1: "The product has been photographed, waiting for the buyer to pay (if the product is maliciously photographed, you can cancel the order in the background~)",
    status2: "The product has been photographed and paid for, waiting for the seller to ship",
    status3: "Order cancelled, transaction closed",
    status4: "Seller has shipped, waiting for buyer to receive",
    status5: "The buyer confirms the receipt and the transaction is successful",
    sealTheDeal: "Complete the deal",
    cancelReason: "Cancel Reason:",
    buyerInformation: "Buyer Information",
    realName: "User account:",
    customerCode: "User ID:",
    payMethod: "Payment method:",
    address: "Shipping address:",
    fkWarehouseSelfLiftingPoint: "Self-lifting point:",
    orderInformation: "Order Information",
    orderCode: "Order ID:",
    creationTime: "Order time:",
    receivingMethod: "Receiving method:",
    name: "Product",
    specParamMappingStr: "Commodity specification",
    quantity: "Unit price (yuan) * quantity",
    amountDetails: "Amount Details",
    productSkuTotalQuantity: "Total quantity of products:",
    productSkuTotalPrice: "Total product price:",
    tranExpenses: "Product Shipping Fee:",
    taxesGST: "Taxes (GST):",
    taxesQST: "Taxes (QST):",
    totalAmount: "Total:",
    invoiceInformation: "Invoice Information",
    invoice: "Invoice Header",
    invoiceAmount: "Invoice Amount",
    payeeInformation: "Payee Information",
  },
  commodityClassification: {
    name: "Classification Name:",
    id: "Classification ID",
    level: "Classification Level",
    description: "Classification Description:",
    displayOrder: "Sort:",
    childrenCreate: "Add Sub Classification",
    create: "Add Classification",
    classification: "Classification:",
    addClassification: "Add Classification",
    editClassification: "Edit Classification",
    tips: {
      name: "Please enter the classification name",
      classification: "Please select the classification",
    },
    createSuccessfully: "Classification created successfully",
  },
  commoditySpecifications: {
    name: "Specifications Name",
    inputType: "Value Type",
    value: "Specification Value",
    description: "Description",
    creationTime: "Creation Time",
    displayOrder: "Sort",
    addSpecificationParams: "Add Specification Params",
    editSpecificationParams: "Edit Specification Params",
    addSpecification: "Add Specification",
    tips: {
      name: "Please enter the specification name",
      inputType: "Please select parameter value type",
    },
  },
  commodityBasicParams: {
    name: "Attribute Name",
    inputType: "Type",
    inputList: "Attribute Value",
    description: "Description",
    creationTime: "Creation Time",
    addBasicParams: "Add Basic Params",
    editBasicParams: "Edit Basic Params",
    tips: {
      name: "Please enter a property name",
      inputType: "Please select an attribute type",
    },
  },
  commodityBrand: {
    name: "Brand Name:",
    imagePath: "Brand Picture:",
    displayOrder: "Sort:",
    description: "Description:",
    creationTime: "Creation Time",
    addBrand: "Add Brand",
    editBrand: "Edit Brand",
    uploadPictures: "Upload Pictures",
    tips: {
      name: "Please enter brand name",
      type: "Upload images can only be in png, jpeg, jpg, gif format!",
      size: "Upload image size cannot exceed 1MB!",
    },
    tip: "The best size for uploading pictures: 300 pixels*300 pixels, other sizes will affect the effect, the format is png, jpeg, jpg, gif, and the size should not exceed 1M.",
    primaryClassification: "Primary Classification",
    createSuccessfully: "The brand was created successfully",
  },
  commoditySalesRecord: {
    supplierName: "Supplier",
    salesTime: "Sales Month",
    totalSalesPrice: "Total Sales Price",
    totalCostPrice: "Total Cost",
    actualSettlementAmount: "Actual Settlement Amount",
    isSettled: "Status",
    settled: "Settled",
    toBeSettled: "To Be Settled",
    settlement: "Settlement",
    salesDetails: "Sales Details",
    tips: {
      actualSettlementAmount: "Please enter the actual settlement amount",
      success: "Settlement succeeded",
    },
  },
  cmsCategory: {
    categoryCode: "Category Code",
    displayName: "Category Name",
    categoryType: "Category Type",
    contentCategory: "Content Category",
    articleCategory: "Article Category",
    createMainCategory: "Create Main Category",
    createSubCategory: "Create Sub Category",
    contentAlias: "Subtitle",
    displayOrder: "display Order",
    type: "Column type",
    shortDesc: "Category Description",
    redirectUrl: "PC End Path",
    redirectUrlForWeixinMiniProgram: "Applet Side Path",
    titleImagePath: "PC Side Picture",
    mobileTitleImagePath: "Applet Picture",
    unconventionalImagePath: "Exceptional Picture",
    description: "Description",
    basicInfo: "Basic Information",
    pathConfig: "Path Configuration",
    pictureConfig: "Picture Configuration",
    editCategory: "Edit Category",
    createCategory: "Create Category",
    content: "Content",
    article: "Article",
    tips: {
      displayName: "Please enter the Category name",
      categoryCode: "Please enter the Category name",
    },
  },
  cmsAnchor: {
    displayName: "Anchor Name",
    name: "Number",
    width: "Width",
    height: "Height",
    tips: {
      name: "Please enter the number",
      displayName: "Please enter the anchor name",
      width: "Width must be numeric",
      widthRange: "Range 0-10000",
      height: "Height must be numeric",
      heightRange: "Range 0-10000",
    },
  },
  cmsSwiper: {
    displayName: "Name of Rotation Chart",
    anchor: "Anchor Point",
    titleImageUrl: "Picture",
    redirectUrl: "Jump Address",
    displayOrder: "Display Order",
    remark: "Remark",
    tips: {
      displayName: "Please enter the name of rotation chart",
      titleImageUrl: "Advertisement picture not uploaded",
      cmsAnchorId: "Please select an anchor",
      displayOrder: "Please enter the display order",
      displayOrderType: "The display order must be numeric",
    },
  },
  cmsNavigation: {
    level: "Navigation Level",
    displayName: "Navigation Name",
    description: "Description",
    displayOrder: "Display Order",
    creationTime: "Creation Time",
    isShow: "Show",
    titleImagePath: "Navigation Picture",
    yes: "Yes",
    no: "No",
    createMainNavigation: "Create Main Navigation",
    createSubNavigation: "Create Sub Navigation",
    editSubNavigation: "Edit Sub Navigation",
    editNavigation: "Edit Navigation",
    createNavigation: "Create Navigation",
    selectProduct: "Select Product",
    productName: "Product Name",
    productSpecParamMappingStr: "Product Specifications",
    productNumber: "Product Number",
    productAvailableStock: "Available Stock",
    productFactoryPrice: "FactoryPrice",
    productUnit: "Unit of Measurement",
    categoryIds: "Related Product Classification",
    tips: {
      displayName: "Please enter a name",
      categoryIds: "Please select product category",
    },
  },
  commodity: {
    allProductTypes: "All Product Types",
    commodityManagement: "Commodity Management",
    name: "Product name/brand",
    addProduct: "Add Product",
    productName: "Product Information",
    brandName: "Product brand",
    parentNamePath: "Product Type",
    totalStock: "Product inventory",
    editCommodity: "Commodity Edit",
    part1: "1. Select product category",
    categoryId: "Product Category",
    selectCategoryId: "Please select a product category",
    addCategory: "Add Category",
    brandId: "Please select the product brand",
    addBrand: "Add Brand",
    part2: "2. Basic product information",
    commodityName: "Commodity Name",
    enterCommodityName: "Please enter the commodity name",
    productAttributes: "Product Attributes",
    basicParamIds: "Select attributes:",
    selectAll: "All",
    productPicture: "Product Picture",
    size: "The best size of the uploaded image: 720 pixels*720 pixels, other sizes will affect the page effect, the format is png, jpeg, jpg, gif. The size should not exceed 2M, the default first image is the main image cover",
    mainImage: "Product Main Image",
    setAsMasterGraph: "Set as master graph",
    deleteImage: "Delete Image",
    description: "Product Description",
    instructionsPath: "Instructions",
    instructionsTip:"The format of the uploaded attachment supports PDF, WORD, PPT, and the size should not exceed 5 M. (Only one file can be uploaded)",
    instructionsText: "Upload attachment",
    installationVideoPath: "Installation Video",
    installationVideoTip: "The best size does not exceed 200M",
    installationVideoText: "Upload Video",
    viewAttachments: "View Attachments",
    part3: "3. Other information",
    warehouseSelfLiftingPointId: "Self-lifting Point",
    submit: "Submit",
    pictureSize: "Upload image size cannot exceed 2MB!",
    skuList: "Please select product specification",
    enterDescription: "Please fill in the product description",
    savedAttachments: "Please upload a product image",
    specParamMappingStr: "Commodity specification",
    selectSpecification: "Select specification:",
    add: "Add",
    id: "Number",
    unitId: "Unit of Measure",
  },
  languageTexts: {
    addKey: "Add",
    enterKey: "Please enter a key",
    enterValue: "Please enter content",
  }
};
