




















import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import api from "@/api";

@Component({
  name: "SidebarLogo",
})
export default class SidebarLogo extends Vue {
  get collapse() {
    return !(this as any).$store.getters["app/sidebarOpened"];
  }

  get title() {
    return (this as any).$l.getLanguageText('platform');
  }

  get logo(): string {
    return (this as any).$store.getters["tenant/squareLogo"];
  }
}
