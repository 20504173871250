import AppContent from "@/layout/AppContent.vue";

const cmsRouter = [
  {
    path: "cms",
    component: AppContent,
    name: "cms",
    redirect: "/cms/categories",
    meta: {
      title: "内容管理",
    },
    children: [
      {
        path: "categories",
        name: "categories",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/category/index.vue"
          ),
        meta: {
          title: "栏目管理",
        },
      },
      {
        path: "category-create/:parentId",
        name: "category-create",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/category/edit.vue"
          ),
        meta: {
          title: "新建栏目",
        },
      },
      {
        path: "category-edit/:id",
        name: "category-edit",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/category/edit.vue"
          ),
        meta: {
          title: "编辑栏目",
        },
      },
      {
        path: "anchor",
        name: "anchor",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/anchor/list.vue"
          ),
        meta: {
          title: "锚点管理",
        },
      },
      {
        path: "anchor/create",
        name: "anchorCreate",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/anchor/edit.vue"
          ),
        meta: {
          title: "新建锚点",
        },
      },
      {
        path: "anchor/edit/:id",
        name: "anchorEdit",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/anchor/edit.vue"
          ),
        meta: {
          title: "编辑锚点",
        },
      },
      {
        path: "swiper",
        name: "swiper",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/swiper/list.vue"
          ),
        meta: {
          title: "轮播图管理",
        },
      },
      {
        path: "swiper/create",
        name: "swiperCreate",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/swiper/edit.vue"
          ),
        meta: {
          title: "新建轮播图",
        },
      },
      {
        path: "swiper/edit/:id",
        name: "swiperEdit",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/swiper/edit.vue"
          ),
        meta: {
          title: "编辑轮播图",
        },
      },
      {
        path: "feedback",
        name: "feedback",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/cms/feedback/index.vue"
          ),
        meta: {
          title: "反馈信息",
        },
      },
      {
        path: "feedbackReply/:feedbackId",
        name: "feedbackReply",
        component: () => import("@/views/cms/feedback/replyList.vue"),
        meta: {
          title: "反馈信息回复",
        },
      },
      {
        path: "navigation",
        name: "navigation",
        component: () => import("@/views/cms/navigation/index.vue"),
        meta: {
          title: "导航管理",
        },
      },
      {
        path: "navigation-create/:parentId",
        name: "navigation-create",
        component: () => import("@/views/cms/navigation/edit.vue"),
        meta: {
          title: "新建一级分类",
        },
      },
      {
        path: "navigation-edit/:id",
        name: "navigation-edit",
        component: () => import("@/views/cms/navigation/edit.vue"),
        meta: {
          title: "编辑一级分类",
        },
      },
      {
        path: "navigation-createChild/:parentId",
        name: "navigation-createChild",
        component: () => import("@/views/cms/navigation/editChild.vue"),
        meta: {
          title: "新建子级类目",
        },
      },
      {
        path: "navigation-editChild/:id",
        name: "navigation-editChild",
        component: () => import("@/views/cms/navigation/editChild.vue"),
        meta: {
          title: "编辑子级类目",
        },
      },
    ],
  },
];

export default cmsRouter;
