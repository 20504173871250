import AppContent from "@/layout/AppContent.vue";

const customerRouter = [
  {
    path: "customer",
    component: AppContent,
    name: "customer",
    redirect: "/customer",
    meta: {
      title: "客户管理",
    },
    children: [
      {
        path: "enterprise",
        name: "enterprise",
        component: () => import("@/views/customer/enterprise/list.vue"),
        meta: {
          title: "企业客户",
        },
      },
      {
        path: "enterpriseCreate",
        name: "enterpriseCreate",
        component: () => import("@/views/customer/enterprise/edit.vue"),
        meta: {
          title: "新增",
        },
      },
      {
        path: "enterpriseEdit/:id",
        name: "enterpriseEdit",
        component: () => import("@/views/customer/enterprise/edit.vue"),
        meta: {
          title: "编辑",
        },
      },
      {
        path: "enterpriseDetail/:id",
        name: "enterpriseDetail",
        component: () => import("@/views/customer/enterprise/detail.vue"),
        meta: {
          title: "企业客户详情",
        },
      },
      {
        path: "ordinary",
        name: "ordinary",
        component: () => import("@/views/customer/ordinary/list.vue"),
        meta: {
          title: "普通客户",
        },
      },
      {
        path: "member",
        name: "member",
        component: () => import("@/views/customer/member/list.vue"),
        meta: {
          title: "会员客户",
        },
      },
      {
        path: "memberDetail/:id",
        name: "memberDetail",
        component: () => import("@/views/customer/member/detail.vue"),
        meta: {
          title: "会员客户详情",
        },
      },
    ],
  },
];

export default customerRouter;
