import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const inventoryRouter = [
  {
    path: "finance",
    component: AppContent,
    name: "finance",
    redirect: "/finance",
    meta: {
      title: "财务"
    },
    children: [
      {
        path: "commoditySalesRecord/:from",
        name: "commoditySalesRecord",
        component: () =>
          import(
            "@/views/finance/commoditySalesRecord/list.vue"
            ),
        meta: {
          title: "商品销售记录"
        }
      },
      {
        path: "financeSaleStatistic",
        name: "financeSaleStatistic",
        component: () =>
          import("@/views/finance/financeSaleStatistic/list.vue"),
        meta: {
          title: "销售统计",
        },
      },
      {
        path: "memberPaymentOrder",
        name: "memberPaymentOrder",
        component: () =>
          import("@/views/finance/memberPaymentOrder/list.vue"),
        meta: {
          title: "会员缴费订单",
        },
      }
    ]
  }
];

export default inventoryRouter;
