import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const commonRouter: RouteConfig[] = [
  {
    path: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/welcome.vue"),
    meta: {
      title: "Dashboard",
    },
  },
];

export default commonRouter;
