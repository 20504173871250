import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const inventoryRouter = [
  {
    path: "purchase",
    component: AppContent,
    name: "purchase",
    redirect: "/purchase",
    meta: {
      title: "采购"
    },
    children: [
      {
        path: "supplierManagement",
        name: "supplierManagement",
        component: () =>
          import(
            "@/views/purchase/supplierManagement/list.vue"
            ),
        meta: {
          title: "供应商管理"
        }
      },
      {
        path: "commoditySalesRecord/:from",
        name: "commoditySalesRecord",
        component: () =>
          import(
            "@/views/purchase/commoditySalesRecord/list.vue"
            ),
        meta: {
          title: "商品销售记录"
        }
      },
      {
        path: "commoditySalesRecord/details/:productSaleMainId",
        name: "commoditySalesRecordDetails",
        component: () =>
          import(
            "@/views/purchase/commoditySalesRecord/details.vue"
            ),
        meta: {
          title: "销售明细"
        }
      }
    ]
  }
];

export default inventoryRouter;
