import Vue from "vue";
import Router, { Route, RouteConfig } from "vue-router";
import Layout from "@/layout/index.vue";
import Login from "@/views/account/login.vue";
import Http404 from "@/views/404.vue";
import store from "@/store/index";

import commonRouter from "@/router/modules/common";
import adminRouter from "@/router/modules/admin";
import cmsRouter from "@/router/modules/cms";

import ouRouter from "@/router/modules/ou";
import commodityRouter from "@/router/modules/commodity";
import productSkuRouter from "@/router/modules/productSku";
import customerRouter from "@/router/modules/customer";
import warehouse from "@/router/modules/warehouse";
import purchase from "@/router/modules/purchase";
import finance from "@/router/modules/finance";

Vue.use(Router);

// const originalPush = Router.prototype.push
//
// Router.prototype.push = function push(location: any) {
//   return originalPush.call(this, location).catch((err: any) => err)
// }

export const constantRoutes = [
  {
    path: "/404",
    component: Http404,
    meta: { hidden: true, auth: false }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "登录",
      hidden: true,
      auth: false
    }
  },

  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    meta: {
      // title: "Dashboard"
    },
    children: [
      ...commonRouter,
      ...adminRouter,
      ...cmsRouter,
      ...ouRouter,
      ...commodityRouter,
      ...customerRouter,
      ...warehouse,
      ...purchase,
      ...productSkuRouter,
      ...finance,
    ]
  }
];

// 动态加载菜单
export const asyncRouter = [
  {
    path: "*",
    redirect: "/404",
    meta: { hidden: true }
  }
];


const router = new Router({
  mode: "history", // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,

  routes: [...constantRoutes, ...asyncRouter]
});

router.beforeEach((to: Route, from: Route, next: any) => {
  const token = store.getters["user/token"];
  if (token) {
    next();
  } else {
    if ((to.meta as any).auth !== false) {
      next({ name: "login", params: { returnUrl: to.fullPath } });
    } else {
      next();
    }
  }
});

export default router;
