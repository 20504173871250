import { RouteConfig } from "vue-router";
import AppContent from "@/layout/AppContent.vue";

const commodityRouter: RouteConfig[] = [
  {
    path: "commodity",
    component: AppContent,
    name: "commodity",
    redirect: "",
    meta: {
      title: "商品",
    },
    children: [
      {
        path: "commodityClassification",
        name: "commodityClassification",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/commodity/classification/list.vue"
            ),
        meta: {
          title: "商品分类",
        },
      },
      // {
      //   path: "externalProjectChangeApply/create",
      //   name: "externalProjectChangeApplyCreate",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ouUserManager" */ "@/views/project/externalProjectChangeApply/edit.vue"
      //       ),
      //   meta: {
      //     title: "新建外部项目变更申请",
      //   },
      // },
      // {
      //   path: "externalProjectChangeApply/edit/:id",
      //   name: "externalProjectChangeApplyEdit",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ouUserManager" */ "@/views/project/externalProjectChangeApply/edit.vue"
      //       ),
      //   meta: {
      //     title: "编辑外部项目变更申请",
      //   },
      // },
      // {
      //   path: "externalProjectChangeApply/detail/:id",
      //   name: "externalProjectChangeApplyDetail",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ouUserManager" */ "@/views/project/externalProjectChangeApply/detail.vue"
      //       ),
      //   meta: {
      //     title: "外部项目变更申请详情",
      //   },
      // },
      // {
      //   path: "externalProjectChangeApply/editProject/:targetExternalProjectId",
      //   name: "externalProjectChangeApplyEditProject",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ouUserManager" */ "@/views/project/externalProject/proposal/edit.vue"
      //       ),
      //   meta: {
      //     title: "编辑外部项目变更申请项目书",
      //   },
      // },
      // {
      //   path: "externalProjectChangeApply/createProject/:sourceProjectId",
      //   name: "externalProjectChangeApplyCreateProject",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ouUserManager" */ "@/views/project/externalProject/proposal/edit.vue"
      //       ),
      //   meta: {
      //     title: "新建外部项目变更申请项目书",
      //   },
      // },
      // {
      //   path: "projectSeries",
      //   name: "projectSeries",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ouUserManager" */ "@/views/project/projectSeries/list.vue"
      //       ),
      //   meta: {
      //     title: "项目系列管理",
      //   },
      // }
      {
        path: "brand",
        name: "brand",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/commodity/brand/list.vue"
            ),
        meta: {
          title: "品牌管理",
        },
      },
      // {
      //   path: "BrandCreate",
      //   name: "BrandCreate",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ActivityEdit" */ "@/views/commodity/brand/edit.vue"
      //       ),
      //   meta: {
      //     title: "添加品牌",
      //   },
      // },
      // {
      //   path: "brand/edit/:id",
      //   name: "BrandEdit",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ActivityEdit" */ "@/views/commodity/brand/edit.vue"
      //       ),
      //   meta: {
      //     title: "品牌编辑",
      //   },
      // },
      {
        path: "specifications",
        name: "specifications",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/commodity/specifications/list.vue"
            ),
        meta: {
          title: "商品规格",
        },
      },
      {
        path: "basicParams",
        name: "basicParams",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/commodity/basicParams/list.vue"
            ),
        meta: {
          title: "商品属性",
        },
      },
      {
        path: "commodity",
        name: "commodity",
        component: () =>
          import(
            /* webpackChunkName: "ouUserManager" */ "@/views/commodity/commodity/list.vue"
            ),
        meta: {
          title: "商品管理",
        },
      },
      {
        path: "commodityCreate",
        name: "commodityCreate",
        component: () =>
          import(
            /* webpackChunkName: "ActivityEdit" */ "@/views/commodity/commodity/edit.vue"
            ),
        meta: {
          title: "添加商品",
        },
      },
      {
        path: "commodity/edit/:id",
        name: "commodityEdit",
        component: () =>
          import(
            /* webpackChunkName: "ActivityEdit" */ "@/views/commodity/commodity/edit.vue"
            ),
        meta: {
          title: "商品编辑",
        },
      },
    ],
  },
];

export default commodityRouter;
