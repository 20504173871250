import { SelectListItem } from "@/api/appService";
import api from "@/api";
import { ActionContext } from "vuex";

class EnumValuesFetchState {
  private typeName: string;
  private loading = true;
  private _items: SelectListItem[] = [];
  private _promise?: Promise<SelectListItem[]>;

  constructor(typeName: string) {
    this.typeName = typeName;
  }

  fetch(): Promise<SelectListItem[]> {
    return new Promise<SelectListItem[]>((resolve, reject) => {
      if (!this.loading) {
        resolve(this._items);
      } else {
        api.enumService
          .getValues({ typeName: this.typeName })
          .then((res: SelectListItem[]) => {
            this.loading = false;
            this._items = res;
            resolve(res);
          });
      }
    });
  }

  get isOnLoading() {
    return this.loading;
  }

  get promise(): Promise<SelectListItem[]> {
    if (this._promise) {
      return this._promise;
    } else {
      this._promise = this.fetch();
      return this._promise;
    }
  }

  get items(): SelectListItem[] {
    return this._items;
  }
}

export interface EnumValuesState {
  values: { [key: string]: SelectListItem[] };
  allStates: { [key: string]: EnumValuesFetchState };
}

interface SetEnumValuesInput {
  key: string;
  values: SelectListItem[];
}

interface SetFetchStateInput {
  key: string;
  fetch: EnumValuesFetchState;
}

export default {
  namespaced: true,
  state: () =>
    ({
      values: {},
      allStates: {},
    } as EnumValuesState),
  mutations: {
    SET_ENUM_VALUE(state: EnumValuesState, payload: SetEnumValuesInput) {
      state.values[payload.key] = payload.values;
    },
    SET_FETCH(state: EnumValuesState, payload: SetFetchStateInput) {
      state.allStates[payload.key] = payload.fetch;
    },
  },
  actions: {
    fetch(
      context: ActionContext<EnumValuesState, any>,
      typeName: string
    ): Promise<SelectListItem[]> {
      return new Promise<SelectListItem[]>((resolve, reject) => {
        const cacheValue = context.state.values[typeName];
        if (cacheValue) {
          resolve(cacheValue);
        } else {
          let state = context.state.allStates[typeName];
          if (!state) {
            state = new EnumValuesFetchState(typeName);
            context.commit("SET_FETCH", {
              key: typeName,
              fetch: state,
            } as SetFetchStateInput);
          }
          if (state.isOnLoading) {
            state.promise.then((res) => {
              context.commit("SET_ENUM_VALUE", {
                key: typeName,
                values: res,
              } as SetEnumValuesInput);
              resolve(res);
            });
          } else {
            resolve(state.items);
          }
        }
      });
    },
  },
};
